import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";

import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    Export
} from 'devextreme-react/ui/data-grid';



const getHeight = () => {
    return (window.innerHeight - 48)
}

class TimeBookingGrid extends Component {
    state = {
    };

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      setDefaultNewValues = (e) => {
      
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    render() {
        return  (
            <DataGrid 
            dataSource={apis.TimeBookingDataSource(this.props.user.access_token)}  
            noDataText="There are no matching bookings in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'woBookings' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}}
            onInitNewRow={this.setDefaultNewValues} onToolbarPreparing={this.onToolbarPreparing}
         >
         <Export enabled={this.props.grants.rights.exportData} fileName="bookings" />
         <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.manageBookings}
            allowDeleting={this.props.grants.rights.manageBookings}            
   >
             <Popup title={'Time Booking'} showTitle={true} width={700} height={345}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
        </Editing>
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        <Column dataField={'userId'} caption="Booked User">
             <Lookup dataSource={apis.UsersDataSource(this.props.user.access_token)} displayExpr={'userName'} valueExpr={'id'} /> 
             <RequiredRule />
        </Column>
        <Column dataField={'startTime'} dataType="datetime" format="dd/MM/y HH:mm  ">
            <RequiredRule />
        </Column>
        <Column dataField={'endTime'} dataType="datetime" format="dd/MM/y HH:mm " />
        <Column dataField={'orderNumber'} caption="W/O #">
            {/* <Lookup dataSource={apis.WorkOrderGridDataSource(this.props.user.access_token)} displayExpr={"woNumber"} valueExpr={'id'} allowClearing={true}/> */}
        </Column>
        <Column dataField={'activityId'} caption="Activity">
             <Lookup dataSource={apis.BookingTypeDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} allowClearing={true} /> 
        </Column>
        <Column dataField={'supportBooking'} caption="Support" dataType="boolean">
        
        </Column>
        <Column dataField={'partNumber'} caption="P/N" dataType="string">
        
        </Column>
        <Column dataField={'partDescription'} caption="Description" dataType="string">
        
        </Column>
        <Column dataField={'workTypeDescription'} caption="Type" dataType="string">
        
        </Column>

      
        </DataGrid>
        );
    }


}


const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      grants: state.rights
  };
}


export default connect(mapStateToProps, null)(TimeBookingGrid)