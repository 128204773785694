import * as actionTypes from '../actions/actionTypes';

const initialState = {
    rowData: null
}

const workorderDetailReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_WO_DETAIL:
         
            return {
                ...state,
                rowData: action.rowData

            }
        case actionTypes.SET_WO_CUSTOMER:
            let rowDataCopy =  {
                ...state.rowData
            }
            rowDataCopy.customerId = action.customerId
            return {
                ...state,
                rowData: rowDataCopy
            }
        default:
            return state;

    }
}

export default workorderDetailReducer;
