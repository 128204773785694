import React, {Component} from 'react';
import { connect } from "react-redux";
import { Form, ScrollView } from 'devextreme-react';
//import * as apis from '../../utils/apis';



class RejectionForm extends Component {

    

    render() {
      


        let formConfig = {
            items : [
              
                        {
                            dataField: 'rejectionMessage',
                            colSpan: 1,                    
                            editorOptions: {
                                readOnly: false
                            },
                            label : { 
                                text: "Rejection Message"},
                        },
                        
                {
                    itemType: "button",
                    visible: true,
                    horizontalAlignment: "right",
                    colSpan: 1,
                    buttonOptions: {
                        text: "Save",
                        type: "default",
                        useSubmitBehavior: false,
                        onClick: (params) => this.props.submitRejection(params,this.props.action.rowData.id,this.form.instance.option('formData')) 
                    }

                },
             ]
    }

        let output = null;

       

        if(this.props.action.rowData) {
            let formData = JSON.parse(this.props.action.rowData.configuration);
            console.log(formData);
            output = 
            <ScrollView>
                <Form formData={formData} items={formConfig.items}
                ref={(form) => {this.form = form}} >

                </Form>
            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        action: state.action
    };
  }

export default connect(mapStateToProps,null)(RejectionForm)