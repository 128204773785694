import React, {Component} from 'react';
import { connect } from "react-redux";
import { Form, ScrollView } from 'devextreme-react';
import * as apis from '../../../utils/apis'



class PartRequestDetailPopup extends Component {

   

    render() {

        let formConfig = {
            items : [
                {
                    itemType: "group",
                    colCount: 7,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: 'requestIdentifier',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "Request #"},
                            
                        },
                        {
                            dataField: 'partNumber',
                            colSpan: 1,                    
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Part Number"},
                        },
                        {
                            dataField: 'description',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Description"},
                                
                        },
                        {
                            dataField: 'figure',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Figure"
                            },
                                
                        },
                        {
                            dataField: 'iplNo',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "IPL No"},
                            
                        },
                        {
                            dataField: 'slot',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "SLOT"},
                            
                        },
                        {
                            dataField: 'cmm',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "CMM"},
                            
                        },
                        
                    ]
                },
                {
                    itemType: "group",
                    colCount: 7,
                    colSpan: 3,
                    items: [      
                        {
                            dataField: 'grn',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "GRN"},
                           
                        },
                        {
                            dataField: 'poRef',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "P/O Ref"},
                               
                        },
                        {
                            dataField: 'accountsRef',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Sage Ref"},
                               
                        },
                        {
                            dataField: 'dateRequested',
                            colSpan: 1,                    
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly: true
                            },
                            label : { 
                                text: "Requested"},
                        },
                        {
                            dataField: 'dueDate',
                            colSpan: 1,                    
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly: true
                            },
                            label : { 
                                text: "Due Date"},
                        },
                        {
                            dataField: 'dateClosed',
                            colSpan: 1,                    
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly: true
                            },
                            label : { 
                                text: "Closed"},
                        },
                        {
                            dataField: 'assembly',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Assembly P/N"},
                               
                        },
                        
                    ]
                },
               
                {
                    itemType: "group",
                    colCount: 5,
                    colSpan: 3,
                    items: [      
                        {
                            dataField: 'qty',
                            colSpan: 2,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Quantity"},
                           
                        },
                        {
                            dataField: 'requestedBy',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Requested By"},
                           
                        },
                        {
                            dataField: 'partRequestPriorityId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'priority',
                                valueExpr: 'id',
                                dataSource: apis.PRPriorityDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Priority"
                            },
                               
                        },
                        {
                            dataField: 'partRequestStatusId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'text',
                                valueExpr: 'id',
                                dataSource: apis.PartRequestStatusesDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Status"
                            },
                               
                        },
                        
                    ]
                },
                {
                    itemType: "group",
                    colCount: 6,
                    colSpan: 3,
                    items: [    
                        {
                            dataField: 'costing',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Costing in progress"},
                           
                        },  
                        {
                            dataField: 'costingComplete',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Costed"},
                           
                        },
                        {
                            dataField: 'quoted',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Quoted"},
                           
                        },
                        {
                            dataField: 'ordered',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Ordered"},
                           
                        },
                        {
                            dataField: 'allocated',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Allocated"},
                           
                        },
                        {
                            dataField: 'used',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Used"},
                           
                        },
                       
                     
                        
                    ]
                },
                {
                    dataField: 'notes',
                    colSpan: 3,
                    editorType: "dxTextArea",
                    editorOptions: {
                        height: "150px",
                        readOnly: true
                    },
                    label: {
                        text: "Notes"
                    }

                }
                
               
            ]


    }

        let output = null;

       

        if(this.props.selectedPR) {
            output = 
            <ScrollView>
                <Form formData ={this.props.selectedPR} items={formConfig.items}>

                </Form>
            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        selectedPR : state.prDetail.rowData
    };
  }

export default connect(mapStateToProps,null)(PartRequestDetailPopup)