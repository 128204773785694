import React, {Fragment} from 'react';
import { FileUploader } from 'devextreme-react';
import * as apis from '../../../utils/apis';


const WOFileUploader = (props) => {
  return (
 <Fragment>
     <FileUploader 
     selectButtonText={'Select File'} 
     labelText={''} 
     accept={'*'} 
     uploadMode={'useButtons'} 
     multiple={true}
     uploadUrl={apis.WOAPI + 'api/files/uploadfile'}
     onUploaded={props.uploadedHandler} uploadHeaders={{'Authorization' : 'bearer ' +  props.token}}
     >
      </FileUploader>
    </Fragment>
  );
}



export default WOFileUploader