
export const animOff = {
    show: {
        duration: 0
    },
    hide: {
        duration: 0
    }
}

export const notificationTiming = 3000;