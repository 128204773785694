import React, {Component, Fragment} from 'react'
import { connect } from "react-redux";
import { Form, ScrollView } from 'devextreme-react';
import * as apis from '../../../utils/apis';
import notify from 'devextreme/ui/notify';
import {Popup} from 'devextreme-react';
import axios from 'axios';
import { notificationTiming } from '../../../utils/appConfig';

class FirstBookingCheck extends Component {
     
    raiseQuery = (e) => {

        this.queryPopup.instance.show();

        // api/workorderqueries/addQuery/{workorderId}
    }

    submitQuery = (e) => {
        var result = e.validationGroup.validate();
        if(result.isValid) {

            

            let data = {
                workorderId: this.props.woToBookTo.id,
                userGroupId: this.form.instance.option('formData').userGroupId,
                question: this.form.instance.option('formData').question
            }
  
            axios.post('api/workorderqueries/addBookingQuery/' + this.props.woToBookTo.id, data)
            .then(response => {
               
                notify("Query raised successfully.","success", notificationTiming);
                this.form.instance.updateData({userGroupId : null, question: null}); 
    
                this.queryPopup.instance.hide();  

            })
            .catch(error => {
                notify("Unable to raise query.","danger", notificationTiming); 
                console.log(error);
          
            });



            
        }
    }

    makeFirstBooking = (e) => {

        var result = e.validationGroup.validate();
        let pnValid = false;
        let snValid = false;
        let qtyValid = false;
        
        if(result.isValid)
        {
       
            if(this.props.woToBookTo.pnConfirm.toUpperCase().trim() === this.props.woToBookTo.partNumber.toUpperCase().trim()) {
                pnValid = true;
            }
            
            if(this.props.woToBookTo.snConfirm) {
                if(this.props.woToBookTo.serialNo !== null) {
                    if(this.props.woToBookTo.snConfirm.toUpperCase() === this.props.woToBookTo.serialNo.toUpperCase()) {
                        snValid = true;
                    }
                }
            }
            else
            {
                if(this.props.woToBookTo.serialNo === null) {
                    // nothing entered, no existing
                    snValid = true;
                }
            }

           

            if(this.props.woToBookTo.quantityConfirm === this.props.woToBookTo.quantity) {
                qtyValid = true;
            }

            // console.log("Part Number entered", this.props.woToBookTo.pnConfirm);
            // console.log("SerialNumber entered", this.props.woToBookTo.snConfirm);
            // console.log("Qty entered", this.props.woToBookTo.quantityConfirm);

            // console.log("Part Number to check", this.props.woToBookTo.partNumber);
            // console.log("SerialNumber to check", this.props.woToBookTo.serialNo);
            // console.log("Qty to check", this.props.woToBookTo.quantity);

            if(qtyValid && pnValid && snValid) {
            this.props.submitBookOnRequest(this.props.woToBookTo);
            }
            else {
                notify("Details entered do not match those on the workorder, please check or raise a query","error" ,notificationTiming)
            }
        }

    }

  

    render() {
        let queryFormData = {
            userGroupId: null,
            question: null
        }

        let queryForm = {
            items: [
                {
                    dataField: 'userGroupId',
                    colSpan: 1,
                    editorType: "dxSelectBox",
                    editorOptions: {
                        displayExpr: 'name',
                        valueExpr: 'id',
                        dataSource: apis.UserGroupWithAccessDataSource(this.props.user.access_token)
                    },
                    
                    label : { 
                        text: "Responsible Group"},
                        validationRules: [
                    {
                        type: "required",
                        message: "Group is Required.",        
                    },
                    ]
                },
                {
                    dataField: "question",
                    colSpan: 1,
                    editorType: "dxTextArea",
                   
                    editorOptions: {
                        height: "100px",
                      
                
                    },
                    label : { text: "Describe Problems"},
                    validationRules: [
                        {
                            type: "required",
                            message: "You must describe the problem"
                        }
                        ,{
                        type: "stringLength",
                        max: 2048,
                        message: "Queries cannot be longer than 2048 characters."
                    }]
                    
                },
                {
                    itemType: "button",
                    visible: true,
                    horizontalAlignment: "right",
                    colSpan: 1,
                    buttonOptions: {
                        text: "Submit Query",
                        type: "default",
                        useSubmitBehavior: false,
                        onClick: (e) => this.submitQuery(e)
                    }
                }
            ]
        }

        let formConfig = {
            items: [
                {
                    itemType: "group",
                    colCount: 3,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: 'woNumber',
                            colSpan: 1,
                        
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "W/O #"},
                           
                            
                        },                        
                        {
                            dataField: 'workOrderStatusId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'status',
                                valueExpr: 'id',
                                dataSource: apis.WorkorderStatusDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Status"
                            },
                        },
                       
                       
                        {
                            dataField: 'entityId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'shortDescription',
                                valueExpr: 'id',
                                dataSource: apis.EntityDataSource(this.props.user.access_token),
                                readOnly: true,
                            },
                            label : { 
                                text: "Entity"},
                                validationRules: [{
                                type: "required",
                                message: "Entity is Required."
                            },
                            ]
                        },
                        
                    ]
                },
                {
                    itemType: "group",
                    colCount: 4,
                    colSpan: 3,
                    items: [
                        {
                            dataField: 'pnConfirm',
                            colSpan: 1,
                            editorOptions: {
                               
                                readOnly: false
                            },
                            label : { 
                            text: "Part No"},
                            validationRules: [
                                {
                                    type: "required",
                                    message: "Part number is required."
                                },
                                {
                                    type: "stringLength",
                                    max: 32,
                                    message: "Part number cannot be longer than 32 characters."
                                },
                                
                        ]
                           
                            
                        },
                        {
                            dataField: 'partDescription',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Part Description"},
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Part Description is required."
                                    },
                                    {
                                        type: "stringLength",
                                        max: 100,
                                        message: "Part description cannot be longer than 100 characters."
                                    }
                            ]
                        },
                       
                        {
                            dataField: 'snConfirm',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: false
                            },
                            label : { text: "Serial No"},
                            validationRules: [
                                {
                                    type: "stringLength",
                                    max: 50,
                                    message: "Serial number cannot be longer than 50 characters."
                                },
                            
                            ]
                        },
                        {
                            dataField: 'quantityConfirm',
                            colSpan: 1,
                            editorType: "dxNumberBox",
                            editorOptions: {
                                showSpinButtons: true,
                                showClearButton: true,
                                readOnly: false
                            },
                            label : { 
                                text: "Qty"
                            },
                            validationRules: [
                                {
                                    type: "required",
                                    message: "Quantity is required."
                                },
                                {
                                    type: "range",
                                    min: 1,
                                    message: "Quantity must be at least 1"
                                }
                            
                            ]
                           
                        },
                    ]
                },
               {
                itemType: "group",
                colCount: 4,
                colSpan: 3,
                items : [
                    {
                        dataField: 'customerId',
                        colSpan: 1,
                        editorType: "dxSelectBox",
                        editorOptions: {
                            displayExpr: 'customerName',
                            valueExpr: 'id',
                            searchExpr: "customerName",
                            searchEnabled: true,
                            dataSource: apis.CustomersDataSource(this.props.user.access_token),
                            readOnly: true
                        },
                        label : { 
                            text: "Customer"},
                            validationRules: [{
                            type: "required",
                            message: "Customer is Required."
                        },
                        ]
                    },
                    {
                        dataField: 'customerReference',
                        colSpan: 1,
                        editorType: "dxTextBox",
                        editorOptions: {
                            readOnly: true
                        },
                        label : {
                        text: "Customer Ref"},
                        validationRules: [{
                            type: "required",
                            message: "Customer Reference is required."
                        },
                        {
                            type: "stringLength",
                            max: 100,
                            message: "Customer reference cannot be longer than 100 characters."
                        }
                        ]
                    },
                    {
                        dataField: 'dateReceived',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            readOnly: true
                        },
                        label : { 
                            text: "Date Received"
                        },
                        validationRules: [{
                            type: "required",
                            message: "Date Received is Required."
                        },
                        ]
                           
                    },
                    {
                        dataField: 'dueDate',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            readOnly: true
                        },
                        label : { 
                            text: "Due Date"
                        },
                           
                    },
                   
                ]
               },
               {
                    itemType: "group",
                    colCount: 2,
                    colSpan: 3,
                    items: [
                        {
                            dataField: 'warranty',
                            colSpan: 1,
                            editorType: "dxCheckBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : {
                            text: "Warranty"
                            },
                        },
                        {
                            dataField: 'aog',
                            colSpan: 1,
                            editorType: "dxCheckBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : {
                            text: "AOG"},
                           
                        },
                      
                       
                    ]
               },
               {
                itemType: "button",
                visible: true,
                horizontalAlignment: "right",
                colSpan: 1,
                buttonOptions: {
                    text: "Raise Query",
                    type: "danger",
                    useSubmitBehavior: false,
                    onClick: (e) => this.raiseQuery(e)
                },
            },
               {
                itemType: "button",
                visible: true,
                horizontalAlignment: "right",
                colSpan: 1,
                buttonOptions: {
                    text: "Book on to W/O",
                    type: "default",
                    useSubmitBehavior: false,
                    onClick: (e) => this.makeFirstBooking(e)
                }
            },
  
            ]
        }


        let output = null;

        if(this.props.woToBookTo) {
           // if(!this.props.woBookedOnTo)
            output = 
            <Fragment>
            <ScrollView>
                <Form formData ={this.props.woToBookTo} items={formConfig.items}>
                    
                </Form>
            </ScrollView>
             <Popup title="Raise Query" ref={(popup) => {this.queryPopup = popup}} closeOnOutsideClick={true}>
                <Form formData={queryFormData} items={queryForm.items} ref={(form) => {this.form = form}}></Form>
            </Popup>
            </Fragment>
            // if(this.props.woBookedOnTo)
            // output = <div>
            //     <p>You are currently booked on to workorder {this.props.woBookedOnTo.woNumber} please book off that first.</p>
            // </div>
        }


        return  (<div style={{height: '100%'}}>
                { output }
                </div>)
        }
}


const mapStateToProps = (state) => {
    return {
      woToBookTo : state.booking.rowData,
      woBookedOnTo: state.booking.bookedOnToWo,
      user: state.oidc.user,
    };
  }

export default connect(mapStateToProps, null)(FirstBookingCheck)