export const SHOW_ADDRESS_POPUP = 'SHOW_ADDRESS_POPUP';
export const HIDE_ADDRESS_POPUP = 'HIDE_ADDRESS_POPUP';

export const SHOW_CONTACTS_POPUP = 'SHOW_CONTACTS_POPUP';
export const HIDE_CONTACTS_POPUP = 'HIDE_CONTACTS_POPUP';

export const SET_WO_DETAIL = 'SET_WO_DETAIL';

export const SET_WO_CUSTOMER = 'SET_WO_CUSTOMER';
export const SET_NOTE_DETAIL = 'SET_NOTE_DETAIL';
export const SET_FULL_FLAG = 'SET_FULL_FLAG';

export const SET_PR_DETAIL = 'SET_PR_DETAIL';
export const SET_QUERY_DETAIL = 'SET_QUERY_DETAIL';

export const SET_BOOKING = 'SET_BOOKING';
export const SET_INTERACT = 'SET_INTERACT';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const BOOKON_TO_WO = 'BOOKON_TO_WO';
export const BOOK_OFF_ORDER = 'BOOK_OFF_ORDER';

export const BOOK_TO_OTHER = 'BOOK_TO_OTHER';
export const BOOK_OFF_OTHER = 'BOOK_OFF_OTHER';
export const BOOK_OFF_ALL = 'BOOK_OFF_ALL';
export const BOOK_TO_MULTI = 'BOOK_TO_MULTI';

export const SET_USER_RIGHTS = 'SET_USER_RIGHTS';

export const SET_RULE_DETAIL = 'SET_RULE_DETAIL';
export const SET_ACTION_DETAIL = 'SET_ACTION_DETAIL';
