import React, {Component, Fragment} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';

import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Lookup
} from 'devextreme-react/ui/data-grid';
import AddressPopup from '../AddressPopup/AddressPopup';
import ContactPopup from '../ContactPopup/ContactPopup';
import { StringLengthRule } from 'devextreme-react/validator';

const getHeight = () => {
    return (window.innerHeight - 48)
}

class CustomersGrid extends Component {
    state = {
    };


    

    contextMenu = (e) => {
        if (e.row.rowType === "data") {
            var rowData = e.row.data;
            e.items = [{
                text: "Addresses",
                onItemClick: this.props.onShowingAddressPopup,
                rowData: rowData
            },
            {
                text: "Contacts",
                onItemClick: this.props.onShowingContactsPopup,
                rowData: rowData
            }
            ];
        }
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      setDefaultNewValues = (e) => {
        e.data.active = true;
      }

      hidingAddressPopupHandler = () => {
        this.props.onHidingAddressPopup();
      }

      hidingContactsPopupHandler = () => {
          this.props.onHidingContactsPopup();
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    render() {

        let addressPopup = 
        <AddressPopup 
         popupVisible={this.props.viewingAddress}
         hidingPopupHandler={this.hidingAddressPopupHandler}
         />
        
        if(this.props.viewingAddress === false) {
            addressPopup = null;
        }

        let contactPopup = 
        <ContactPopup
        popupVisible={this.props.viewingContacts}
        hidingPopupHandler={this.hidingContactsPopupHandler}
        />
      
        if(this.props.viewingContacts === false) {
            contactPopup = null;
        }

        return  (
            <Fragment>
            <DataGrid 
            dataSource={apis.CustomersDataSource(this.props.user.access_token)}  
            noDataText="There are no customers defined."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'custs' }}
            height={this.state.gridHeight > 0 ? this.state.gridHeight : getHeight }
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onInitNewRow={this.setDefaultNewValues} onContextMenuPreparing={this.contextMenu} onToolbarPreparing={this.onToolbarPreparing}
         >
        
          <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.adminCustomer}
            allowDeleting={this.props.grants.rights.adminCustomer}
            allowAdding={this.props.grants.rights.adminCustomer} >
             <Popup title={'Customers'} showTitle={true} >
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
            </Editing>
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>

        <Column dataField={'customerCode'} caption="Code">
            <RequiredRule message="Customer code is required." />
            <StringLengthRule max="10" message="The customer code cannot exceed 10 characters."/>
        </Column>
        <Column dataField={'customerName'} caption="Customer">
            <RequiredRule message="Customer name is required." />
            <StringLengthRule max="255" message="The customer name cannot exceed 255 characters."/>
        </Column>
        <Column dataField={'currencyId'} caption="Default Currency">
            <Lookup dataSource={apis.CurrencyDataSource(this.props.user.access_token)} displayExpr={'code'} valueExpr={'id'} allowClearing={true} />
        </Column>
        <Column dataField={'defaultShippingMethod'} caption="Default Shipping">
          <StringLengthRule max="255" message="The default shipping method cannot exceed 255 characters."/>
        </Column>
        <Column dataField={'active'} caption="Active" />
        </DataGrid>
        {addressPopup}
        {contactPopup}
        </Fragment>
        );
    }


}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      grants: state.rights,
      viewingAddress: state.customer.viewingAddress,
      viewingContacts: state.customer.viewingContacts
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onShowingAddressPopup: (rowData) => dispatch(actions.showAddressPopup(rowData)),
        onHidingAddressPopup: () => dispatch(actions.hideAddressPopup()),
        onShowingContactsPopup: (rowData) => dispatch(actions.showContactsPopup(rowData)),
        onHidingContactsPopup: () => dispatch(actions.hideContactsPopup()),
    };
  }


export default connect(mapStateToProps, mapDispatchToProps)(CustomersGrid)