import React, {Component} from 'react';
import * as apis from '../../../utils/apis';

import { connect } from "react-redux";

import DataGrid, {
    Column,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Lookup,
    StringLengthRule,
    Format
} from 'devextreme-react/ui/data-grid';
import './ProdQuoteTab.css';

const getHeight = () => {
    return (window.innerHeight - 178)
}

class ProductionQuotes extends Component {
   

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {

      

        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {


        window.removeEventListener('resize', this.resize);
      }

      onEditorPreparing = (e) =>  {
        if (e.dataField === "details")
            e.editorName = "dxTextArea";
      }


      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    highlightActiveQuote = (info) =>  {
      if(info.rowType === 'data') {

          if(info.data.requoted === false && info.data.dateAccepted !== null) {
              info.rowElement.classList.add("currentQuote");
          }

          
      }
  }

    render() {

        let output = <p>Book on to wo</p>;

        if(this.props.woBookedOnTo) {
            output =   <DataGrid 
            dataSource={apis.CustomerQuoteDataSource(this.props.woBookedOnTo.id,this.props.user.access_token)}  
            noDataText="There are no quotes for this W/O"
            rowAlternationEnabled={false}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'quotes' }}
            height={getHeight }
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onInitNewRow={this.setDefaultNewValues} onEditorPreparing={this.onEditorPreparing} onToolbarPreparing={this.onToolbarPreparing}
            onRowPrepared={this.highlightActiveQuote} 
         >
        
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'amount'}>
            <RequiredRule />
        </Column>
        <Column dataField={'currencyId'} caption="Default Currency">
            <RequiredRule />
            <Lookup dataSource={apis.CurrencyDataSource(this.props.user.access_token)} displayExpr={'code'} valueExpr={'id'} allowClearing={true} />
        </Column>
        <Column dataField={'dateQuoted'} dataType="date" format="dd/MM/y"  >
            <RequiredRule />
        </Column>
        <Column dataField={'dateAccepted'} dataType="date" format="dd/MM/y" />
        <Column dataField={'dateRejected'} dataType="date" format="dd/MM/y" />
        <Column dataField={'requoted'} dataType="boolean" visible={false}>
          <FormItem visible={false} />
         </Column>
         <Column dataField={'preApproved'} dataType="boolean" visible={false}>
          <FormItem visible={false} />
         </Column>
        <Column dataField={'labourCost'} dataType="number" visible={false} setCellValue={this.setCellValueLabour}>
          <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'partCost'} dataType="number" visible={false} setCellValue={this.setCellValuePart}>
        <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'serviceCost'} dataType="number" visible={false} setCellValue={this.setCellValueService}>
        <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'costToDate'} dataType="number" visible={false}>
        <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'revisedTarget'} dataType="number" visible={false} caption="Revised Target(Hrs)"/>

        <Column dataField={'details'} caption='Notes'>
            <StringLengthRule max="1024" message="Quote details email cannot exceed 1024 characters."></StringLengthRule>
            <FormItem colSpan="5" editorOptions={{height: "200px"}}></FormItem>
        </Column>
 
        </DataGrid>
        }
        return  (
          <div>
              {output}
          </div>
        );
        
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      woBookedOnTo: state.booking.woToInteract,
    };
  }

export default connect(mapStateToProps)(ProductionQuotes)