import React, {Component} from 'react';
import { connect } from "react-redux";
import { ScrollView, TextArea } from 'devextreme-react';
import axios from 'axios';


class NoteChronologyView extends Component {

    state = {
       fullNote: null,
       noteId: null
    }

   
    componentDidUpdate() {

       if(this.props.selectedNote && this.props.selectedNote.id !== this.state.noteId)
       {
           if(this.props.fullNotes === false)
            axios.get('api/chronology/getfullnotetodate/' + this.props.selectedNote.id)
            .then(response => {
                this.setState({ fullNote: response.data, noteId : this.props.selectedNote.id });
        
            });

            if(this.props.fullNotes === true)
            {
                axios.get('api/chronology/getfullnote/' + this.props.selectedNote.id)
                .then(response => {
                    this.setState({ fullNote: response.data, noteId : this.props.selectedNote.id });
            
                });
            }

        }    
    }

    render() {
    
        let output = null;
  
        if(this.state.fullNote) {
            output = 
            <ScrollView height="100%">
                <TextArea autoResizeEnabled={true} readOnly={true} value={this.state.fullNote.note}></TextArea>
            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        selectedNote : state.notes.rowData,
        fullNotes: state.notes.fullNotes

    };
  }

export default connect(mapStateToProps,null)(NoteChronologyView)