import React, {Component} from 'react';
import * as apis from '../../../utils/apis';
import * as utils from '../common';
import axios from 'axios';
import { connect } from "react-redux";
import './QuoteTab.css';
import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    RangeRule,
    FormItem,
    Form,
    Lookup,
    StringLengthRule,
    Format
} from 'devextreme-react/ui/data-grid';

const getHeight = () => {
    return (window.innerHeight - 98)
}

const dateOptions = {
  dateSerializationFormat : "yyyy-MM-dd"
}

class QuotesTab extends Component {
    state = {
        currencyId: null
    };


    costOptions = {
      format: {
        precision: 2
      }
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {

        axios.get('api/currencydefault/' + this.props.selectedWo.customerId)
        .then(response => {
            this.setState({currencyId: response.data.currencyId});
        });

        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {


        window.removeEventListener('resize', this.resize);
      }

      onEditorPreparing = (e) =>  {
        if (e.dataField === "details")
            e.editorName = "dxTextArea";

        if(e.dataField ==="amount") {
          e.editorOptions.readOnly = true;
        }
      }

      setDefaultNewValues = (e) => {
        e.data.currencyId = this.state.currencyId;
        e.data.preApproved = false;
        e.data.dateQuoted = new Date();
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    setCellValuePart = (newData, value, currentRowData) => {

      let totalCost = 0;

      if (typeof(currentRowData.labourCost) != 'undefined' && currentRowData.labourCost != null)
      {
        totalCost += currentRowData.labourCost;
      }

      if (typeof(currentRowData.serviceCost) != 'undefined' && currentRowData.serviceCost != null)
      {
        totalCost += currentRowData.serviceCost;
      }
      
      newData.partCost = value;

      if(value !== null) {
        totalCost += value;
      }
        newData.amount = totalCost.toFixed(2);
        
        
    }

    setCellValueService = (newData, value, currentRowData) => {

      let totalCost = 0;

      if (typeof(currentRowData.labourCost) != 'undefined' && currentRowData.labourCost != null)
      {
        totalCost += currentRowData.labourCost;
      }

      if (typeof(currentRowData.partCost) != 'undefined' && currentRowData.partCost != null)
      {
        totalCost += currentRowData.partCost;
      }
      
      newData.serviceCost = value;

      if(value !== null) {
        totalCost += value;
      }
        newData.amount = totalCost.toFixed(2);
        
        
    }

    setCellValueLabour = (newData, value, currentRowData) => {

      let totalCost = 0;

      if (typeof(currentRowData.serviceCost) != 'undefined' && currentRowData.serviceCost != null)
      {
        totalCost += currentRowData.serviceCost;
      }

      if (typeof(currentRowData.partCost) != 'undefined' && currentRowData.partCost != null)
      {
        totalCost += currentRowData.partCost;
      }
      
      newData.labourCost = value;

      if(value !== null) {
        totalCost += value;
      }
        newData.amount = totalCost.toFixed(2);
        
        
    }

    setCellValueApproved = (newData, value, currentRowData) => {
     
      if(value === true) {

        newData.dateAccepted = new Date();
      }
      newData.preApproved = value;
    }

   
    highlightActiveQuote = (info) =>  {
      if(info.rowType === 'data') {

          if(info.data.requoted === false && info.data.dateAccepted !== null) {
              info.rowElement.classList.add("currentQuote");
          }

          
      }
  }

  allowUpdating =(e) => {
    return e.row.data.dateAccepted === null && this.props.grants.rights.editQuote && !(this.props.selectedWo.locked === true || this.props.selectedWo.complete === true)
  }

    render() {
        return  (
            <DataGrid 
            dataSource={apis.CustomerQuoteDataSource(this.props.selectedWo.id,this.props.user.access_token)}  
            noDataText="There are no quotes for this W/O"
            rowAlternationEnabled={false}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'quotes' }}
            height={getHeight }
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onInitNewRow={this.setDefaultNewValues} onEditorPreparing={this.onEditorPreparing} onToolbarPreparing={this.onToolbarPreparing}
            onRowPrepared={this.highlightActiveQuote} 
         >
         <Editing
            mode={'popup'}
            allowUpdating={this.allowUpdating}
            allowDeleting={this.props.grants.rights.editQuote && !(this.props.selectedWo.locked === true || this.props.selectedWo.complete === true)}
            allowAdding={this.props.grants.rights.addQuote && !(this.props.selectedWo.locked === true || this.props.selectedWo.complete === true)} >
            <Form colCount="5">         
            </Form>
             <Popup title={'Customer Quote'} showTitle={true} width={utils.popupWidth()} height={utils.popupHeight()}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
        </Editing>
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'amount'} dataType="number" caption="Total Cost">
            {/* <RangeRule message="Total Labour cost must be more than zero" min={1} /> */}
            <RequiredRule />
            <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'currencyId'} caption="Default Currency">
            <RequiredRule />
            <Lookup dataSource={apis.CurrencyDataSource(this.props.user.access_token)} displayExpr={'code'} valueExpr={'id'} allowClearing={true} />
        </Column>
        <Column dataField={'dateQuoted'} dataType="date" format="dd/MM/y" editorOptions={dateOptions}>
          
            <RequiredRule />
        </Column>
        <Column dataField={'dateAccepted'} dataType="date" format="dd/MM/y" editorOptions={dateOptions} />
        <Column dataField={'dateRejected'} dataType="date" format="dd/MM/y" editorOptions={dateOptions} />
     
        <Column dataField={'requoted'} dataType="boolean" visible={false}>
          <FormItem visible={false} />
         </Column>
        <Column dataField={'labourCost'} dataType="number" visible={false} setCellValue={this.setCellValueLabour} editorOptions={this.costOptions}>
          <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'partCost'} dataType="number" visible={false} setCellValue={this.setCellValuePart} editorOptions={this.costOptions}>
        <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'serviceCost'} dataType="number" visible={false} setCellValue={this.setCellValueService} editorOptions={this.costOptions}>
        <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'costToDate'} dataType="number" visible={false} editorOptions={this.costOptions} >

        <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        <Column dataField={'revisedTarget'} dataType="number" visible={false} caption="Revised Target(Hrs)"/>
      
        <Column dataField={'preApproved'} dataType="boolean" visible={false} setCellValue={this.setCellValueApproved}>
        <FormItem colSpan="4"></FormItem>
       </Column>
       <Column dataField={'invoiceHours'} dataType="number" visible={false} caption="Invoiced Hours" showInColumnChooser={this.props.grants.rights.editQuote === true || this.props.grants.rights.addQuote === true} setCellValue={this.setCellValueInv} editorOptions={this.costOptions}>
       <RequiredRule />
       
       </Column>
        <Column dataField={'details'} caption='Notes'>
            <StringLengthRule max="1024" message="Quote details cannot exceed 1024 characters."></StringLengthRule>
            <FormItem colSpan="5" editorOptions={{height: "200px"}}></FormItem>
        </Column>
 
        </DataGrid>
        );
        
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      selectedWo: state.woDetail.rowData,
      grants: state.rights
    };
  }

export default connect(mapStateToProps)(QuotesTab)