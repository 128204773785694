import React, {Component} from 'react';
import { ScrollView } from 'devextreme-react';
//import LabourForm from '../../WorkorderTabs/LabourTab/LabourForm/LabourForm';
import ProdLabourForm from './ProdLabourForm';


const getHeight = () => {
    return (window.innerHeight - 105)
}


class ProductionLabour extends Component {
    render() {
        return (
            <ScrollView height={getHeight()}>
            <ProdLabourForm></ProdLabourForm>
            </ScrollView>
        ) 
        
    }
}

export default ProductionLabour