import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import QueryDetailsPopup from '../QueryDetailsPopup/QueryDetailsPopup';
import {Popup} from 'devextreme-react';

import DataGrid, {
    Column,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Export

} from 'devextreme-react/ui/data-grid';

const getHeight = () => {
    return (window.innerHeight - 48)
}

class QueryEnquiry extends Component {
    state = {
    };

    

    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

     
      infoClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingQuery(e.row.data);
        this.popup.instance.show();
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }
    
    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.AllQueryDataSource(this.props.user.access_token)}  
            noDataText="There are no matching queries in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'queryenq' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} onToolbarPreparing={this.onToolbarPreparing}
         >
        <Export enabled={this.props.grants.rights.exportData} fileName="queryenquiry" />
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        <Column dataField={'queryIdentifier'} dataType="string" >
            
        </Column>
        <Column dataField={'id'} visible={false} dataType="number">
            
        </Column>
     
       
        <Column dataField={'raisedBy'} dataType="string">
      
       
        </Column>
        <Column dataField={'claimedBy'} dataType="string" >
         
      
        </Column>
        <Column dataField={'closedBy'} dataType="string" >
         
      
        </Column>
        <Column dataField={'dateOpened'}  dataType="date" format="dd/MM/y">
        </Column>
        <Column dataField={'dateResolved'}  dataType="date" format="dd/MM/y">

        </Column> 
    
        <Column dataField={'userGroupId'} caption="Responsible" dataType="string">
            <Lookup dataSource={apis.UserGroupDataSource(this.props.user.access_token)} displayExpr={'name'} valueExpr={'id'} />
        </Column>

        <Column dataField={'queryStatusId'} caption="Status" dataType="number">
            <Lookup dataSource={apis.QueryStatusDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} />
        </Column>


        <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
          buttons={[ {
            hint: 'Details',
            icon: 'info',
            onClick: this.infoClickedHandler
          }]} />
   
        </DataGrid>
            <Popup title="Query details" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <QueryDetailsPopup></QueryDetailsPopup>
            </Popup>
      

        </div>
        );
    }


}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      grants: state.rights
  };
}

const mapDispatchToProps = dispatch => {
  return {
       onSelectingQuery: (rowData) => dispatch(actions.setQueryDetail(rowData)),
     
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(QueryEnquiry)