import React, {Component} from 'react';
import { connect } from "react-redux";

import {  Form, ScrollView } from 'devextreme-react';
import { SimpleItem, ButtonOptions } from 'devextreme-react/form';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import {notificationTiming} from '../../utils/appConfig';
import WOCompleteRuleResults from '../WOSummary/WOCompleteRuleResults/WOCompleteRuleResults';
class CompleteWorkorderPopup extends Component {
    state =  {
        id: null
    }

    closeClicked = (e) => {

        var result = e.validationGroup.validate();
        if(result.isValid)
        {
        var submission = this.form.instance.option("formData");

        let data = {
            id: this.props.selectedWo.id,
        }
        data.closeNotes = submission.closeNotes;
       
        axios.post('api/workorders/closeworkorder', data)
        .then(response => {  
            notify("Workorder closed successfully.","success", notificationTiming);
            this.props.closedHandler();
        })
        .catch(error => {   
            notify(error.response.data,"error", notificationTiming);
        });
        
       }
    }

    tbOptions = {
        height: '100px',
    }


    formData = {
      
        closeNotes: null

    }

    componentDidUpdate() {
        if(this.props.selectedWo) {
            if(this.state.id !== this.props.selectedWo.id) {
                this.form.instance.updateData(
                    {
                        closeNotes: null,
                    });
                this.setState({ id:  this.props.selectedWo.id});
            }
        }
    }
   

    render() {

        let output = null;
       

        if(this.props.selectedWo) {
            

            output = 
            <ScrollView>
              <WOCompleteRuleResults></WOCompleteRuleResults>
               <Form formData={this.formData} ref={(form) => {this.form = form}} >
               <SimpleItem dataField="closeNotes" editorType="dxTextArea" editorOptions={this.tbOptions} />
                    <SimpleItem itemType="empty"></SimpleItem>
                    <SimpleItem itemType="button" horizontalAlignment="right" visible="true" colSpan="1">
                        <ButtonOptions 
                        type="success"
                        text="Complete Workorder"
                        useSubmitBehavior="false"
                        onClick={(e) => this.closeClicked(e)}></ButtonOptions>
                    </SimpleItem>
                    </Form>
            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        selectedWo: state.woDetail.rowData
    };
  }

export default connect(mapStateToProps,null)(CompleteWorkorderPopup)