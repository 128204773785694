import * as actionTypes from '../actions/actionTypes';

const initialState = {
    rowData: null,
    bookedOnToWo: [],
    bookedToOther: false,
    woToInteract: null
}

const bookingReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_BOOKING:
         
            return {
                ...state,
                rowData: action.rowData

            }
            case actionTypes.SET_INTERACT:
                return {
                    ...state,
                    woToInteract: action.rowData
                }

        case actionTypes.SET_BOOKINGS:
     
            let bookedOnToWoDb = [...state.bookedOnToWo.slice()];
         //   console.log(bookedOnToWoDb);
            if(action.bookings.data.length > 0) {
                action.bookings.data.forEach(element => {
                    bookedOnToWoDb.push(element);
                });
            }
           

            return {
                ...state,
                bookedOnToWo: bookedOnToWoDb
            }    
        case actionTypes.BOOKON_TO_WO:

            let bookedOnToWoCopy = [...state.bookedOnToWo.slice()];
            

            bookedOnToWoCopy.push(action.rowData);
            return {
                ...state,
                bookedOnToWo: bookedOnToWoCopy,
                bookedToOther: false
            }
            case actionTypes.BOOK_OFF_ORDER:

                let bookedOnToCopy = [...state.bookedOnToWo.slice()];
                bookedOnToCopy.splice(bookedOnToCopy.findIndex(item => item.id === action.id), 1)
               
    
                
                return {
                    ...state,
                    bookedOnToWo: bookedOnToCopy,
                    bookedToOther: false
                }
        case actionTypes.BOOK_TO_OTHER:
        return {
            ...state,
            bookedOnToWo: [],
            bookedToOther: true
        }
        case actionTypes.BOOK_OFF_ALL:
        return {
            ...state,
            bookedOnToWo: [],
            bookedToOther: false
        }
        case actionTypes.BOOK_OFF_OTHER:
        return {
            ...state,
            bookedOnToWo: [],
            bookedToOther: false
        }
        case actionTypes.BOOK_TO_MULTI:
        
            let bookedOnToWoCurrent = [...state.bookedOnToWo.slice()];
            
            let newBookings = bookedOnToWoCurrent.concat(action.orders);
        return {
            ...state,
            bookedOnToWo: newBookings,
            bookedToOther: false
        }
        
        default:
            return state;

    }
}

export default bookingReducer;