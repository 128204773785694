import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import TreeView from 'devextreme-react/ui/tree-view';
import * as actions from '../../store/actions/actions';
import axios from 'axios';

class MainMenu extends Component {
    state = {
        treeViewHeight: 0,
    }

    menuSelectedHandler = (e) => {
    
     //   e.component.selectItem(e.itemData.id);
        
        //console.log(e);
        this.props.history.push('/' + e.itemData.path);
        
      
    }

    getHeight = () => {
        return window.innerHeight - 48;
    }

    componentDidMount() {
        this.updateCounts();
       
       
        this.timerID = setInterval(
              () => this.updateCounts(),
              1000 * 60 * 5
            );
         

        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        clearInterval(this.timerID);
        window.removeEventListener('resize', this.resize);
      }

      updateCounts() {
        axios.get('api/menucounts/')
        .then(response => {
            
            this.menuItems[5].badge = response.data.woCount;
            this.plainMenuItems[5].badge = response.data.woCount;
            this.menuItems[6].badge = response.data.queryCount;
            this.plainMenuItems[6].badge = response.data.queryCount;
            this.menuItems[7].badge = response.data.partCount;
            this.plainMenuItems[7].badge = response.data.partCount;
            this.plainMenuItems[1].badge = response.data.allocatedToUser;
            this.menuItems[1].badge = response.data.allocatedToUser;
            this.menuItems[3].badge = response.data.unallocatedCount;
            this.plainMenuItems[3].badge = response.data.unallocatedCount;
            this.treeView.instance.repaint();
        });
      }


    resize=() => {
        this.setState({treeViewHeight : window.innerHeight - 48})
    }

    renderTreeViewItem = (itemData) => {
     
        
        
    return(<div>{itemData.name}
    {itemData.badge != null && itemData.badge !== 0 ? <span className='dx-list-item-badge dx-badge'>{itemData.badge}</span> : ''}
    </div>)
    }

    woCount = 0;
    queryCount = 0;
    partCount = 0;
    unallocateCount = 0;
    allocatedToUser = 0;

    menuItems =  [
        {
            id: '1',
            name: 'Production',
            expanded: false,
            path: '',        
        },
        {
            id: '1_1',
            parentId: '1',
            name: 'W/O Live',
            expanded: false,
            path: 'production/live',
            badge: this.allocatedToUser,
        },
        {
            id: '2',
            name: 'Workflow',
            expanded: false,
        },
        {
            id: '2_1',
            parentId: "2",
            name: 'W/O Allocation',
            path: 'workflow/allocation',
        },
        {
            id: '2_2',
            parentId: "2",
            name: 'Bookings',
            expanded: false,
            path: 'admin/bookings',
        },
        {
            id: '2_3',
            parentId: "2",
            name: 'Workorders',
            expanded: false,
            path: 'workflow/workorders',
            badge: this.woCount,
        },
        {
            id: '2_4',
            parentId: "2",
            name: 'Queries',
            expanded: false,
            path: 'workflow/queries',
            badge: this.queryCount,     
        },
        {
            id: '2_5',
            parentId: "2",
            name: 'Part Requests',
            expanded: false,
            path: 'workflow/parts',
            badge: this.partCount,       
        },
        {
            id: '2_6',
            parentId: "2",
            name: 'Subcontracts',
            expanded: false,
            visible: false,      
        },
        {
            id: '3',
            name: 'Enquiry',
            expanded: false,
         
        },
      
        {
            id: '3_1',
            parentId: "3",
            name: 'Workorders',
            expanded: false,
            path: 'enquiry/workorders',
          },
        {
            id: '3_2',
            parentId: "3",
            name: 'Part Requests',
            expanded: false,
            path: 'enquiry/partrequests',
         },
        {
            id: '3_3',
            parentId: "3",
            name: 'Queries',
            expanded: false,
            path: 'enquiry/queries',
        },
        {
            id: '4',
            name: 'Admin',
            expanded: false,
            visible: true,
         },
        {
            id: '4_1',
            parentId: "4",
            name: 'Workorders',
            expanded: false,
            path: 'admin/workorders',
        },
       
        {
            id: '4_2',
            parentId: "4",
            name: 'Part Requests',
            path: 'admin/partrequests'
        },
        {
            id: '4_3',
            parentId: "4",
            name: 'Queries',
            path:'admin/queries'
        },
        {
            id: '4_4',
            parentId: "4",
            name: 'Workorder Setup'
        },
        {
            id: '4_4_1',
            parentId: "4_4",
            name: 'Statements'
        },
        {
            id: '4_4_2',
            parentId: "4_4",
            name: 'W/O Status',
            path: 'admin/workorderstatuses'
        },
        {
            id: '4_4_3',
            parentId: "4_4",
            name: 'W/O Types',
            path: 'admin/workordertypes'
        },
        {
            id: '4_4_4',
            parentId: "4_4",
            name: 'W/O Priorities',
            path: 'admin/workorderpriorities'
        },
        {
            id: '4_4_5',
            parentId: "4_4",
            name: 'Release Types',
            path: 'admin/releasetypes'
        },
        {
            id: '4_4_6',
            parentId: "4_4",
            name: 'Part Request Statuses',
            path: 'admin/partrequeststatuses'
        },
        {
            id: '4_4_7',
            parentId: "4_4",
            name: 'W/O Status Mapping',
            path: 'admin/wostatusmap'
        },
        {
            id: '4_4_8',
            parentId: "4_4",
            name: 'Part Request Status Mapping',
            path: 'admin/prstatusmap'
        },
        {
            id: '4_4_9',
            parentId: "4_4",
            name: 'Part Request Priorities',
            path: 'admin/prpriorities'
        },
        {
            id: '4_4_10',
            parentId: "4_4",
            name: 'File Types',
            path: 'admin/filetypes'
        },
        {
            id: '4_5',
            parentId: '4',
            name: 'Address Setup'
        },
        {
            id: '4_5_1',
            parentId: '4_5',
            name: 'Countries',
            path: 'admin/countries'
        },
        {
            id: '4_5_2',
            parentId: '4_5',
            name: 'Address Types',
            path: 'admin/addresstypes'
        },
        {
            id: '4_6',
            parentId: '4',
            name: 'Booking Setup'
        },
        {
            id: '4_6_1',
            parentId: '4_6',
            name: 'Booking Types',
            path: 'admin/bookingtypes'
        },
        {
            id: '4_6_2',
            parentId: '4_6',
            name: 'Booking Off Reasons',
            path: 'admin/bookingreasons'
        },
        {
            id: '4_6_3',
            parentId: '4_6',
            name: 'Booking -> Status Map',
            path: 'admin/bookingstatusmap'
        },
        {
            id: '4_7',
            parentId: '4',
            name: 'Currencies',
            path: 'admin/currencies'
        },
        {
            id: '4_8',
            parentId: '4',
            name: 'Security Rights',
            path: 'admin/availablerights'
        },
        {
            id: '4_8_1',
            parentId: "4_8",
            name: 'Group Rights',
            path: 'admin/security'
        },
        {
            id: '4_8_2',
            parentId: "4_8",
            name: 'User Rights',
            path: 'admin/usersecurity'
        },
        {
            id: '4_9',
            parentId: "4",
            name: 'Query Setup',
            path: 'admin/querystatus'
        },
        
        {
            id: '4_10',
            parentId: "4",
            name: 'Customer Setup',
            path: 'admin/customers'
        },
        {
            id: '4_11',
            parentId: "4",
            name: 'Rules Setup',
            path: 'admin/rules'
        }
       
    ];

    plainMenuItems =  [
        {
            id: '1',
            name: 'Production',
            expanded: false,
            path: '' 
          
        },
        {
            id: '1_1',
            parentId: '1',
            name: 'W/O Live',
            expanded: false,
            path: 'production/live',
            badge: this.allocatedToUser 
        },
        {
            id: '2',
            name: 'Workflow',
            expanded: false
        },
        {
            id: '2_1',
            parentId: "2",
            name: 'W/O Allocation',
            path: 'workflow/allocation',
            badge: this.unallocatedCount 
        },
        {
            id: '2_2',
            parentId: "2",
            name: 'Bookings',
            expanded: false,
            path: 'admin/bookings'
        },
        {
            id: '2_3',
            parentId: "2",
            name: 'Workorders',
            expanded: false,
            path: 'workflow/workorders',
            badge: this.woCount
        },
        {
            id: '2_4',
            parentId: "2",
            name: 'Queries',
            expanded: false,
            path: 'workflow/queries',
            badge: this.queryCount
        },
        {
            id: '2_5',
            parentId: "2",
            name: 'Part Requests',
            expanded: false,
            path: 'workflow/parts',
            badge: this.partCount
        },
        {
            id: '2_6',
            parentId: "2",
            name: 'Subcontracts',
            expanded: false,
            visible: false
           
        },
        {
            id: '3',
            name: 'Enquiry',
            expanded: false,
          
        },
        
        {
            id: '3_1',
            parentId: "3",
            name: 'Workorders',
            expanded: false,
            path: 'enquiry/workorders'
        },
        {
            id: '3_2',
            parentId: "3",
            name: 'Part Requests',
            expanded: false,
            path: 'enquiry/partrequests'
        },
        {
            id: '3_3',
            parentId: "3",
            name: 'Queries',
            expanded: false,
            path: 'enquiry/queries'
        },
        {
            id: '4',
            name: 'Admin',
            expanded: false,
            visible: false
        },
        {
            id: '4_1',
            parentId: "4",
            name: 'Workorders',
            expanded: false,
            path: 'admin/workorders'
        },
       
        {
            id: '4_2',
            parentId: "4",
            name: 'Part Requests',
            path: 'admin/partrequests'
        },
        {
            id: '4_3',
            parentId: "4",
            name: 'Queries',
            path:'admin/queries'
        },
        {
            id: '4_4',
            parentId: "4",
            name: 'Workorder Setup'
        },
        {
            id: '4_4_1',
            parentId: "4_4",
            name: 'Statements'
        },
        {
            id: '4_4_2',
            parentId: "4_4",
            name: 'W/O Status',
            path: 'admin/workorderstatuses'
        },
        {
            id: '4_4_3',
            parentId: "4_4",
            name: 'W/O Types',
            path: 'admin/workordertypes'
        },
        {
            id: '4_4_4',
            parentId: "4_4",
            name: 'W/O Priorities',
            path: 'admin/workorderpriorities'
        },
        {
            id: '4_4_5',
            parentId: "4_4",
            name: 'Release Types',
            path: 'admin/releasetypes'
        },
        {
            id: '4_4_6',
            parentId: "4_4",
            name: 'Part Request Statuses',
            path: 'admin/partrequeststatuses'
        },
        {
            id: '4_4_7',
            parentId: "4_4",
            name: 'W/O Status Mapping',
            path: 'admin/wostatusmap'
        },
        {
            id: '4_4_8',
            parentId: "4_4",
            name: 'Part Request Status Mapping',
            path: 'admin/prstatusmap'
        },
        {
            id: '4_4_9',
            parentId: "4_4",
            name: 'Part Request Priorities',
            path: 'admin/prpriorities'
        },
        {
            id: '4_4_10',
            parentId: "4_4",
            name: 'File Types',
            path: 'admin/filetypes'
        },
        {
            id: '4_5',
            parentId: '4',
            name: 'Address Setup'
        },
        {
            id: '4_5_1',
            parentId: '4_5',
            name: 'Countries',
            path: 'admin/countries'
        },
        {
            id: '4_5_2',
            parentId: '4_5',
            name: 'Address Types',
            path: 'admin/addresstypes'
        },
        {
            id: '4_6',
            parentId: '4',
            name: 'Booking Setup'
        },
        {
            id: '4_6_1',
            parentId: '4_6',
            name: 'Booking Types',
            path: 'admin/bookingtypes'
        },
        {
            id: '4_6_2',
            parentId: '4_6',
            name: 'Booking Off Reasons',
            path: 'admin/bookingreasons'
        },
        {
            id: '4_6_3',
            parentId: '4_6',
            name: 'Booking -> Status Map',
            path: 'admin/bookingstatusmap'
        },
        {
            id: '4_7',
            parentId: '4',
            name: 'Currencies',
            path: 'admin/currencies'
        },
        {
            id: '4_8',
            parentId: '4',
            name: 'Security Rights'
        },
        {
            id: '4_8_1',
            parentId: "4_8",
            name: 'Granted Rights'
        },
        {
            id: '4_9',
            parentId: "4",
            name: 'Query Setup',
            path: 'admin/querystatus'
        },
        
        {
            id: '4_10',
            parentId: "4",
            name: 'Customer Setup',
            path: 'admin/customers'
        },
        {
            id: '4_11',
            parentId: "4",
            name: 'Rules Setup',
            path: 'admin/rules'
        }
       
    ];

    render() {

        if(this.props.grants.rights.viewAdminMenu === false) {
            return (
                <div>
                <TreeView dataSource = {this.plainMenuItems} 
                dataStructure="plain"
                parentIdExpr="parentId" itemRender={this.renderTreeViewItem}
                displayExpr="name"  
                searchEnabled={true}
                selectionMode="single"
                selectByClick={true}
                selectNodesRecursive={false}
                height={this.state.treeViewHeight > 0 ? this.state.treeViewHeight : this.getHeight }
                onItemClick={this.menuSelectedHandler}
                ref={(tv) => {this.treeView = tv}} />
            </div>
            );


        }
        return(
        <div>
            <TreeView dataSource = {this.menuItems}
            dataStructure="plain"
            parentIdExpr="parentId"
            displayExpr="name" itemRender={this.renderTreeViewItem}
            searchEnabled={true}
            selectionMode="single"
            selectByClick={true}
            selectNodesRecursive={false}
            height={this.state.treeViewHeight > 0 ? this.state.treeViewHeight : this.getHeight }
            onItemClick={this.menuSelectedHandler}
            ref={(tv) => {this.treeView = tv}} />
        </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      grants: state.rights
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
         onSettingRights: (rights) => dispatch(actions.setUserRights(rights)),
    };
  }

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(MainMenu))

