import React, {Component} from 'react';
import * as apis from '../../../utils/apis';
import BookToWorkorderPopup from './BookToWorkorderPopup/BookToWorkorderPopup';
import BookToGeneralPopup from '../BookToGeneralPopup/BookToGeneralPopup';
import BookOffPopup from '../BookOffPopup/BookOffPopup';
import * as actions from '../../../store/actions/actions';
import { connect } from "react-redux";
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import сBox from "devextreme/ui/check_box";
import BookOffAllPopup from '../../BookOffAllPopup/BookOffAllPopup';
import { confirm } from 'devextreme/ui/dialog';
import DataGrid, {
    Column,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Selection,
    KeyboardNavigation
} from 'devextreme-react/ui/data-grid';

import {Popup} from 'devextreme-react';
import ProductionDetailsTab from '../ProductionDetailsTab/ProductionDetailsTab';
import { notificationTiming } from '../../../utils/appConfig';

const getHeight = () => {
    return (window.innerHeight - (98))
}

const fullHeight = () => {
    return (window.innerHeight - 30);
}

const fullWidth = () => {
    return (window.innerWidth - 30);
}

class ProductionQueueTab extends Component {
    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }

     highlightBooked = (info) =>  {
        if(info.rowType === 'data') {
            if(info.data.aog === true) {
                info.rowElement.classList.add("aog");
            }

            if (info.data.bookedUserIds.includes(this.props.user.profile.sub)) {
                info.rowElement.classList.add("booked");
            }
            
        
        }
    }

    bookOnRequest = (rowData) => {
        
        let data = {
            workorderId: rowData.id
        }

        axios.post('api/production/bookon', data)
        .then(response => {
           
            notify("Booked to workorder successfully.","success", notificationTiming);
            this.gridview.instance.refresh();
            this.props.onBookedOn(rowData);
            this.popup.instance.hide();      

        })
        .catch(error => {
            notify("Unable to book on to workorder.","danger", notificationTiming); 
            console.log(error);
      
        });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh();
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                 icon: 'clock', 
                   onClick: this.bookSelection,
                   visible: true
                }
              },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                 icon: 'clearsquare', 
                   onClick: this.bookOffMainHandler
                }
              },
              {
            location: 'after',
            widget: 'dxButton',
            options: {
             icon: 'coffee',  
              onClick: this.bookToNpHandler
            }
          }, {
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }


    bookOnHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingWOToBook(e.row.data);
        this.popup.instance.show();
    }

    bookToNpHandler = (e) => {
        e.event.preventDefault();
        this.generalpopup.instance.show();
    }

    booktonp = (params,id) => {
        var result = params.validationGroup.validate();
        if(result.isValid)
        {
            let data = {
                activityId: id
            }
    
            axios.post('api/production/booktononprod', data)
            .then(response => {
               
                notify("Booked to activity successfully.","success", notificationTiming);
                this.props.onBookedToOther();
                this.gridview.instance.refresh();
                this.generalpopup.instance.hide();
    
            })
            .catch(error => {
                notify("Unable to book on to activity.","danger", notificationTiming); 
                console.log(error);
          
            });
        }
    }

    bookOffMainHandler = (e) => {
        e.event.preventDefault();
        this.bookoffallpopup.instance.show();
    }

    bookOffHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingWOToInteract(e.row.data);
        this.bookoffpopup.instance.show();
    }

    bookOffNp = () => {
        let data = {
            activity: true
        }

        axios.post('api/production/bookoffactivity', data)
        .then(response => {
           
            notify("Booked off non workorder time successfully.","success", notificationTiming);
            this.props.onBookedOffNp();
            this.generalpopup.instance.hide();

        })
        .catch(error => {
            notify("Unable to book off activity.","danger", notificationTiming); 
            console.log(error);
      
        });
    }

    simpleBookOff = (woId) => {
        let data = {
            workorder: true,
            workorderId: woId,
        }

        axios.post('api/production/bookoffsimple', data)
        .then(response => {
           
            notify("Booked off workorder successfully.","success", notificationTiming);
           // this.props.onBookedOn(null);
           this.props.onBookedOff(data.workorderId);
            this.bookoffpopup.instance.hide();
            this.gridview.instance.refresh();
        })
        .catch(error => {
            notify("Unable to book off workorder.","danger", notificationTiming); 
            console.log(error);
      
        });
    }

    bookOff = (params,reasonId,woId,notes) => {
        var result = params.validationGroup.validate();
        if(result.isValid)
        {
            let data = {
                workorderId: woId,
                reasonId: reasonId,
                notes: notes
            }
  
            axios.post('api/production/bookoffworkorder', data)
            .then(response => {
               
                notify("Booked off workorder successfully.","success", notificationTiming);
                
                  // this.props.onBookedOn(null);
                  this.props.onBookedOff(data.workorderId);
                this.bookoffpopup.instance.hide();
                this.gridview.instance.refresh();
    
            })
            .catch(error => {
                notify("Unable to book off workorder.","danger", notificationTiming); 
                console.log(error);
          
            });
        }
    }
    isBookIconVisible = (e) => {   
        return !e.row.isEditing &&
        !e.row.data.bookedUserIds.includes(this.props.user.profile.sub) && e.row.data.bookOnCheckCompleted === true;
      }

    isBookOffIconVisible = (e) => {    
        return !e.row.isEditing && 
        (e.row.data.bookedUserIds.includes(this.props.user.profile.sub));
    }
 
    contextClicked = (e) => {
        if(e.itemData.text === "Details")
        {
            // this.detailpopup.instance.option("title","Details: WO#" + e.itemData.rowData.woNumber)
            // this.detailpopup.instance.show();
            e.event.preventDefault();
            this.props.onSelectingWOToInteract(e.itemData.rowData);
            this.detailpopup.instance.option("title","Details: WO#" + e.itemData.rowData.woNumber)
            this.detailpopup.instance.show();   
           
        }
    }

    detailClicked = (e) => {
        e.event.preventDefault();
        this.props.onSelectingWOToInteract(e.row.data);
        this.detailpopup.instance.option("title","Details: WO#" + e.row.data.woNumber)
        this.detailpopup.instance.show();
    }

    closeDetails = (e) => {
        this.gridview.instance.refresh();
    }

    contextMenu = (e) => {
        if (e.row.rowType === "data" &&  (e.row.data.bookedUserIds.includes(this.props.user.profile.sub))) {
            var rowData = e.row.data;
            e.items = [
                {
                      
                    text: "Details",
                    onItemClick: this.contextClicked,
                    rowData: rowData
                
                },
               
            ];
        }
    }

    bookSelection = () => {
        var selectedRowsData = this.gridview.instance.getSelectedRowsData();

        let data = {
            woIds: []
        }

        if(selectedRowsData.length === 0) {
            notify("You must select orders to book to first","error", notificationTiming); 
        }
        else {
            let msg = "Are you sure you want to book to all of the following orders<br /><br />";
            for(var i = 0; i < selectedRowsData.length; i++) {
                msg += "<strong>W/O: " + selectedRowsData[i].woNumber + "</strong> " + selectedRowsData[i].partNumber + " - " + selectedRowsData[i].partDescription + "<br />";
                data.woIds.push(selectedRowsData[i].id);
            }
            

            let result = confirm(msg, "Book To Multiple Orders");
            result.then((dialogResult) => {
                if(dialogResult === true) {
                    // need to update on server first.

                    axios.post('api/production/booktomultiple', data)
                    .then(response => {
                       
                        notify("Booked to workorders successfully.","success", notificationTiming);
        
                        this.gridview.instance.refresh();
                        this.gridview.instance.clearSelection();
                        // need to splice into existing state all rows,
                        this.props.onBookedToMulti(selectedRowsData);
                        this.generalpopup.instance.hide();
            
                    })
                    .catch(error => {
                        notify("Unable to book on to selected orders.","error", notificationTiming); 
                        console.log(error);
                    });

                }
                
            });
        }
       
    }

    selectionChanged = (e) => {       
        for(var i =0; i < e.selectedRowsData.length ; i ++) {
            if(e.selectedRowsData[i].production === false || e.selectedRowsData[i].bookOnCheckCompleted === false || e.selectedRowsData[i].bookedUserIds.includes(this.props.user.profile.sub)) {
                this.gridview.instance.deselectRows(e.selectedRowsData[i].id)
            }
        }
        
    }

    allowSelection = (e) => {

        if (e.rowType === "data" & e.column.command === 'select') {
            var instance = e.cellElement.getElementsByClassName('dx-select-checkbox');
            
 
            let cbInstance = сBox.getInstance(instance[0]);
            
            if(e.row.data.bookOnCheckCompleted === false || e.row.data.production === false) {
                cbInstance.option("disabled", true); 
            }
            else  {
                if((e.row.data.bookedUserIds.includes(this.props.user.profile.sub)))
                {
                     cbInstance.option("visible", false);  
                }
            }

        } 
    }

    bookOffAllRequest = () => {

        let data = {
            userId: this.props.user.profile.sub,
       
        }

        axios.post('api/production/bookoffallworkorders', data)
        .then(response => {
           
           notify("Booked off all workorders successfully.","success", notificationTiming);
           this.gridview.instance.refresh();
   
           this.props.onBookedOffAll();
           this.bookoffallpopup.instance.hide();

        })
        .catch(error => {
            notify("Unable to book off all workorders.","danger", notificationTiming); 
        });
    }

    render() {
        return  (<div>
            <DataGrid 
            dataSource={apis.ProductionWODataSource(this.props.user.access_token)}  
            noDataText="There are no matching workorders in the queue."
            rowAlternationEnabled={false}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'prwoGrid' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}}
         onCellPrepared ={this.allowSelection} onRowPrepared={this.highlightBooked}
         onToolbarPreparing={this.onToolbarPreparing} 
         onContextMenuPreparing={this.contextMenu}
         onSelectionChanged={this.selectionChanged}
            >
          <Selection 
            mode="multiple"
            allowSelectAll={false}
            
            showCheckBoxesMode='always'
          />
          <KeyboardNavigation enabled={false} />
       
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
       

        
        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'woNumber'} caption="W/O#">
            <FormItem visible={false} />
        </Column>
      

        <Column dataField={'customerReference'} visible={false} showInColumnChooser={true}>
           
         
        </Column>
       
        <Column dataField={'customerId'} caption="Customer Name">
            <RequiredRule message="Please select customer" />
            <Lookup dataSource={apis.CustomersDataSource(this.props.user.access_token)} displayExpr={'customerName'} valueExpr={'id'} />
          
        </Column>
        <Column dataField={'workOrderStatusId'} caption="Status">
            <Lookup dataSource={apis.WorkorderStatusDataSource(this.props.user.access_token)} displayExpr={'status'} valueExpr={'id'} />
     
        </Column>
        <Column dataField={'entityId'} caption="Entity" visible={false} showInColumnChooser={true}>
            <RequiredRule message="Please select entity" />
            <Lookup dataSource={apis.EntityDataSource(this.props.user.access_token)} displayExpr={'shortDescription'} valueExpr={'id'} />
   
        </Column>
        <Column dataField={'workOrderTypeId'} caption="Type" visible={false} showInColumnChooser={true}>
            <RequiredRule message="Please select workorder type" />
            <Lookup dataSource={apis.WorkorderTypeDataSource(this.props.user.access_token)} displayExpr={'code'} valueExpr={'id'} />
          
            
        </Column>
        <Column dataField={'releaseTypeId'} caption="Release" visible={false} showInColumnChooser={true}>
            <RequiredRule message="Please select release type" />
            <Lookup dataSource={apis.ReleaseTypeDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} />
       
        </Column>
        <Column dataField={'releaseReference'} caption="Release Ref" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
     
        <Column dataField={'warranty'} dataType="boolean" visible={false} showInColumnChooser={true} >

        </Column>
        <Column dataField={'quantity'} dataType="number" visible={false} showInColumnChooser={true}>
 
        </Column>
        <Column dataField={'partNumber'} >
            <RequiredRule message="Please enter a part number." />
 
          
        </Column>
        <Column dataField={'partDescription'} >

        </Column>
        <Column dataField={'workTypeId'} caption="RO Type" visible={false} showInColumnChooser={true}>
            <Lookup dataSource={apis.WorkTypeDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} />

        </Column>
        <Column dataField={'serialNo'} visible={false} showInColumnChooser={true}>
 
        </Column>
        <Column dataField={'dateReceived'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>

        </Column>
        <Column dataField={'dueDate'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>

        </Column>
        <Column dataField={'dispatchDate'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>

        </Column>
        <Column dataField={'techDataChecked'} caption="Tech Data Checked" dataType="boolean" visible={false} showInColumnChooser={true}>
            
         </Column>
         <Column dataField={'bookOnCheckCompleted'} caption="Pre Work Checked" dataType="boolean" visible={false} showInColumnChooser={true}>
            
         </Column>
         <Column dataField={'aog'} dataType="boolean">
 
        </Column>
         <Column dataField={'activeBookingCount'} dataType="number" caption="Booked Staff">
        
        </Column>
        <Column dataField={'openPartRequestCount'} dataType="number" caption="Part Requests" visible={false} showInColumnChooser={true} >
    
        </Column>
        <Column dataField={'fileCount'} dataType="number" caption="Files" visible={false} showInColumnChooser={true}>
        
        </Column>
        <Column dataField={'pendingQuoteCount'} dataType="number" caption="Pending Quotes" visible={false} showInColumnChooser={true}>
        
        </Column>
        <Column dataField={'openQueryCount'} dataType="number" caption="Open Queries" visible={false} showInColumnChooser={true}>
    
        </Column>
        <Column dataField={'allocatedTo'} dataType="string" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
         </Column>
        <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
          buttons={[ {
              text: 'book',
            hint: 'Book',
            icon: 'clock',
            visible: this.isBookIconVisible,
            onClick: this.bookOnHandler
          },
          {
            text: 'book off',
          hint: 'Book Off',
          visible: this.isBookOffIconVisible,
          icon: 'clearsquare',

          onClick: this.bookOffHandler
        },
        {
          text: 'Details',
          hint: 'Detail',
          visible: this.isBookOffIconVisible,
          icon: 'preferences',

          onClick: this.detailClicked
        }
        ]} />
  

        </DataGrid>
      
        <Popup title="Book onto Workorder" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} closeOnOutsideClick={true} >
          <BookToWorkorderPopup submitBookOnRequest={(id) => this.bookOnRequest(id)}></BookToWorkorderPopup>
        </Popup>
        <Popup title="General Activity Booking" ref={(popup) => {this.generalpopup = popup}} height={this.popupHeight} width={this.popupWidth} closeOnOutsideClick={true}>
          <BookToGeneralPopup submitBookOnRequest={(params,id) => this.booktonp(params,id)} submitBookOffRequest={() => this.bookOffNp()}></BookToGeneralPopup>
        </Popup>
        <Popup title="Book off Workorder" ref={(popup) => {this.bookoffpopup = popup}} height={this.popupHeight} width={this.popupWidth} closeOnOutsideClick={true}>
          <BookOffPopup submitBookOffRequest={(params,reasonId,woId,notes) => this.bookOff(params,reasonId,woId,notes)} submitSimpleBookOff={(woId) => this.simpleBookOff(woId)}></BookOffPopup>
        </Popup>
     
        <Popup ref={(popup) => {this.detailpopup = popup}} height={fullHeight} width={fullWidth} closeOnOutsideClick={true} onHiding={this.closeDetails}>
            <ProductionDetailsTab></ProductionDetailsTab>
        </Popup>
        <Popup title="Book off all orders" ref={(popup) => {this.bookoffallpopup = popup}} height={this.popupHeight} width={this.popupWidth} closeOnOutsideClick={true} >
            <BookOffAllPopup submitBookOffAllRequest={() => this.bookOffAllRequest()}>></BookOffAllPopup>
        </Popup>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
   
        user: state.oidc.user,
    };
  }
  
  const mapDispatchToProps = dispatch => {
    return {
         onSelectingWOToBook: (rowData) => dispatch(actions.setBooking(rowData)),
         onSelectingWOToInteract: (rowData) => dispatch(actions.setInteract(rowData)),
         onBookedOn: (rowData) => dispatch(actions.bookOnToWo(rowData)),
         onBookedToOther: () => dispatch(actions.bookToOther()),
         onBookedOffNp: () => dispatch(actions.bookOffOther()),
         onBookedOff: (id) => dispatch(actions.bookOffOrder(id)),
         onBookedOffAll: () => dispatch(actions.bookOffAll()),
         onBookedToMulti: (orders) => dispatch(actions.bookToMulti(orders))
    };
  }


export default connect(mapStateToProps,mapDispatchToProps)(ProductionQueueTab)