import React, {Component} from 'react';
import { ScrollView } from 'devextreme-react';
import ProductionTechData from '../ProductionTechData/ProductionTechData';

export const getHeight = () => {
    return (window.innerHeight - 185)
  }

class ProductionTechDataHolder extends Component {
    render() {
        return (
            <ScrollView height={getHeight()}>
               <ProductionTechData></ProductionTechData>
            </ScrollView>
        ) 
        
    }
}

export default ProductionTechDataHolder