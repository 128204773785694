import React, {Component} from 'react';
import { connect } from "react-redux";
import WODispatchRuleResults from '../WOSummary/WODispatchRuleResults/WODispatchRuleResults';
import {  Form, ScrollView } from 'devextreme-react';
import { SimpleItem, GroupItem, ButtonOptions } from 'devextreme-react/form';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { RequiredRule } from 'devextreme-react/data-grid';
import { notificationTiming } from '../../utils/appConfig';
import * as apis from '../../utils/apis';
class DispatchWorkorderPopup extends Component {


    state =  {
        id: null,

    }

    dispatchClicked = (e) => {

        var result = e.validationGroup.validate();
        if(result.isValid)
        {
            let button = this.form.instance.getEditor("dispatchButton")
            button.option("disabled",true);
        var submission = this.form.instance.option("formData");

       
        let data = {
            id: this.props.selectedWo.id,
           
        }
        data.address1 = submission.addressLine1;
        data.address2 = submission.addressLine2;
        data.address3 = submission.addressLine3;
        data.address4 = submission.addressLine4;
        data.shipMethod = submission.shipMethod;
        data.shipReference = submission.shipReference;
        data.postalZipCode = submission.postalZipCode;
        data.customerId = submission.customerId;
        data.dispatchNotes = submission.dispatchNotes;
        data.countryId = submission.countryId;
    
       
        axios.post('api/workorders/dispatchworkorder', data)
        .then(response => {  
            notify("Workorder dispatched successfully.","success", notificationTiming);
            this.props.dispatchedHandler();
        })
        .catch(error => {   
            notify(error.response.data,"error", notificationTiming);
        });
        
       }
    }

  
    componentDidUpdate() {
        if(this.props.selectedWo) {
        if(this.state.id !== this.props.selectedWo.id) {

            if(this.form !== null) {
                this.form.instance.updateData(
                    {
                        addressLine1: null,
                        addressLine2: null,
                        addressLine3: null,
                        addressLine4:null,
                        shippingMethod: null,
                        shippingReference: null,
                        dispatchNotes: null,
                        countryId: null,
                        postalZipCode: null
                    });

                    // disable submit button until loaded
                    let button = this.form.instance.getEditor("dispatchButton")
                    button.option("disabled",true);
                }


            axios.get('api/customeraddress/' + this.props.selectedWo.customerId)
            .then(response => {
                if(this.form !== null) {
                    // this.form.instance.updateData(
                    //     {
                    //         addressLine1: null,
                    //         addressLine2: null,
                    //         addressLine3: null,
                    //         addressLine4:null,
                    //         shippingMethod: null,
                    //         shippingReference: null,
                    //         dispatchNotes: null,
                    //         countryId: null,
                    //         postalZipCode: null
                    //     });
                
                    var defaultAddress = response.data.data.filter(obj => {
                        return obj.id === this.props.selectedWo.addressId
                    });
               
                    this.form.instance.updateData(defaultAddress[0]);
                    this.form.instance.updateData({
                        customerRef: this.props.selectedWo.customerReference,
                        woNo: this.props.selectedWo.woNumber,
                        partNumber: this.props.selectedWo.partNumber,
                        serialNo: this.props.selectedWo.serialNo,
                        releaseRef: this.props.selectedWo.releaseReference,
                        shipMethod: this.props.selectedWo.shippingMethod,
                        shipReference: this.props.selectedWo.shippingReference
                    })
                   // disable submit button until loaded
                   let button = this.form.instance.getEditor("dispatchButton")
                   button.option("disabled",false);
                    this.setState({ id:  this.props.selectedWo.id});
                }
               
            });
        }
    }
    }

    readOnly = {
        readOnly: true
    }

    loptions = {
        displayExpr: 'text',
        valueExpr: 'id',
        dataSource: apis.CountriesDataSource(this.props.user.access_token) 
    }

    tbOptions = {
        height: '100px',
    }
    
    formData = {
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
        postalZipCode: null,
        countryId: null,
        customerRef: null,
        woNo: null,
        partNumber: null,
        serialNo: null,
        releaseRef: null,
        shipMethod: null,
        shipReference: null,
        dispatchNotes: null

    }


    render() {
        
        let output = null;
       

        if(this.props.selectedWo) {

          
                this.formData.customerRef =  this.props.selectedWo.customerReference;
                this.formData.woNo= this.props.selectedWo.woNumber;
                this.formData.partNumber= this.props.selectedWo.partNumber;
                this.formData.serialNo= this.props.selectedWo.serialNo;
                this.formData.releaseRef= this.props.selectedWo.releaseReference;
                this.formData.shipMethod= this.props.selectedWo.shippingMethod;
                this.formData.shipReference= this.props.selectedWo.shippingReference;
           
            

            output = 
            <ScrollView>
                    <WODispatchRuleResults></WODispatchRuleResults>   
                    <h2>Dispatch To</h2>
                    <Form formData={this.formData} ref={(form) => {this.form = form}} >
                    <GroupItem colSpan={4} colCount={5} caption="Details">
                    <SimpleItem dataField="customerRef" editorOptions={this.readOnly}/>
                        <SimpleItem dataField="woNo" editorOptions={this.readOnly}/>
                        <SimpleItem dataField="partNumber" editorOptions={this.readOnly}/>
                        <SimpleItem dataField="serialNo" editorOptions={this.readOnly}/>
                        <SimpleItem dataField="releaseRef" editorOptions={this.readOnly}/>
                    </GroupItem>
                    <GroupItem colCount="4" colSpan="4" caption="Shipping Info">
                        <SimpleItem dataField="addressLine1">
                            <RequiredRule message="Address line 1 is required."/>
                        </SimpleItem>
                        <SimpleItem dataField="addressLine2">
                            <RequiredRule message="Address line 2 is required."/>
                        </SimpleItem>
                        <SimpleItem dataField="addressLine3" />
                        <SimpleItem dataField="addressLine4" />
                        <SimpleItem dataField="postalZipCode">
                            <RequiredRule message="Post/Zip code is required."/>
                        </SimpleItem>
                        <SimpleItem dataField="countryId" editorType="dxSelectBox" editorOptions={this.loptions} label={{text:"Country"} } >
                            <RequiredRule message="Country is required."/>
                        </SimpleItem>
                            
 
                    
                        <SimpleItem dataField="shipMethod">
                            <RequiredRule message="Shipping Method is required."/>
                        </SimpleItem>
                        <SimpleItem dataField="shipReference">
                          <RequiredRule message="Shipping Reference is required."/>
                        </SimpleItem>
                        <SimpleItem dataField="dispatchNotes" editorType="dxTextArea" editorOptions={this.tbOptions} colSpan='4' />
                    </GroupItem>
                  
                  
                        
                   
                    <GroupItem colSpan="4">
                        <SimpleItem itemType="empty"></SimpleItem>
                    <SimpleItem itemType="button" horizontalAlignment="right" visible="true" colSpan="1" name="dispatchButton">
                        <ButtonOptions 
                        type="success"
                        text="Dispatch Workorder"
                        useSubmitBehavior="false"
                        onClick={(params) => this.dispatchClicked(params)}></ButtonOptions>
                    </SimpleItem>
                    </GroupItem>
                
                    </Form>
            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        selectedWo: state.woDetail.rowData
    };
  }

export default connect(mapStateToProps,null)(DispatchWorkorderPopup)