import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import {withRouter} from 'react-router-dom';
import ReleaseWorkorderClaimPopup from '../ReleaseWorkorderClaimPopup/ReleaseWorkorderClaimPopup';
import CompleteWorkorderPopup from '../CompleteWorkorderPopup/CompleteWorkorderPopup';
import DispatchWorkorderPopup from '../DispatchWorkorderPopup/DispatchWorkorderPopup';
import QuarantineWorkorderPopup from '../QuarantineWorkorderPopup/QuarantineWorkorderPopup';
import {Popup} from 'devextreme-react';
import DataGrid, {
    Column,
    Editing,
    Position,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Form,
    Format,
    Export
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';
import {RangeRule} from 'devextreme-react/validator';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { notificationTiming } from '../../utils/appConfig';


const getHeight = () => {
    return (window.innerHeight - 48)
}

class WorkorderGrid extends Component {
    state = {
        gridHeight: 0,
    };

    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }


    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      contextClicked = (e) => {
          this.props.onSettingWorkorder(e.itemData.rowData);
          if(e.itemData.text === "Details")
          {
              if(this.props.admin === true) {
                this.props.history.push('/admin/workorderdetail/');
              }
              else {
                this.props.history.push('/enquiry/workorderdetail/');
              }
          
          }

          if(e.itemData.text === "Customer")
          {
              if(this.props.admin === true) {
                this.props.history.push('/admin/workorderdetail/customer');
              }
              else  {
                this.props.history.push('/enquiry/workorderdetail/customer');
              }
          }

          if(e.itemData.text === "Quotes")
          {
            if(this.props.admin === true) {
              this.props.history.push('/admin/workorderdetail/quotes');
            }
            else  {
                this.props.history.push('/enquiry/workorderdetail/quotes');
              }
          }

          if(e.itemData.text === "Tech Data")
          {
              if(this.props.admin === true) {
                this.props.history.push('/admin/workorderdetail/techdata');
              }
              else {
                this.props.history.push('/enquiry/workorderdetail/techdata');
              }

             
          }

          if(e.itemData.text === "Labour Log")
          {
              this.props.history.push('/admin/workorderdetail/labour');
          }

          if(e.itemData.text === "Files")
          { 
              if(this.props.admin === true) {
                    this.props.history.push('/admin/workorderdetail/files');
                }
                else {
                    this.props.history.push('/enquiry/workorderdetail/files');
                }
              
          }
          if(e.itemData.text === "Part Requests")
          {
              if(this.props.admin === true) {
                this.props.history.push('/admin/workorderdetail/parts');
              }
              else
              {
                this.props.history.push('/enquiry/workorderdetail/parts');
              }
              
          }
          if(e.itemData.text === "Queries")
          {
              if(this.props.admin === true) {
                this.props.history.push('/admin/workorderdetail/queries');
              }
              else
              {
                this.props.history.push('/enquiry/workorderdetail/queries');
              }
          }

          if(e.itemData.text === "Notes")
          {
              if(this.props.admin === true) {
              this.props.history.push('/admin/workorderdetail/notes');
              }
              else {
                  this.props.history.push('/enquiry/workorderdetail/notes');
              }

          }

          if(e.itemData.text === "Clone")
          {
            let data = {
                Id: e.itemData.rowData.id,
            }
        
            if(this.props.grants.rights.cloneWorkorder === true) {
                axios.post('api/workorders/cloneworkorder', data)
                .then(response => {  
                    notify("Order cloned successfully.","success", notificationTiming);
                    this.gridview.instance.refresh();
                })
                .catch(error => {
                    console.log(error);
                    notify(error.response.data,"error", notificationTiming);
                });

          }

         }
    }

      contextMenu = (e) => {
        if (e.row.rowType === "data") {
            var rowData = e.row.data;
            e.items = [{
                text: "Details",
                onItemClick: this.contextClicked,
                rowData: rowData
                }, 
                {  
                text: "Customer",
                onItemClick: this.contextClicked,
                rowData: rowData
                },
                {  
                    text: "Quotes",
                    onItemClick: this.contextClicked,
                    rowData: rowData
                },
                {  
                    text: "Tech Data",
                    onItemClick: this.contextClicked,
                    rowData: rowData
                },
                {  
                    text: "Labour Log",
                    onItemClick: this.contextClicked,
                    rowData: rowData
                },
                {  
                    text: "Files",
                    onItemClick: this.contextClicked,
                    rowData: rowData
                },
                {  
                    text: "Part Requests",
                    onItemClick: this.contextClicked,
                    rowData: rowData
                },
                {  
                    text: "Queries",
                    onItemClick: this.contextClicked,
                    rowData: rowData
                },
                {  
                    text: "Notes",
                    onItemClick: this.contextClicked,
                    rowData: rowData
                },
                {  
                    text: "Clone",
                    onItemClick: this.contextClicked,
                    rowData: rowData,
                    visible: this.props.grants.rights.cloneWorkorder === true
                },
            ];
        }
    }

    setDefaultNewValues = (e) => {
        e.data.aog = false;
        e.data.warranty = false;
        e.data.quantity = 1;
      }

      onEditorPreparing = (e) =>  {
         if (e.dataField === "partNumber") {
            e.editorName = "dxAutocomplete";
         //   e.editorOptions = { dataSource : ["Manc","Live","London"], placeholder: 'Start typing'}
      } 
      if(e.dataField === "quantity") {
          e.editorName = "dxNumberBox";
      }
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh();
    }

    releaseClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSettingWorkorder(e.row.data);
        this.releaseWOClaimPopup.instance.show();
      }

      quarantineClickedHandler = (e) => {
          e.event.preventDefault();
          this.props.onSettingWorkorder(e.row.data);
          this.quarantineWOpopup.instance.show();
      }

      dispatchClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSettingWorkorder(e.row.data);
        this.dispatchWOpopup.instance.show();
    }

    closeClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSettingWorkorder(e.row.data);
        this.completeWOpopup.instance.show();
    }

    WOClaimReleased = () => {
        this.releaseWOClaimPopup.instance.hide();
        this.gridview.instance.refresh();
      }

    quarantinedWO = () => {
        this.quarantineWOpopup.instance.hide();
        this.gridview.instance.refresh();
    }

    closedWO = () => {
        this.completeWOpopup.instance.hide();
        this.gridview.instance.refresh();
    }

    dispatchedWO = () => {
        this.dispatchWOpopup.instance.hide();
        this.gridview.instance.refresh();
    }
    
    validToRelease = (e) => {    
        return !e.row.isEditing && (e.row.data.claimedById !== null) 
    }

    validToClose = (e) => {
        return !e.row.isEditing && (e.row.data.complete !== true && e.row.data.locked !== true && e.row.data.openQueryCount === 0 && e.row.data.openPartRequestCount === 0) && this.props.grants.rights.closeWO === true
    }

    validToDispatch = (e) => {
        return !e.row.isEditing && (e.row.data.complete !== true && e.row.data.locked !== true && e.row.data.openQueryCount === 0 && e.row.data.openPartRequestCount === 0) && this.props.grants.rights.dispatchWO === true
    }

    validToQuarantine = (e) => {
        return !e.row.isEditing && (e.row.data.complete !== true && e.row.data.locked !== true) && this.props.grants.rights.quarantine === true
    }

    onCellPrepared = (e) => {
        if(e.rowType === "data" && e.column.dataField === "woNumber") {
            if(e.data.hoursRequired === 0)
            {
                return;
            }

            if(e.data.actual === 0) {
                e.cellElement.style.color = "darkorange";
                return;
            }
            else {
                e.cellElement.style.color = e.data.diff > 0 ? "mediumseagreen" : "red";
            }

        }
    }


    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.WorkOrderGridDataSource(this.props.user.access_token)}  
            noDataText="There are no matching workorders in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'woGrid' + this.props.admin }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}}
            onInitNewRow={this.setDefaultNewValues} onContextMenuPreparing={this.contextMenu} onEditorPreparing={this.onEditorPreparing}
            onToolbarPreparing={this.onToolbarPreparing} onCellPrepared={this.onCellPrepared}
            >
      <Export enabled={this.props.grants.rights.exportData} fileName="workorders"/>
        <Editing
            mode={'popup'}
            allowAdding={this.props.grants.rights.addWorkorder}>
            <Form colCount="4">      
            
            </Form>
             <Popup title={'Create Workorder'} showTitle={true} width={this.popupWidth()} height={this.popupHeight()}>
             <Position my={'center'} at={'center'} of={window} />
            </Popup>
            
        </Editing> 
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
       

        
        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'woNumber'} caption="W/O#">
            <FormItem visible={false} />
        </Column>
      

        <Column dataField={'customerReference'} visible={false} showInColumnChooser={true}>
            <StringLengthRule max="512" message="Customer reference cannot exceed 512 characters"/>
            <FormItem visibleIndex="1"></FormItem>
        </Column>
        <Column dataField={'workOrderStatusId'} caption="Status">
            <Lookup dataSource={apis.WorkorderStatusDataSource(this.props.user.access_token)} displayExpr={'status'} valueExpr={'id'} />
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'customerId'} caption="Customer Name">
            <RequiredRule message="Please select customer" />
            <Lookup dataSource={apis.CustomersDataSource(this.props.user.access_token)} displayExpr={'customerName'} valueExpr={'id'} />
            <FormItem visibleIndex="0"></FormItem>
        </Column>
        
        <Column dataField={'entityId'} caption="Entity" visible={false} showInColumnChooser={true}>
            <RequiredRule message="Please select entity" />
            <Lookup dataSource={apis.EntityDataSource(this.props.user.access_token)} displayExpr={'shortDescription'} valueExpr={'id'} />
            <FormItem visibleIndex="2"></FormItem>
        </Column>
        <Column dataField={'workOrderTypeId'} caption="Type" visible={false} showInColumnChooser={true}>
            <RequiredRule message="Please select workorder type" />
            <Lookup dataSource={apis.WorkorderTypeDataSource(this.props.user.access_token)} displayExpr={'code'} valueExpr={'id'} />
            <FormItem visibleIndex="3"></FormItem>
            
        </Column>
        <Column dataField={'releaseTypeId'} caption="Release" visible={false} showInColumnChooser={true}>
            <RequiredRule message="Please select release type" />
            <Lookup dataSource={apis.ReleaseTypeDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} />
            <FormItem visibleIndex="8"></FormItem>
        </Column>
        <Column dataField={'boxId'} caption="Box ID" visible={false} showInColumnChooser={true}>
            <FormItem visibleIndex="9"></FormItem>
            <StringLengthRule max="100" message="Box Id cannot exceed 100 characters." />
        </Column>
        <Column dataField={'releaseReference'} caption="Release Ref" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'aog'} dataType="boolean" visible={false} showInColumnChooser={true}>
        <FormItem visibleIndex="10"></FormItem>
        </Column>
        <Column dataField={'warranty'} dataType="boolean" visible={false} showInColumnChooser={true}>
        <FormItem visibleIndex="11"></FormItem>
        </Column>
        <Column dataField={'quantity'} dataType="number" visible={false} showInColumnChooser={true}>
            <FormItem visibleIndex="7" editorOptions={{ showSpinButtons: true, min: 0 }} ></FormItem>
            <RequiredRule message="Please enter quantity." />
            <RangeRule min='1' message="Quantity must be at least 1."></RangeRule>
        </Column>
        <Column dataField={'partNumber'} visible={false} showInColumnChooser={true}>
            <RequiredRule message="Please enter a part number." />
            <StringLengthRule max="32" message="Part number cannot exceed 50 characters"/>
            <FormItem visibleIndex="4" editorOptions = {{ dataSource : apis.PartLookupDataSource(this.props.user.access_token), placeholder: 'Start typing', searchMode: 'startswith', displayExpr :'partNumber', valueExpr: 'partNumber', searchExpr: 'partNumber'}}></FormItem>
        </Column>
        <Column dataField={'partDescription'} visible={false} showInColumnChooser={true}>
        <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'workTypeId'} caption="RO Type" visible={false} showInColumnChooser={true}>
            <Lookup dataSource={apis.WorkTypeDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} />
            <FormItem visibleIndex="5"></FormItem>
        </Column>
        <Column dataField={'serialNo'} visible={false} showInColumnChooser={true}>
        <StringLengthRule max="50" message="Serial number cannot exceed 50 characters" />
        <FormItem visibleIndex="6"></FormItem>
        </Column>
        <Column dataField={'dateReceived'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'dueDate'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'dispatchDate'} dataType="date" format="dd/MM/y" caption="Close Date" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
   
        <Column dataField={'openPartRequestCount'} dataType="number" caption="Part Requests">
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'fileCount'} dataType="number" caption="Files" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'pendingQuoteCount'} dataType="number" caption="Pending Quotes">
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'activeBookingCount'} dataType="number" caption="Booked Staff" >
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'openQueryCount'} dataType="number" caption="Open Queries">
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'claimedBy'} dataType="string" >
            <FormItem visible={false}></FormItem>
         </Column>
         <Column dataField={'techDataChecked'} caption="Tech Data Checked" dataType="boolean" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
         </Column>
         <Column dataField={'bookOnCheckCompleted'} caption="Pre Work Checked" dataType="boolean" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
         </Column>
         <Column dataField={'allocatedTo'} dataType="string" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
         </Column>
            <Column dataField={'actual'} dataType="number" caption="Elapsed" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'diff'} dataType="number" caption="Diff" visible={false} showInColumnChooser={true} >
            <FormItem visible={false}></FormItem>
            <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
         <Column dataField={'acceptedPrice'} dataType="number" caption="Accepted Price" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
            <Format
              type="fixedPoint"
              precision={2}
          />
         </Column>
         <Column dataField={'currency'} caption="Currency" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
         </Column>
         <Column dataField={'hoursRequired'} caption="Hours Reqd" dataType="number" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
         </Column>
         <Column dataField={'invoiceHours'} dataType="number" visible={false} caption="Invoiced Hours" showInColumnChooser={this.props.grants.rights.restrictedOrderData === true}  >
            <FormItem visible={false}></FormItem>
         </Column>
         <Column type={'buttons'} width={150} allowReordering={false} showInColumnChooser={false} visible={this.props.admin}
        buttons={[ 
        {
          hint: 'Release Claim',
          icon: 'clearsquare',
          onClick: this.releaseClickedHandler,
          visible: this.validToRelease
        },
        {
            hint: 'Quarantine',
            icon: 'warning',
            onClick: this.quarantineClickedHandler,
            visible: this.validToQuarantine
        },
        {
            hint: 'Close/Complete',
            icon: 'close',
            onClick: this.closeClickedHandler,
            visible: this.validToClose
        },
        {
            hint: 'Dispatch',
            icon: 'airplane',
            onClick: this.dispatchClickedHandler,
            visible: this.validToDispatch
        }]} />

        </DataGrid>
         <Popup title="Release Workorder Claim" ref={(popup) => {this.releaseWOClaimPopup = popup}} height={this.popupHeight} width={this.popupWidth} >
           <ReleaseWorkorderClaimPopup WOClaimReleasedHandler={this.WOClaimReleased} adminMode={true}></ReleaseWorkorderClaimPopup> 
         </Popup>
         <Popup title="Mark Workorder as Complete" ref={(popup) => {this.completeWOpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
            <CompleteWorkorderPopup closedHandler={this.closedWO}>

            </CompleteWorkorderPopup>
         </Popup>
         <Popup title="Dispatch Workorder" ref={(popup) => {this.dispatchWOpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
            <DispatchWorkorderPopup dispatchedHandler={this.dispatchedWO}>

            </DispatchWorkorderPopup>
         </Popup>
         <Popup title="Quarantine Workorder" ref={(popup) => {this.quarantineWOpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
            <QuarantineWorkorderPopup quarantinedHandler={this.quarantinedWO}>

            </QuarantineWorkorderPopup>
         </Popup>
         
         </div>
        );
    }


}


const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        grants: state.rights
    };
  }

const mapDispatchToProps = dispatch => {
    return {
        onSettingWorkorder: (rowData) => dispatch(actions.setWoDetail(rowData)),
    };
  }


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkorderGrid))