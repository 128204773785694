import React, {Component} from 'react';
import { ScrollView } from 'devextreme-react';
import LabourForm from './LabourForm/LabourForm';
import * as utils from '../common';

class LabourTab extends Component {
    render() {
        return (
            <ScrollView height={utils.getHeight()}>
               <LabourForm></LabourForm>
            </ScrollView>
        ) 
        
    }
}



export default LabourTab

