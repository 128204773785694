import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import ReleasePartRequestClaimPopup from '../ReleasePartRequestClaimPopup/ReleasePartRequestClaimPopup';
import {Popup,} from 'devextreme-react';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { confirm} from 'devextreme/ui/dialog';
import {notificationTiming} from '../../utils/appConfig';
import DataGrid, {
    Column,
    Editing,
    Position,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Form,
    Export
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';
import PartAllocationPopup from '../PartAllocationPopup/PartAllocationPopup';
import PartCostingPopup from '../PartCostingPopup/PartCostingPopup';
import EditCostingPopup from '../EditCostingPopup/EditCostingPopup';
import OrderPartPopup from '../OrderPartPopup/OrderPartPopup';
import PartRequestDetailsPopup from '../PartRequestEnquiry/PartRequestDetailPopup/PartRequestDetailPopup';
import CancelPartRequestPopup from '../CancelPartRequestPopup/CancelPartRequestPopup';

const getHeight = () => {
    return (window.innerHeight - 48)
}

class PartRequestGrid extends Component {
    state = {
    };

    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      onEditorPreparing = (e) =>  {

        if (e.parentType === 'dataRow') {
            
            if (e.dataField === "notes")
            e.editorName = "dxTextArea";
        
            if(e.dataField === 'qty') {
                e.editorName = "dxNumberBox";
                // e.editorOptions = { showSpinButtons: true, showClearButton: true, min: 1 }
                // e.editorOptions.onValueChanged = (event) => {
                //     var value = event.value;
                //     e.setValue(value); 
                // }
            }
        }        
    }

    costingClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.costingpopup.instance.show();
      }

      editCostingClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.editcostingpopup.instance.show();
      }

    releaseClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.releaseClaimPopup.instance.show();
      }

      claimReleased = () => {
        this.releaseClaimPopup.instance.hide();
        this.gridview.instance.refresh();
      }

    validToRelease = (e) => { 
        return !e.row.isEditing && (e.row.data.claimedById !== null) 
      }

      editCostingPossible = (e) => {
        return !e.row.isEditing && (e.row.data.costingComplete === true && this.props.grants.rights.editCostingNotes === true) 
      }
     

      validToAllocate = (e) => {
        return !e.row.isEditing && (e.row.data.allocated !== true && this.props.grants.rights.allocateParts === true && (!e.row.data.dateClosed) );
      }

      validToDeAllocate = (e) => {
        return !e.row.isEditing && (e.row.data.allocated === true && this.props.grants.rights.deallocateParts === true && e.row.data.dateClosed === null);
      }

      validToOrder = (e) => {
        return !e.row.isEditing && (e.row.data.allocated !== true && e.row.data.ordered !== true && this.props.grants.rights.orderParts === true && (!e.row.data.dateClosed) );
      }
      costingPossible = (e) => {
        
        return !e.row.isEditing && (e.row.data.costingComplete !== true && this.props.grants.rights.costParts === true && (!e.row.data.dateClosed) )
      }

      allocateClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.allocationpopup.instance.show();
      }

      deAllocateClickedHandler = (e) => {
        e.event.preventDefault();
    //    this.props.onSelectingPartRequest(e.row.data);
        let result = confirm("<i>" + e.row.data.requestIdentifier + "</i>", "Deallocate");
        result.then((dialogResult) => {
            if(dialogResult === true) {

                let data = {
                    id: e.row.data.id,
                  
                }
                axios.post('api/partworkflow/deallocate', data)
                .then(response => {
                   
                    notify("Part request successfully deallocated","success", notificationTiming);
                    this.gridview.instance.refresh();
   
        
                })
                .catch(error => {
                  notify(error.response.data,"error", notificationTiming);
        
              
                });
            }
            
        });
      }

      
      orderClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.orderPopup.instance.show();
      }

      submitAllocation = (params,id,grn,qtyAllocated,accountsRef) => {
        var result = params.validationGroup.validate();
        if(result.isValid)
        {
            let data = {
                id: id,
                grn: grn,
                qtyAllocated: qtyAllocated,
                accountsRef: accountsRef
            }
 
            axios.post('api/partworkflow/allocate', data)
            .then(response => {
               
                notify("Allocated Parts successfully","success", notificationTiming);
                this.gridview.instance.refresh();
                this.allocationpopup.instance.hide();
    
            })
            .catch(error => {
              notify(error.response.data,"error", notificationTiming);
    
          
            });
        }
    }

    submitOrder = (params,id,poRef,dueDate) => {
        var result = params.validationGroup.validate();
        if(result.isValid)
        {
            let data = {
                id: id,
                poRef: poRef,
                dueDate: dueDate
            }
  
            axios.post('api/partworkflow/order', data)
            .then(response => {
               
                notify("Part Order Recorded Successfully","success", notificationTiming);
                 this.gridview.instance.refresh();
  //               this.props.onBookedOn(null);
                this.orderPopup.instance.hide();
    
            })
            .catch(error => {
              notify(error.response.data,"error", notificationTiming);
              
          
            });
        }
    }

    infoClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.popup.instance.show();
      }

      editCosting = (params,id,notes,accountsRef) => {
        var result = params.validationGroup.validate();
        if(result.isValid)
        {
            let data = {
                id: id,
                additionalNotes: notes,
                accountsRef: accountsRef
            }
    
            axios.post('api/partworkflow/editcost', data)
            .then(response => {
               
                notify("Part Costed Successfully","success", notificationTiming);
                this.gridview.instance.refresh();
                this.editcostingpopup.instance.hide();
    
            })
            .catch(error => {
              notify(error.response.data,"error", notificationTiming);
              
          
            });
        }
    }

    submitCosting = (params,id,notes,accountsRef) => {
        var result = params.validationGroup.validate();
        if(result.isValid)
        {
            let data = {
                id: id,
                additionalNotes: notes,
                accountsRef: accountsRef
            }
    
            axios.post('api/partworkflow/cost', data)
            .then(response => {
               
                notify("Part Costed Successfully","success", notificationTiming);
                this.gridview.instance.refresh();
                this.costingpopup.instance.hide();
    
            })
            .catch(error => {
              notify(error.response.data,"error", notificationTiming);
              
          
            });
        }
    }

    submitCostingInProgress = (params,id,notes,accountsRef) => {
      var result = params.validationGroup.validate();
      if(result.isValid)
      {
          let data = {
              id: id,
              additionalNotes: notes,
              accountsRef: accountsRef
          }
  
          axios.post('api/partworkflow/partialcost', data)
          .then(response => {
             
              notify("Updated Part Costing Successfully","success", notificationTiming);
              this.gridview.instance.refresh();
              this.costingpopup.instance.hide();
  
          })
          .catch(error => {
            notify(error.response.data,"error", notificationTiming);
            
        
          });
      }
  }
    
    cancelClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.cancelpopup.instance.show();
      }
  
    isCancelIconVisible = (e) => {
          return !e.row.isEditing && (this.props.grants.rights.cancelPartRequest) && (!e.row.data.dateClosed) && (!e.row.data.allocated);  
       // return !e.row.isEditing && (this.props.admin) && (e.row.data.isComplete === 1);
    }

    cancelPartRequest = (params,id,reason) => {
        var result = params.validationGroup.validate();
        if(result.isValid) {
          let data = {
            id: id,
            reason: reason
          }
    
          axios.post('api/workorderparts/cancelpartrequest/' + 0 , data)
          .then(response => {
             
              notify("Part request cancelled successfully.","success", notificationTiming);
              this.gridview.instance.refresh();
              this.cancelpopup.instance.hide();
    
          })
          .catch(error => {
            notify(error.response.data,"error", notificationTiming);
        
          });
        }
      }

      quotedClickedHandler = (e) => {
        e.event.preventDefault();
  
        let result = confirm("<i>" + e.row.data.requestIdentifier + "</i>", "Quoted?");
        result.then((dialogResult) => {
            if(dialogResult === true) {
  
                let data = {
                    id: e.row.data.id,
                  
                }
                axios.post('api/partworkflow/quoteparts', data)
                .then(response => {
                   
                    notify("Part request quoted successfully.","success", notificationTiming);
                    this.gridview.instance.refresh();
   
        
                })
                .catch(error => {
                  notify(error.response.data,"error", notificationTiming);
        
              
                });
            }
            
        });
  
      }
  
      validToQuote = (e) => {
        return !e.row.isEditing && (e.row.data.quoted === false && this.props.grants.rights.quoteParts === true);
      }

    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.PartRequestDataSource(this.props.user.access_token)}  
            noDataText="There are no matching part requests in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'allpartrequests' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} onEditorPreparing={this.onEditorPreparing} onToolbarPreparing={this.onToolbarPreparing}
         >
         <Export enabled={this.props.grants.rights.exportData} fileName="partrequests" />
         <Editing
            mode={'popup'}
            allowUpdating={true}
            allowDeleting={true}            
         >
         <Form colCount="5"></Form>
             <Popup title={'Part Request Administrative Edit'} showTitle={true} width={this.popupWidth()} height={this.popupHeight()}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
        </Editing>
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        <Column dataField={'requestIdentifier'} >
            <FormItem visible={false} />
        </Column>
        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
       
       
        <Column dataField={'partNumber'}>
            <FormItem visible={true} />
            <RequiredRule />
            <StringLengthRule max='32' message='The part number cannot exceed 32 characters.' />
        </Column>
        <Column dataField={'description'}  >
            <FormItem visible={true} />
            <RequiredRule />
            <StringLengthRule max ='100' message='The part description cannot exceed 100 characters.' />
        </Column>
        <Column dataField={'figure'} visible={false} showInColumnChooser={true}>
            <StringLengthRule max='100' message='The figure text cannot exceed 100 characters.' />
            <FormItem visible={true} />
        </Column>
        <Column dataField={'iplNo'} visible={false} showInColumnChooser={true}>
            <StringLengthRule max='100' message='The figure text cannot exceed 100 characters.' />
            <FormItem visible={true} />
        </Column>
        

        <Column dataField={'partRequestPriorityId'} caption="Priority" >
        <RequiredRule message="Please select priority" />
            <Lookup dataSource={apis.PRPriorityDataSource(this.props.user.access_token)} displayExpr={'priority'} valueExpr={'id'} />
        </Column>

        

        <Column dataField={'grn'} caption ="GRN" visible={false} showInColumnChooser={true}>
            <FormItem visible={true} />
        </Column>

        <Column dataField={'PORef'} visible={false} showInColumnChooser={true}>
            <StringLengthRule max='100' message='The PO Ref text cannot exceed 100 characters.' />
            <FormItem visible={true} />
        </Column>

        <Column dataField={'accountsRef'} caption="Sage Ref" visible={false} showInColumnChooser={true}>
            <FormItem visible={true} />
        </Column>

        <Column dataField={'dueDate'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
            <FormItem visible={true} />
        </Column>

       

        <Column dataField={'qty'} visible={false} showInColumnChooser={true}>
            <FormItem visible={true} colSpan='1' editorOptions = {{ showSpinButtons: true, showClearButton: true, min: 1 }} />
        </Column>
        
        <Column dataField={'partRequestStatusId'} caption="Status" >
            <FormItem visible={true} colSpan="5"/>
            <Lookup dataSource={apis.PartRequestStatusesDataSource(this.props.user.access_token)} displayExpr={'text'} valueExpr={'id'} />
        </Column>
        <Column dataField={'notes'} caption='Note' visible={false} showInColumnChooser={false}>
            <FormItem editorOptions={{height: "150px"}}  visible="true" colSpan='5'></FormItem>
            <StringLengthRule max="2048" message="A Note cannot exceed 2048 characters."/>
        </Column>
        <Column dataField={'requestedBy'} visible={false} showInColumnChooser={true}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'claimedBy'} dataType="string" visible={false} showInColumnChooser={true}>
            <FormItem visible={false} />
         </Column>
         <Column dataField={'costingComplete'} caption="Costed" dataType="boolean" >
         <FormItem visible={false} />
        </Column>
        <Column dataField={'costing'} caption="Costing" dataType="boolean" visible={false}>
         <FormItem visible={false} />
        </Column>
        <Column dataField={'quoted'} caption="Quoted" dataType="boolean" visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'ordered'} caption="Ordered" dataType="boolean" >
            <FormItem visible={false} />
        </Column>
    
        <Column dataField={'allocated'} caption="Allocated" dataType="boolean" >
            <FormItem visible={false} />
        </Column>
        <Column dataField={'used'} caption="Used" dataType="boolean" >
            <FormItem visible={false} />
        </Column>
        <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
          buttons={[ 'delete' ,
          {
            hint: 'Details',
            icon: 'info',
            onClick: this.infoClickedHandler
          },
          {
            hint: 'Release Claim',
            icon: 'clearsquare',
            onClick: this.releaseClickedHandler,
            visible: this.validToRelease
          },
          {
            hint: 'Costing',
            icon: 'money',
            onClick: this.costingClickedHandler,
            visible: this.costingPossible
          },
          {
            hint: 'Costing Notes',
            icon: 'edit',
            onClick: this.editCostingClickedHandler,
            visible: this.editCostingPossible
          },
          {
            hint: 'Allocate',
            icon: 'toolbox',
            onClick: this.allocateClickedHandler,
            visible: this.validToAllocate
          },
          {
            hint: 'De-allocate',
            icon: 'undo',
            onClick: this.deAllocateClickedHandler,
            visible: this.validToDeAllocate
          },
          {
            hint: 'Order',
            icon: 'cart',
            onClick: this.orderClickedHandler,
            visible: this.validToOrder
          },
          {
            hint: 'Quoted',
            icon: 'exportselected',
            onClick: this.quotedClickedHandler,
            visible: this.validToQuote
          },
          {
            hint: 'Cancel',
            icon: 'clear',
            onClick: this.cancelClickedHandler,
            visible: this.isCancelIconVisible,
          }]} />
       

      
        </DataGrid>
        <Popup title="Part request details" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <PartRequestDetailsPopup></PartRequestDetailsPopup>
            </Popup>
       <Popup title="Release Claim" ref={(popup) => {this.releaseClaimPopup = popup}} height={this.popupHeight} width={this.popupWidth} >
         <ReleasePartRequestClaimPopup claimReleasedHandler={this.claimReleased} adminMode={true}></ReleasePartRequestClaimPopup> 
       </Popup>
       <Popup title="Part costing" ref={(popup) => {this.costingpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
         <PartCostingPopup submitCosting={(params,id,notes,accountsRef) => this.submitCosting(params,id, notes,accountsRef)} submitCostingInProgress={(params,id,notes,accountsRef) => this.submitCostingInProgress(params,id, notes,accountsRef)}></PartCostingPopup>
        </Popup>
        <Popup title="Edit costing" ref={(popup) => {this.editcostingpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
          <EditCostingPopup editCosting={(params,id,notes,accountsRef) => this.editCosting(params,id, notes, accountsRef)} ></EditCostingPopup>
        </Popup>
        <Popup title="Part allocation details" ref={(popup) => {this.allocationpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
          <PartAllocationPopup submitAllocation={(params,id,grn,qtyAllocated,accountsRef) => this.submitAllocation(params,id,grn,qtyAllocated,accountsRef)}></PartAllocationPopup>
        </Popup>   
        <Popup title="Part Order Details" ref={(popup) => {this.orderPopup = popup}} height={this.popupHeight} width={this.popupWidth} >
          <OrderPartPopup submitOrder={(params,id,poRef, dueDate) => this.submitOrder(params,id, poRef, dueDate)}></OrderPartPopup> 
        </Popup>
        <Popup title="Cancel Part Request" ref={(popup) => {this.cancelpopup = popup}} height={this.popupHeight} width={this.popupWidth}  >
          <CancelPartRequestPopup cancelPartRequest={(params,id,reason) => this.cancelPartRequest(params,id,reason)}></CancelPartRequestPopup>
        </Popup>
       </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        grants: state.rights
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
         onSelectingPartRequest: (rowData) => dispatch(actions.setPRDetail(rowData)),
       
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(PartRequestGrid) 