import React, {Component, Fragment} from 'react';
import { Form, LoadIndicator  } from 'devextreme-react';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { connect } from "react-redux";
import { notificationTiming } from '../../../../utils/appConfig';

class CustomerForm extends Component {

    state =  {
        loading: true,
        error: false, 
       
        deliveryAddresses : [
            
        ],
        contacts:  [
           
        ]
    }

    componentDidMount() {

        axios.get('api/customercontacts/' + this.props.selectedWo.customerId)
        .then(response => {
            this.setState({contacts: response.data.data});
        });

        axios.get('api/customeraddress/' + this.props.selectedWo.customerId)
        .then(response => {
            this.setState({deliveryAddresses: response.data.data})
        })


        axios.get('api/customerheader/' + this.props.selectedWo.id)
        .then(response => {
           this.customerFormData = response.data;
            this.setState({ loading: false });
        });
       
    } 

  
    submitUpdateRequest = (params) => {
      
        var result = params.validationGroup.validate();

        if(result.isValid) {     
            axios.post('api/customerheader/' + this.props.selectedWo.id, this.customerFormData)
            .then(response => {
                notify("Customer Header Updated","success", notificationTiming);   
            })
            .catch(error => {
                notify("Customer Header NOT Updated","warning", notificationTiming); 
                console.log(error);
          
            });
        }
    }

    contactChanged = (e) => {
        console.log(e.selectedItem);
        if(e.selectedItem.id !== this.customerFormData.customerContactId) {
           this.customerForm.instance.getEditor('customerPhone').option('value',e.selectedItem.phone);
           this.customerForm.instance.getEditor('customerEmail').option('value',e.selectedItem.email);    
       } 
    }

    customerFormData = {
        customerId: null,
        customerRequests: null,
        deliveryAddressId: null,
        shippingMethod: null,
        shippingReference: null,
        customerContactId: null,
        customerEmail: null,
        customerPhone: null,
    }

    render() {

        let customerFormConfig = {
            validationGroup: 'cust',
            items: [
                {
                    itemType: "group",
                    colCount: 2,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: "customerRequests",
                            colSpan: 2,
                            editorType: "dxTextArea",
                           
                            editorOptions: {
                                height: "100px",
                                readOnly: !this.props.grants.rights.editCustomerTab
                        
                            },
                            label : { text: "Customer Requests"},
                            validationRules: [{
                                type: "stringLength",
                                max: 1024,
                                message: "Customer requests cannot be longer than 1024 characters."
                            }]
                            
                        },
                       
                        {
                            dataField: 'customerContactId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                           
                            editorOptions: {
                                displayExpr: 'name',
                                valueExpr: 'id',
                                dataSource: this.state.contacts,
                                onSelectionChanged: this.contactChanged,
                                readOnly: !this.props.grants.rights.editCustomerTab
                            },
                            label : { 
                                text: "Customer Contact"},
                                validationRules: [{
                                type: "required",
                                message: "Contact is Required."
                            },
                            ]
                        },
                        {
                            dataField: 'deliveryAddressId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'fullAddress',
                                valueExpr: 'id',
                                dataSource: this.state.deliveryAddresses,
                                readOnly: !this.props.grants.rights.editCustomerTab
                            },
                            label : { 
                                text: "Delivery Address"},
                                validationRules: [{
                                type: "required",
                                message: "Delivery address is Required."
                            },
                            ]
                        },
                       
                        {
                            dataField: 'customerPhone',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                disabled: true
                            },
                            label : { 
                                text: "Phone"
                            },
                                
                        },
                        {
                            dataField: 'shippingMethod',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: 
                            {
                                readOnly: !this.props.grants.rights.editCustomerTab,
                            },
                            label : { 
                                text: "Shipping Method"
                            },
                                
                        },
                        {
                            dataField: 'customerEmail',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                disabled: true
                            },
                            label : { 
                                text: "Email"
                            },
                                
                        },
                        {
                           
                            dataField: 'shippingReference',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: 
                            {
                                readOnly: !this.props.grants.rights.editCustomerTab
                            },
                            
                            label : { 
                                text: "Shipping Reference"
                            },
                                
                        },
                       
                        
                        
                    ]
                },

                {
                    itemType: "button",
                    visible: this.props.grants.rights.editCustomerTab === true && !(this.props.selectedWo.locked === true || this.props.selectedWo.complete === true),
                    horizontalAlignment: "right",
                    colSpan: 3,
                    validationGroup: 'cust',
                    buttonOptions: {
                        text: "Update",
                        type: "success",
                        useSubmitBehavior: false,
                        onClick: this.submitUpdateRequest
                    }
                },
            ]
        }


        return (
            <Fragment>
             <Form visible={!this.state.loading} height="100%"
             colCount={3}
             formData={this.customerFormData}  
             items={customerFormConfig.items}
             ref={(form) => {this.customerForm = form}}>
            </Form> 
             <LoadIndicator visible={this.state.loading} />
           </Fragment>
             
         );
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      grants: state.rights,
      selectedWo: state.woDetail.rowData
    };
  }


export default connect(mapStateToProps)(CustomerForm)