import React, {Component} from 'react'
import {  Form,ScrollView } from 'devextreme-react';
import { connect } from "react-redux";

class BookOffAllPopup extends Component {
   

    render() {
      
        let offformConfignon = {
         
            items: [
                {
                    itemType: "group",               
                    colCount: 1,
                    items: [{
                        itemType: "simple",
                        template: "<div><p class='dx-theme-material-typography'>You are not booked to any orders.</p></div>"
                    },]
                    
                },
               
            ]
            
            
        };

        let offformConfig = {
         
            items: [
                {
                    itemType: "group",               
                    colCount: 1,
                    items: [{
                        itemType: "simple",
                        template: "<div><p class='dx-theme-material-typography'>Please note booking off all work orders will not change their status.</p></div>"
                    },]
                    
                },
               {
                itemType: "button",
                visible: true,
                horizontalAlignment: "right",
                colSpan: 1,
                buttonOptions: {
                    text: "Book Off ALL W/O",
                    type: "default",
                    useSubmitBehavior: false,
                    onClick: () => this.props.submitBookOffAllRequest() 
                }
            }
            
            ]
        }

       

       let output = <ScrollView>
               <Form items={offformConfignon.items} >
                
                </Form> 
            </ScrollView>
      
        if(this.props.woBookedOnTo.length > 0) {
          
            if(this.props.woBookedOnTo.allocated !== this.props.user.profile.sub) {
            output = <ScrollView>
             <Form items={offformConfig.items} >
                
                </Form> 

        </ScrollView>
            }
        }
   
        

        return  (<div style={{height: '100%'}}>
                { output }
                </div>)
        }
}

const mapStateToProps = (state) => {
    return {
      woBookedOnTo: state.booking.bookedOnToWo,
      user: state.oidc.user,
    };
  }

export default connect(mapStateToProps, null)(BookOffAllPopup)