import { createStore } from 'devextreme-aspnet-data-nojquery';

export const WOAPI = 'https://woapi.bosa.uat99.com/';
export const MANUALAPI = 'https://manualapi.bosa.uat99.com/';
export const HUBHOME = 'https://hub.bosa.uat99.com';


export const CustomersDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/customers/',
      updateUrl: WOAPI + 'api/customers/',
      insertUrl: WOAPI + 'api/customers/',
      deleteUrl:  WOAPI + 'api/customers/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const WorkorderStatusDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorderstatus/',
      updateUrl: WOAPI + 'api/workorderstatus/',
      insertUrl: WOAPI + 'api/workorderstatus/',
      deleteUrl:  WOAPI + 'api/workorderstatus/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const WorkorderTypeDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workordertypes/',
      updateUrl: WOAPI + 'api/workordertypes/',
      insertUrl: WOAPI + 'api/workordertypes/',
      deleteUrl:  WOAPI + 'api/workordertypes/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const ReleaseTypeDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/releasetypes/',
      updateUrl: WOAPI + 'api/releasetypes/',
      insertUrl: WOAPI + 'api/releasetypes/',
      deleteUrl:  WOAPI + 'api/releasetypes/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const PartRequestStatusesDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/partrequeststatuses/',
      updateUrl: WOAPI + 'api/partrequeststatuses/',
      insertUrl: WOAPI + 'api/partrequeststatuses/',
      deleteUrl:  WOAPI + 'api/partrequeststatuses/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const CountriesDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/countries/',
      updateUrl: WOAPI + 'api/countries/',
      insertUrl: WOAPI + 'api/countries/',
      deleteUrl:  WOAPI + 'api/countries/',
       onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const CurrencyDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/currencies/',
      updateUrl: WOAPI + 'api/currencies/',
      insertUrl: WOAPI + 'api/currencies/',
      deleteUrl:  WOAPI + 'api/currencies/',
       onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const AddressTypeDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/addresstypes/',
      updateUrl: WOAPI + 'api/addresstypes/',
      insertUrl: WOAPI + 'api/addresstypes/',
      deleteUrl:  WOAPI + 'api/addresstypes/',
       onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const BookingTypeDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/bookingtypes/',
      updateUrl: WOAPI + 'api/bookingtypes/',
      insertUrl: WOAPI + 'api/bookingtypes/',
      deleteUrl:  WOAPI + 'api/bookingtypes/',
       onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const WOPriorityDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/wopriority/',
      updateUrl: WOAPI + 'api/wopriority/',
      insertUrl: WOAPI + 'api/wopriority/',
      deleteUrl:  WOAPI + 'api/wopriority/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        } 
      }
    )
  }

  export const AddressDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/customeraddress/' + id + '/',
      updateUrl: WOAPI + 'api/customeraddress/' + id + '/',
      insertUrl: WOAPI + 'api/customeraddress/' + id + '/',
      deleteUrl: WOAPI + 'api/customeraddress/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const ContactDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/customercontacts/' + id + '/',
      updateUrl: WOAPI + 'api/customercontacts/' + id + '/',
      insertUrl: WOAPI + 'api/customercontacts/' + id + '/',
      deleteUrl: WOAPI + 'api/customercontacts/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const WorkOrderGridDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorders/getextendedworkorders',
   //   updateUrl: WOAPI + 'api/customercontacts/' + id + '/',
      insertUrl: WOAPI + 'api/workorders/addworkorder',
   //   deleteUrl: WOAPI + 'api/customercontacts/' + id + '/',
     onBeforeSend: (method, ajaxOptions) => {
     ajaxOptions.headers =  {'Authorization': "bearer " + token} 
       }
      }
    )
  }

  export const WorkOrderWorkflowGridDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorders/GetOpenFilteredWFWorkorders',
   //   updateUrl: WOAPI + 'api/customercontacts/' + id + '/',
      insertUrl: WOAPI + 'api/workorders/addworkorder',
   //   deleteUrl: WOAPI + 'api/customercontacts/' + id + '/',
     onBeforeSend: (method, ajaxOptions) => {
     ajaxOptions.headers =  {'Authorization': "bearer " + token} 
       }
      }
    )
  }


  export const AllocationDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/allocations',
      updateUrl: WOAPI + 'api/allocations',
      deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const UsersDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/users',
      // updateUrl: WOAPI + 'api/allocations',
      // insertUrl: WOAPI + 'api/allocations',
      // deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const UserGroupDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/usergroups/getusergroups',
      // updateUrl: WOAPI + 'api/allocations',
      // insertUrl: WOAPI + 'api/allocations',
      // deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const EntityDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/entities',
      // updateUrl: WOAPI + 'api/allocations',
      // insertUrl: WOAPI + 'api/allocations',
      // deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const StatusMapDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/wostatusmap',
      updateUrl: WOAPI + 'api/wostatusmap',
      insertUrl: WOAPI + 'api/wostatusmap',
      deleteUrl: WOAPI + 'api/wostatusmap',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const WorkTypeDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/worktypes',
      updateUrl: WOAPI + 'api/worktypes',
      insertUrl: WOAPI + 'api/worktypes',
      deleteUrl: WOAPI + 'api/worktypes',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const TimeBookingDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/bookings',
      updateUrl: WOAPI + 'api/bookings',
      // insertUrl: WOAPI + 'api/allocations',
      deleteUrl: WOAPI + 'api/bookings',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const CustomerQuoteDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/customerquote/' + id + '/',
      updateUrl: WOAPI + 'api/customerquote/' + id + '/',
      insertUrl: WOAPI + 'api/customerquote/' + id + '/',
      deleteUrl: WOAPI + 'api/customerquote/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const WorkorderNoteDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workordernote/getworkordernotes/' + id + '/',
      updateUrl: WOAPI + 'api/workordernote/updatenote/' + id + '/',
      insertUrl: WOAPI + 'api/workordernote/addnote/' + id + '/',
      deleteUrl: WOAPI + 'api/workordernote/deleteworkordernote/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  // interactions other than insert and list are done via axios.
  export const WorkorderPartsDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorderparts/getworkorderparts/' + id + '/',
      insertUrl: WOAPI + 'api/workorderparts/addpartrequest/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  //updates done via axios.
  export const WorkorderQueriesDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorderqueries/getworkorderqueries/' + id + '/',

      insertUrl: WOAPI + 'api/workorderqueries/addquery/' + id + '/',

      onBeforeSend: (method, ajaxOptions) => {
     ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  
  export const PartLookupDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/partlookup',
      // updateUrl: WOAPI + 'api/allocations',
      // insertUrl: WOAPI + 'api/allocations',
      // deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const WorkorderFileDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorderfiles/getfiles/' + id,
      insertUrl: WOAPI + 'api/workorderfiles/addfile/' + id,
      deleteUrl: WOAPI + 'api/workorderfiles/deletefile/' + id,
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
       }
      }
    )
  }

  export const PartRequestStatusMapDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/prstatusmap',
      updateUrl: WOAPI + 'api/prstatusmap',
      insertUrl: WOAPI + 'api/prstatusmap',
      deleteUrl: WOAPI + 'api/prstatusmap',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const PRPriorityDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/partrequestpriorities',
      updateUrl: WOAPI + 'api/partrequestpriorities',
      insertUrl: WOAPI + 'api/partrequestpriorities',
      deleteUrl: WOAPI + 'api/partrequestpriorities',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const BookOffReasonDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/bookoffreasons',
      updateUrl: WOAPI + 'api/bookoffreasons',
      insertUrl: WOAPI + 'api/bookoffreasons',
      deleteUrl: WOAPI + 'api/bookoffreasons',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const BookOffReasonStatusDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/bookoffstatusmap',
      updateUrl: WOAPI + 'api/bookoffstatusmap',
      insertUrl: WOAPI + 'api/bookoffstatusmap',
      deleteUrl: WOAPI + 'api/bookoffstatusmap',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const FileTypesDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/filetypes',
      updateUrl: WOAPI + 'api/filetypes',
      insertUrl: WOAPI + 'api/filetypes',
      deleteUrl: WOAPI + 'api/filetypes',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const PartRequestDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/partrequests',
      updateUrl: WOAPI + 'api/partrequests',
      deleteUrl: WOAPI + 'api/partrequests',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const ProductionWODataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/production/getworkorders',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const AllocatedWODataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/production/getallocatedworkorders',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const QueryStatusDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/querystatus',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const OpenQueryDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/queries/getopenqueries',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const FilteredOpenQueryDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/queries/getfilteredopenqueries',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const AllQueryDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/queries/getallqueries',
      deleteUrl: WOAPI + 'api/queries/deleteQuery',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const PartWorkflowDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/partworkflow/getpartworkflow',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const SecurityRightDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/rights/getallsecurityrights',
      // updateUrl: WOAPI + 'api/allocations',
      // insertUrl: WOAPI + 'api/allocations',
      // deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const GrantedRightsDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/rights/getallgrantedrights',
      updateUrl: WOAPI + 'api/rights/updategrantedright',
      insertUrl: WOAPI + 'api/rights/addgrantedright',
      deleteUrl: WOAPI + 'api/rights/deletegrantedright',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const FilteredPartWorkflowDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/partworkflow/getfilteredpartworkflow',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const WorkorderNotePDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workordernote/getworkordernotes/' + id + '/',
      updateUrl: WOAPI + 'api/workordernote/updatenoteP/' + id + '/',
      insertUrl: WOAPI + 'api/workordernote/addnoteP/' + id + '/',
      deleteUrl: WOAPI + 'api/workordernote/deleteworkordernoteP/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

   // interactions other than insert and list are done via axios.
   export const WorkorderPartsPDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorderparts/getworkorderparts/' + id + '/',
      insertUrl: WOAPI + 'api/workorderparts/addpartrequestp/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const WorkorderFilePDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorderfiles/getfiles/' + id,
      insertUrl: WOAPI + 'api/workorderfiles/addfilep/' + id,
      deleteUrl: WOAPI + 'api/workorderfiles/deletefilep/' + id,
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
       }
      }
    )
  }

  export const AllocUsersDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/users',
      // updateUrl: WOAPI + 'api/allocations',
      // insertUrl: WOAPI + 'api/allocations',
      // deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const UserRightsDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/rights/getalluserrights',
      updateUrl: WOAPI + 'api/rights/updateuserright',
      insertUrl: WOAPI + 'api/rights/adduserright',
      deleteUrl: WOAPI + 'api/rights/deleteuserright',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  //updates done via axios.
  export const ProdWorkorderQueriesDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/workorderqueries/getworkorderqueries/' + id + '/',

      insertUrl: WOAPI + 'api/workorderqueries/addqueryp/' + id + '/',

      onBeforeSend: (method, ajaxOptions) => {
     ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }


   export const ManHoursDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/wosummary/getmanhours/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
     ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const UserGroupWithAccessDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/usergroups/getusergroupswithaccess',
      // updateUrl: WOAPI + 'api/allocations',
      // insertUrl: WOAPI + 'api/allocations',
      // deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const AllRulesDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/rules/getrules',
      updateUrl: WOAPI + 'api/rules/updaterule',
      insertUrl: WOAPI + 'api/rules/addrule',
      deleteUrl: WOAPI + 'api/rules/deleterule',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const HookDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/rules/gethooks',
      // updateUrl: WOAPI + 'api/allocations',
      // insertUrl: WOAPI + 'api/allocations',
      // deleteUrl: WOAPI + 'api/allocations',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const RuleActionsDataSource = (id,token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/ruleactions/' + id + '/',
      updateUrl: WOAPI + 'api/ruleactions/' + id + '/',
      insertUrl: WOAPI + 'api/ruleactions/' + id + '/',
      deleteUrl: WOAPI + 'api/ruleactions/' + id + '/',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }

  export const ActionsDataSource = (token) => {
    return createStore({
      key: 'id',
      loadUrl: WOAPI + 'api/rules/getactiontypes',
      onBeforeSend: (method, ajaxOptions) => {
      ajaxOptions.headers =  {'Authorization': "bearer " + token} 
        }
      }
    )
  }