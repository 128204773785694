import * as actionTypes from '../actions/actionTypes';

const initialState = {
    rowData: null,
}

const queryReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_QUERY_DETAIL:
         
            return {
                ...state,
                rowData: action.rowData

            }
        default:
            return state;

    }
}

export default queryReducer;