import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as utils from '../WorkorderTabs/common';

import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Form
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';




const getHeight = () => {
    return (window.innerHeight - 48)
}

class ReleaseTypeGrid extends Component {
    state = {
    };

    resize = () => {
       this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      setDefaultNewValues = (e) => {
        e.data.active = true; 
        e.data.mandatoryReference = false;
        e.data.caplistEntryOnly = false;
      }

      onEditorPreparing = (e) =>  {

        if (e.parentType === 'dataRow') {
            
            if (e.dataField === "crsText")
            e.editorName = "dxTextArea";
        
        }        
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    render() {
        return  (
            <DataGrid 
            dataSource={apis.ReleaseTypeDataSource(this.props.user.access_token)}  
            noDataText="There are no release types defined."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'releaseTypes' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onInitNewRow={this.setDefaultNewValues} onEditorPreparing={this.onEditorPreparing} onToolbarPreparing={this.onToolbarPreparing}
         >
        <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.adminReleaseTypes}
            allowDeleting={this.props.grants.rights.adminReleaseTypes}
            allowAdding={this.props.grants.rights.adminReleaseTypes} >
            <Form colCount="4">
            </Form>
             <Popup title={'Release Types'} showTitle={true} width={utils.popupWidth()} height={utils.popupHeight()}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
        </Editing>
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'description'}>
            <RequiredRule message="Release description is required." />
            <StringLengthRule max="100" message="The release type description cannot exceed 100 characters."/>
        </Column>
        <Column dataField={'mandatoryReference'} />
        <Column dataField={'caplistEntryOnly'} dataType="boolean" />
        <Column dataField={'active'} />
        <Column dataField={'sequenceGenerator'} dataType="number" />
        {/* <Column dataField={'crsText'} caption="Statement" visible={false} showInColumnChooser={false} >
            <FormItem editorOptions={{height: "100px"}} visible={true} colSpan="5" />
            <StringLengthRule max="4096" message="The release type description cannot exceed 4096 characters."/>
        </Column> */}
        </DataGrid>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        grants: state.rights
    };
  }


export default connect(mapStateToProps, null)(ReleaseTypeGrid)