import * as actionTypes from '../actions/actionTypes';

const initialState = {
    rowData: null
}

const ruleReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_RULE_DETAIL:
         
            return {
                ...state,
                rowData: action.rowData

            }
        
        default:
            return state;

    }
}

export default ruleReducer;