import React, {Component} from 'react'
import { Form, ScrollView } from 'devextreme-react';
import { connect } from "react-redux";
import * as apis from '../../../utils/apis';

class BookOffPopup extends Component {
   

    render() {
        let bookingOffForm = {
            reasonId : null,
            notes: '',
            openPartRequestCount: null,
            openQueryCount: null
        }  

         let formConfig = {
             validationGroup : "bookoff",
            items: [
                {
                    itemType: "group",
                    colCount: 2,
                    colSpan: 1,
                    items: [
                        {
                            dataField: 'openPartRequestCount',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true,   
                            }
                            
        
                        },
                        {
                            dataField: 'openQueryCount',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true,     
                            }
        
                        },
                    ]
                },

               
                {
                    
                    dataField: 'reasonId',
                    colSpan: 1,
                    editorType: "dxSelectBox",
                    editorOptions: {
                        displayExpr: 'reasonText',
                        valueExpr: 'id',
                        dataSource: apis.BookOffReasonDataSource(this.props.user.access_token)
                    },
                    
                    label : { 
                        text: "Reason"},
                        validationRules: [
                    {
                        validationGroup: "bookoff",
                        type: "required",
                        message: "Reason is Required.",        
                    },
                    ]
                },
                {
                    dataField: "notes",
                    colSpan: 2,
                    editorType: "dxTextArea",
                   
                    editorOptions: {
                        height: "100px",
                      
                
                    },
                    label : { text: "Additional Notes"},
                    validationRules: [{
                        validationGroup: "bookoff",
                        type: "stringLength",
                        max: 1024,
                        message: "Notes cannot be longer than 1024 characters."
                    }]
                    
                },
           
              
               {
                itemType: "button",
                visible: true,
                horizontalAlignment: "right",
                colSpan: 1,
                buttonOptions: {
                    text: "Book Off W/O",
                    type: "default",
                    useSubmitBehavior: false,
                    onClick: (params) => this.props.submitBookOffRequest(params,bookingOffForm.reasonId, this.props.woBookedOnTo.id,bookingOffForm.notes) 
                }
            }
            
            ]
        }

        let offformConfig = {
         
           items: [
               
              {
               itemType: "button",
               visible: true,
               horizontalAlignment: "right",
               colSpan: 1,
               buttonOptions: {
                   text: "Book Off W/O",
                   type: "default",
                   useSubmitBehavior: false,
                   onClick: () => this.props.submitSimpleBookOff(this.props.woBookedOnTo.id) 
               }
           }
           
           ]
       }
 

       

       let output = <ScrollView>
                <Form formData ={bookingOffForm} items={formConfig.items} >
                    
                </Form> 
            </ScrollView>
        
        if(this.props.woBookedOnTo) {
           
           bookingOffForm.openQueryCount = this.props.woBookedOnTo.openQueryCount;
           bookingOffForm.openPartRequestCount = this.props.woBookedOnTo.openPartRequestCount;

            if(this.props.woBookedOnTo.allocated !== this.props.user.profile.sub) {
            output = <ScrollView>
            <Form items={offformConfig.items} >
                
            </Form> 
        </ScrollView>
            }
        }
        else
        {
            output =<p>You are not currently booked to a workorder.</p>
        }
        

        return  (<div style={{height: '100%'}}>
                { output }
                </div>)
        }
}

const mapStateToProps = (state) => {
    return {
      woToBookTo : state.booking.rowData,
      woBookedOnTo: state.booking.woToInteract,
 
      user: state.oidc.user,
    };
  }

export default connect(mapStateToProps, null)(BookOffPopup)
