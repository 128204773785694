import React, {Component} from 'react';
import { connect } from "react-redux";
import { Form, ScrollView } from 'devextreme-react';
import * as apis from '../../utils/apis';



class CancelQueryPopup extends Component {

    

    render() {

        let formConfig = {
            validationGroup: 'cancel',
            items : [
                {
                    itemType: "group",
                    colCount: 5,
                    colSpan: 5,
                    items: [
                        
                        {
                            dataField: 'queryIdentifier',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "Query #"},
                            
                        },
                        {
                            dataField: 'claimedBy',
                            colSpan: 1,                    
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Claimed by"},
                        },
                        {
                            dataField: 'queryStatusId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'description',
                                valueExpr: 'id',
                                dataSource: apis.QueryStatusDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Status"
                            },
                               
                        },
                        {
                            dataField: 'userGroupId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'name',
                                valueExpr: 'id',
                                dataSource: apis.UserGroupDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Responsible"
                            },
                               
                        },
                        {
                            dataField: 'raisedBy',
                            colSpan: 1,                    
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Raised by"},
                        },
                        
                        
                    ]
                },
                {
                    dataField: 'question',
                    colSpan: 4,
                    editorType: "dxTextArea",
                    editorOptions: {
                        height: "150px",
                        readOnly: true
                    },
                    label: {
                        text: "Question"
                    }

                },
                {
                    dataField: 'response',
                    colSpan: 4,
                    editorType: "dxTextArea",
                    editorOptions: {
                        height: "150px",
                        readOnly: false
                    },
                    validationRules: [
                        {
                            validationGroup: "cancel",
                            type: "required",
                            message: "Reason is Required.",        
                        },
                        ],
                    label: {
                        text: "Cancellation Reason"
                    }

                },
                {
                    itemType: "button",
                    visible: true,
                    horizontalAlignment: "right",
                    colSpan: 4,
                    buttonOptions: {
                        text: "Cancel Query",
                        type: "default",
                        useSubmitBehavior: false,
                        onClick: (params) => this.props.cancelQuery(params,this.props.query.id, this.props.query.response)
                    }
                }
                
               
            ]


    }

        let output = null;

       

        if(this.props.query) {
            output = 
            <ScrollView>
                <Form formData ={this.props.query} items={formConfig.items}>

                </Form>
            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        query : state.queryDetail.rowData
    };
  }

export default connect(mapStateToProps,null)(CancelQueryPopup)