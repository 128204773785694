import React, {Component, Fragment} from 'react';
import { connect } from "react-redux";
//import * as apis from '../../../utils/apis';
import axios from 'axios';
import { LoadIndicator  } from 'devextreme-react';



class WODispatchRuleResults extends Component {
    _isMounted = false;
    state =  {
        loading: true,
        error: false,
        selectedId: null,
        ruleResults: ['Assessing Rules - Please Wait...']
    }

    componentDidMount() {
        this._isMounted = true;

        axios.get('api/rulecheck/dispatch/' + this.props.selectedWo.id)
        .then(response => {
            if (this._isMounted) {
                this.setState({ loading: false, selectedId: this.props.selectedWo.id, ruleResults: response.data });
              }
        });

    } 
    
    componentDidUpdate() {

        if(this.props.selectedWo.id !== this.state.selectedId)
        {
            this.loadIndicator.instance.option('visible',true);  
            axios.get('api/rulecheck/dispatch/' + this.props.selectedWo.id)
            .then(response => {
                if (this._isMounted) {
                    this.loadIndicator.instance.option('visible',false);
                    this.setState({ loading: false, selectedId: this.props.selectedWo.id, ruleResults: response.data });
                  }
            });
        }
        

    } 

    componentWillUnmount() {
        this._isMounted = false;
    }

    
    ruleResultStyle= 'ruleGood';
    
    render() {

        if(this.state.ruleResults[0] !== 'Assessing Rules - Please Wait...')
        {
            this.ruleResultStyle = 'ruleBad';
        }
        var errorList = this.state.ruleResults.map( function(item,index) {
            return <p key={index}>{item}</p>
        } )

        return  <Fragment>
                <h2>Dispatch Rule Checks</h2>
                {this.state.ruleResults.length > 0 ? <div className={this.ruleResultStyle}>{errorList}</div> : <div className='ruleGood'>OK</div>}
                <LoadIndicator visible={this.state.loading} ref={(li) => {this.loadIndicator = li}}/>
                </Fragment>
    }
}
const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      selectedWo: state.woDetail.rowData,
      grants: state.rights
    };
  }



export default connect(mapStateToProps,null)(WODispatchRuleResults);