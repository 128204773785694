import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import QueryDetailsPopup from '../QueryDetailsPopup/QueryDetailsPopup';
import {Popup} from 'devextreme-react';
import ReleaseQueryClaimPopup from '../ReleaseQueryClaimPopup/ReleaseQueryClaimPopup';
import ReRouteQueryPopup from '../ReRouteQueryPopup/ReRouteQueryPopup';

import DataGrid, {
    Column,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Editing,
    Form,
    Position,
    FormItem,
    Export

} from 'devextreme-react/ui/data-grid';

const getHeight = () => {
    return (window.innerHeight - 48)
}

class QueryAdmin extends Component {
    state = {
    };

    onEditorPreparing = (e) =>  {
        if (e.parentType === 'dataRow') {
            
            if (e.dataField === "question")
            e.editorName = "dxTextArea";

            if (e.dataField === "response")
            e.editorName = "dxTextArea";
        }    
    }

    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      validToRelease = (e) => {
        
        return !e.row.isEditing && (e.row.data.claimedById !== null) 
      }

      claimReleased = () => {
        this.releaseClaimPopup.instance.hide();
        this.gridview.instance.refresh();
      }

      queryUpdated = () => {
        this.reRouteQueryPopup.instance.hide();
        this.gridview.instance.refresh();
      }

      releaseClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingQuery(e.row.data);
        this.releaseClaimPopup.instance.show();
      }

      reRouteClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingQuery(e.row.data);
        this.reRouteQueryPopup.instance.show();
      }

      validToReRoute = (e) => {
        
        return !e.row.isEditing && (e.row.data.queryStatusId !== 2) 
      }
     
      infoClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingQuery(e.row.data);
        this.popup.instance.show();
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }
    
    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.AllQueryDataSource(this.props.user.access_token)}  
            noDataText="There are no matching queries in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'queryenq' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} onToolbarPreparing={this.onToolbarPreparing}
            onEditorPreparing={this.onEditorPreparing}
         >

        <Export enabled={this.props.grants.rights.exportData} fileName="queries" />
        <Editing
            mode={'popup'}  
            allowDeleting={true}        
         >
         <Form colCount='5'></Form>
             <Popup title={'Query Administrative Edit'} showTitle={true} width={this.popupWidth()} height={this.popupHeight()}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
        </Editing>
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        <Column dataField={'queryIdentifier'} dataType="string" >
            <FormItem editorOptions={{readOnly : true}} />
        </Column>
        <Column dataField={'id'} visible={false} dataType="number">
            <FormItem visible={false} />
        </Column>
     
       
        <Column dataField={'raisedBy'} dataType="string">
            <FormItem editorOptions={{readOnly : true}}/>
        </Column>
        <Column dataField={'claimedBy'} dataType="string" >
            <FormItem editorOptions={{readOnly : true}}/>
        </Column>
        <Column dataField={'closedBy'} dataType="string" >
            <FormItem editorOptions={{readOnly : true}}/>
        </Column>
        <Column dataField={'dateOpened'}  dataType="date" format="dd/MM/y" >
            <FormItem editorOptions={{readOnly : true}}/>
        </Column>
        <Column dataField={'dateResolved'}  dataType="date" format="dd/MM/y">
            <FormItem editorOptions={{readOnly : true}}/>
        </Column> 
       

    
        <Column dataField={'userGroupId'} caption="Responsible" dataType="string">
            <Lookup dataSource={apis.UserGroupWithAccessDataSource(this.props.user.access_token)} displayExpr={'name'} valueExpr={'id'} />
            <FormItem colSpan={2} />
        </Column>

        <Column dataField={'queryStatusId'} caption="Status" dataType="number">
            <Lookup dataSource={apis.QueryStatusDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'}/>
            <FormItem colSpan={2} />
        </Column>
        <Column dataField={'question'} dataType="string" visible={false} showInColumnChooser={false}>
            <FormItem visible={true} editorOptions={{height: "150px"}} colSpan={5} />
        </Column> 
        <Column dataField={'response'} dataType="string"visible={false} showInColumnChooser={false} >
            <FormItem visible={true} editorOptions={{height: "150px"}} colSpan={5} />
        </Column> 

        <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
          buttons={[ 'delete',{
            hint: 'Details',
            icon: 'info',
            onClick: this.infoClickedHandler
          },
          {
            hint: 'Release Claim',
            icon: 'clearsquare',
            onClick: this.releaseClickedHandler,
            visible: this.validToRelease
          },
          {
            hint: 'Reroute',
            icon: 'group',
            onClick: this.reRouteClickedHandler,
            visible: this.validToReRoute
          },
          ]} />
   
        </DataGrid>
            <Popup title="Query details" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <QueryDetailsPopup></QueryDetailsPopup>
            </Popup>
            <Popup title="Release Claim" ref={(popup) => {this.releaseClaimPopup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <ReleaseQueryClaimPopup claimReleasedHandler={this.claimReleased} adminMode={true}></ReleaseQueryClaimPopup> 
            </Popup>
            <Popup title="Reroute Query" ref={(popup) => {this.reRouteQueryPopup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <ReRouteQueryPopup queryUpdatedHandler={this.queryUpdated} ></ReRouteQueryPopup> 
            </Popup>

        </div>
        );
    }


}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      grants: state.rights
  };
}

const mapDispatchToProps = dispatch => {
  return {
       onSelectingQuery: (rowData) => dispatch(actions.setQueryDetail(rowData)),
     
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(QueryAdmin)