import React, {Component, Fragment} from 'react';
import { Form, LoadIndicator  } from 'devextreme-react';
import axios from 'axios';
import * as apis from '../../../../utils/apis';
import notify from 'devextreme/ui/notify';
import { connect } from "react-redux";
import { confirm} from 'devextreme/ui/dialog';
import * as actions from '../../../../store/actions/actions';
import { withRouter } from "react-router-dom";
import { notificationTiming } from '../../../../utils/appConfig';

class DetailsForm extends Component {

    state =  {
        loading: true,
        error: false,
        customersData: null,
        workorderTypes: null,
        entities: null,
        releaseTypes: null,
        workorderStatuses: null,
        workTypes: null
    }

    componentDidMount() {

        axios.get('api/worktypes/')
        .then(response => {
            this.setState({ workTypes: response.data.data });
    
        });
        
        axios.get('api/entities/')
        .then(response => {
            this.setState({ entities: response.data.data });
    
        });

        axios.get('api/workorderstatus/')
        .then(response => {
            this.setState({ workorderStatuses: response.data.data });
    
        });

        
        axios.get('api/releasetypes/')
        .then(response => {
            this.setState({ releaseTypes: response.data.data });
    
        });

        axios.get('api/workordertypes/')
        .then(response => {
            this.setState({ workorderTypes: response.data.data });
    
        });

        axios.get('api/customers/')
        .then(response => {
            this.setState({ customersData: response.data.data });
    
        });
        

        axios.get('api/workorderheader/' + this.props.selectedWo.id)
        .then(response => {
            this.detailsFormData = response.data;
            this.setState({ loading: false });
    
        });
    } 


    submitUpdateRequest = (params) => {
      
        var result = params.validationGroup.validate();

        if(result.isValid) {   
            
            let result = confirm("<i>Did you consider a status change?</i>", "Status Change");
            result.then((dialogResult) => {
                if(dialogResult === true) {


            axios.post('api/workorderheader/' + this.props.selectedWo.id, this.detailsFormData)
            .then(response => {
                notify("Detail Header Updated","success", notificationTiming); 
                 if(this.props.selectedWo.customerId !== this.detailsFormData.customerId)
                 {
                    this.props.onChangingCustomer(this.detailsFormData.customerId);
                }  
                this.detailsForm.instance.updateData(response.data);
                this.props.history.goBack();
            })
            .catch(error => {
                notify(error.response.data,"error", notificationTiming);
            });
        }
        });
    }
}

reOpenWorkorder = (params) => {
      
           
        let result = confirm("<i>Reopen Workorder?</i>", "Reopen?");
        result.then((dialogResult) => {
            if(dialogResult === true) {

                let data = {
                    id: this.props.selectedWo.id,
                    statusId: this.detailsFormData.workOrderStatusId
                }

        axios.post('api/workorders/reopenworkorder/', data)
        .then(response => {
            notify("Workorder reopened","success", notificationTiming);
            this.props.history.goBack();
        })
        .catch(error => {
            notify(error.response.data,"error", notificationTiming);
        });
    }
    });

}



    pressureVesselToggle = (e) => {
        if(this.detailsForm !== null) {
            if(e.value === true) {
                this.detailsForm.instance.itemOption('pressureData','visible',true);
            }
            else { 
                this.detailsForm.instance.itemOption('pressureData','visible',false);
                this.detailsFormData.cylinderSerialNo =  null;
                this.detailsFormData.regulatorSerialNo = null;
                
                this.detailsFormData.hstDue = null;
                this.detailsFormData.overhaulDue = null;
            }
        }
        
    }

    detailsFormData = {
        woNumber: null,
        dateReceived: null,
        entityId: null,
        workOrderTypeId: null,
        partNumber: null,
        serialNo: null,
        quantity: null,
        partDescription: null,
        releaseTypeId: null,
        releaseReference: null,
        customerId: null,
        customerReference: null,
        warranty: false,
        aog: false,
        targetDate: null,
        workTypeId: null,
        boxId: null,
        isPressureVessel: false,
        cylinderSerialNo: null,
        regulatorSerialNo: null,
        workOrderStatusId: null,
        hstDue: null,
        overhaulDue: null,
        dueDate: null 
    }

    render() {
        let detailsFormConfig = {
            items: [
                {
                    itemType: "group",
                    colCount: 5,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: 'woNumber',
                            colSpan: 1,
                            editorType: "dxNumberBox",
                            editorOptions: {
                                showSpinButtons: true,
                                showClearButton: true,
                                readOnly: true
                            },
                            label : { text: "W/O #"},
                            validationRules: [{
                                type: "required",
                                message: "Workorder No is Required."
                            },
                            ]
                        },
                        {
                            dataField: 'workOrderTypeId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'code',
                                valueExpr: 'id',
                                dataSource: this.state.workorderTypes,
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "Type"},
                                validationRules: [{
                                type: "required",
                                message: "Workorder type is Required.",
                               
                            },
                            ]
                        },
                        {
                            dataField: 'workOrderStatusId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'status',
                                valueExpr: 'id',
                                dataSource: this.state.workorderStatuses,
                                readOnly: (this.props.grants.rights.editWorkorderHeader === false && this.props.grants.rights.reOpenWorkorder === false) || (this.props.selectedWo.complete === true && this.props.grants.rights.reOpenWorkorder === false)
                            },
                            label : { 
                                text: "Status"},
                                validationRules: [{
                                type: "required",
                                message: "Workorder Status is Required."
                            },
                            ]
                        },
                        {
                            dataField: 'workTypeId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'description',
                                valueExpr: 'id',
                                dataSource: this.state.workTypes,
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "Work Type"
                            },
                                
                        },
                        {
                            dataField: 'entityId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'shortDescription',
                                valueExpr: 'id',
                                dataSource: this.state.entities,
                                readOnly: true,
                            },
                            label : { 
                                text: "Entity"},
                                validationRules: [{
                                type: "required",
                                message: "Entity is Required."
                            },
                            ]
                        },
                        
                    ]
                },
                {
                    itemType: "group",
                    colCount: 4,
                    colSpan: 3,
                    items: [
                        {
                            dataField: 'partNumber',
                            colSpan: 1,
                            editorType: "dxAutocomplete",
                            editorOptions: {
                                dataSource : apis.PartLookupDataSource(this.props.user.access_token),
                                placeholder: 'Start typing',
                                searchMode: 'startswith',
                                displayExpr :'partNumber',
                                valueExpr: 'partNumber',
                                searchExpr: 'partNumber',
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true  || this.props.selectedWo.complete
                            },
                            label : { 
                            text: "Part No"},
                            validationRules: [{
                                type: "required",
                                message: "Part number is Required."
                            },
                            {
                                type: "stringLength",
                                max: 32,
                                message: "Part number cannot be longer than 32 characters."
                            }
                            ]
                        },
                        {
                            dataField: 'partDescription',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "Part Description"},
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Part Description is required."
                                    },
                                    {
                                        type: "stringLength",
                                        max: 100,
                                        message: "Part description cannot be longer than 100 characters."
                                    }
                            ]
                        },
                       
                        {
                            dataField: 'serialNo',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { text: "Serial No"},
                            validationRules: [
                                {
                                    type: "stringLength",
                                    max: 50,
                                    message: "Serial number cannot be longer than 50 characters."
                                },
                            
                            ]
                        },
                        {
                            dataField: 'quantity',
                            colSpan: 1,
                            editorType: "dxNumberBox",
                            editorOptions: {
                                showSpinButtons: true,
                                showClearButton: true,
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "Qty"
                            },
                            validationRules: [
                                {
                                    type: "required",
                                    message: "Quantity is required."
                                },
                                {
                                    type: "range",
                                    min: 1,
                                    message: "Quantity must be at least 1"
                                }
                            
                            ]
                           
                        },
                    ]
                },
               {
                itemType: "group",
                colCount: 5,
                colSpan: 3,
                items : [
                    {
                        dataField: 'customerId',
                        colSpan: 1,
                        editorType: "dxSelectBox",
                        editorOptions: {
                            displayExpr: 'customerName',
                            valueExpr: 'id',
                            searchExpr: "customerName",
                            searchEnabled: true,
                            dataSource: this.state.customersData,
                            readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                        },
                        label : { 
                            text: "Customer"},
                            validationRules: [{
                            type: "required",
                            message: "Customer is Required."
                        },
                        ]
                    },
                    {
                        dataField: 'customerReference',
                        colSpan: 1,
                        editorType: "dxTextBox",
                        editorOptions: {
                            readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                        },
                        label : {
                        text: "Customer Ref"},
                        validationRules: [{
                            type: "required",
                            message: "Customer Reference is required."
                        },
                        {
                            type: "stringLength",
                            max: 512,
                            message: "Customer reference cannot be longer than 512 characters."
                        }
                        ]
                    },
                    {
                        dataField: 'dateReceived',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            dateSerializationFormat: "yyyy-MM-dd",
                            readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                        },
                        label : { 
                            text: "Date Received"
                        },
                        validationRules: [{
                            type: "required",
                            message: "Date Received is Required."
                        },
                        ]
                           
                    },
                    {
                        dataField: 'dueDate',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            dateSerializationFormat: "yyyy-MM-dd",
                            readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                        },
                        label : { 
                            text: "Due Date"
                        },
                           
                    },
                    {
                        dataField: 'dispatchDate',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            dateSerializationFormat: "yyyy-MM-dd",
                            readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                        },
                        label : { 
                            text: "Close Date"
                        },
                           
                    },
                ]
               },
                
               
                {
                    dataField: 'releaseTypeId',
                    colSpan: 1,
                    editorType: "dxSelectBox",
                    editorOptions: {
                        displayExpr: 'description',
                        valueExpr: 'id',
                        searchExpr: "description",
                        searchEnabled: true,
                        dataSource: this.state.releaseTypes,
                        readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                    },
                    label : { 
                        text: "Release"},
                        validationRules: [{
                        type: "required",
                        message: "Release type is Required."
                    },
                    ]
                },
                {
                    dataField: 'releaseReference',
                    
                    colSpan: 1,
                    editorType: "dxNumberBox",
                    editorOptions: {
                        readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete,
                        min: 1
                    },
                    label : {
                    text: "Release Ref"},
                    
                },
                {
                    dataField: 'boxId',
                    colSpan: 1,
                    editorType: "dxTextBox",
                    editorOptions: {
                        readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                    },
                    label : {
                         text: "Box ID"
                    },
                    validationRules: [ {
                        type: "stringLength",
                        max: 100,
                        message: "Box Id cannot be longer than 100 characters."
                    }
                    ]
                },
                
                {
                    dataField: 'warranty',
                    colSpan: 1,
                    editorType: "dxCheckBox",
                    editorOptions: {
                        readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                    },
                    label : {
                    text: "Warranty"
                    },
                },
                {
                    dataField: 'aog',
                    colSpan: 1,
                    editorType: "dxCheckBox",
                    editorOptions: {
                        readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                    },
                    label : {
                    text: "AOG"},
                   
                },
                {
                    dataField: 'isPressureVessel',
                    colSpan: 1,
                    editorType: "dxCheckBox",
                    editorOptions: {
                        readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete,
                        onValueChanged: this.pressureVesselToggle
                    },
                    label : { 
                        text: "Pressure Vessel"
                    },
                   
                },
               
               
                {
                    itemType: "group",
                    name: "pressureData",
                    caption: "Pressure Vessel Data",
                    colCount: 5,
                    colSpan: 3,
                    visible: this.detailsFormData.isPressureVessel,
                    items: [
                       
                        {
                            dataField: 'cylinderSerialNo',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "Cylinder S/N"
                            },
                            validationRules: [ {
                                type: "stringLength",
                                max: 100,
                                message: "Cylinder serial No cannot be longer than 100 characters."
                            }
                            ]
                               
                        },
                        {
                            dataField: 'regulatorSerialNo',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "Regulator S/N"
                            },
                            validationRules: [ {
                                type: "stringLength",
                                max: 100,
                                message: "Regaultor S/N cannot be longer than 100 characters."
                            }
                            ]
                               
                        },
                        {
                            dataField: 'hstDue',
                            colSpan: 1,
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                dateSerializationFormat: "yyyy-MM-dd",
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "HST Due"
                            },
                            validationRules: [ {
                                type: "required",
                                max: 100,
                                message: "HST Due is required"
                            }
                            ]
                               
                        },
                        {
                            dataField: 'overhaulDue',
                            colSpan: 1,
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                dateSerializationFormat: "yyyy-MM-dd",
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "Overhaul Due"
                            },
                               
                        },
                        {
                            dataField: 'manufactureDate',
                            colSpan: 1,
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                dateSerializationFormat: "yyyy-MM-dd",
                                readOnly: !this.props.grants.rights.editWorkorderHeader === true || this.props.selectedWo.complete
                            },
                            label : { 
                                text: "MFG Date"
                            },
                            validationRules: [ {
                                type: "required",
                                max: 100,
                                message: "MFG Date is required"
                            }
                            ]
                               
                        },
                    ]
                },
                {
                    itemType: "button",
                    visible: this.props.grants.rights.editWorkorderHeader  && !this.props.selectedWo.complete,
                    horizontalAlignment: "right",
                    colSpan: 3,
                    buttonOptions: {
                        text: "Update",
                        type: "success",
                        useSubmitBehavior: false,
                        onClick: this.submitUpdateRequest
                    }
                },
                {
                    itemType: "button",
                    visible: this.props.selectedWo.complete && this.props.grants.rights.reOpenWorkorder === true,
                    horizontalAlignment: "right",
                    colSpan: 3,
                    buttonOptions: {
                        text: "Reopen",
                        type: "danger",
                        useSubmitBehavior: false,
                        onClick: this.reOpenWorkorder
                    }
                },
            ]
        }


        return (
           <Fragment>
            <Form visible={!this.state.loading} height="100%"
            colCount={3}
            formData={this.detailsFormData} 
            items={detailsFormConfig.items}
            ref={(form) => {this.detailsForm = form}}>
           </Form> 
            <LoadIndicator visible={this.state.loading} />
          </Fragment>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      selectedWo: state.woDetail.rowData,
      grants: state.rights
    };
  }

const mapDispatchToProps = dispatch => {
    return {
        onChangingCustomer: (customerId) => dispatch(actions.setWoCustomer(customerId)),
    };
  }

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(DetailsForm));

