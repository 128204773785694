import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import {Popup as VPopup} from 'devextreme-react';

import DataGrid, {
    Column,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Editing,
    Popup,
    RequiredRule,
    FormItem

} from 'devextreme-react/ui/data-grid';
import RuleActionsGrid from '../RuleActionsGrid/RuleActionsGrid';

const getHeight = () => {
    return (window.innerHeight - 48)
}

class RulesGrid extends Component {
    state = {
    };

    onEditorPreparing = (e) =>  {
        // if (e.parentType === 'dataRow') {
            
        //     if (e.dataField === "question")
        //     e.editorName = "dxTextArea";

        //     if (e.dataField === "response")
        //     e.editorName = "dxTextArea";
        // }    
    }

    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    onEditorPreparing = (e) =>  {
      if (e.dataField === "description")
          e.editorName = "dxTextArea";

      if (e.dataField === "expression")
        e.editorName = "dxTextArea";

        if (e.dataField === "sequence")
        e.editorName = "dxNumberBox";
      }

    setDefaultNewValues = (e) => {
        e.data.active = true;
        e.data.sequence = 1;
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    hidePopup = () => {
      this.gridview.instance.refresh();
    }

    actionClickedHandler = (e) => {
      e.event.preventDefault();
      this.props.onSelectingRule(e.row.data);
      this.popup.instance.option('title', 'Actions for rule: ' + e.row.data.name);
      this.popup.instance.show();
    }
    
    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.AllRulesDataSource(this.props.user.access_token)}  
            noDataText="There are no matching queries in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'rules' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} onToolbarPreparing={this.onToolbarPreparing}
            onEditorPreparing={this.onEditorPreparing} onInitNewRow={this.setDefaultNewValues}
         >
        <Editing
            mode={'popup'} 
            allowAdding={this.props.grants.rights.manageRules}
            allowUpdating={this.props.grants.rights.manageRules}
            allowDeleting={this.props.grants.rights.manageRules} >
          <Popup title="Define Rule" showTitle={true} >
       
          </Popup>
        </Editing>
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
      
        <Column dataField={'id'} visible={false} dataType="number">
            <FormItem visible={false} />
        </Column>
     
       
        <Column dataField={'hooksId'} dataType="number" caption="Hook">
            <RequiredRule message="You must specify when the rule will be evaluated." />
            <Lookup dataSource={apis.HookDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} allowClearing={true} /> 
        </Column>
        <Column dataField={'name'} dataType="string" >
          <RequiredRule message="You must specify a name for your rule." />
        </Column>
     
        <Column dataField={'sequence'}  dataType="number">
            <RequiredRule message="Numeric value is required." />
            <FormItem editorOptions={{ showSpinButtons: true, min: 1 }} />
        </Column>
 
        
        <Column dataField={'active'}  dataType="boolean">

        </Column>
        <Column dataField={'expression'} dataType="string" >
          <RequiredRule message="You must define an expression to evaluate." />
          <FormItem colSpan="2"></FormItem>
        </Column>
        <Column dataField={'description'} dataType="string">
         <FormItem colSpan="2" editorOptions={{placeholder: 'Enter some notes describing what the rule detects.'}}></FormItem>
        </Column>
        <Column dataField={'actionCount'}  dataType="number" caption="Actions">
            <FormItem visible={false} />
        </Column> 
        <Column type="buttons" width={110}
          buttons={['edit', 'delete',
           {
            hint: 'Actions',
            icon: 'runner',
            onClick: this.actionClickedHandler,
            }]} />

    
       
       
   
        </DataGrid>
          <VPopup ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} onHiding={this.hidePopup} >
             <RuleActionsGrid></RuleActionsGrid>
          </VPopup>
            }

        </div>
        );
    }


}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      grants: state.rights
    
  };
}

const mapDispatchToProps = dispatch => {
  return {
       onSelectingRule: (rowData) => dispatch(actions.setRuleDetail(rowData)),
     
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(RulesGrid)