import React, {Component} from 'react';
import { connect } from "react-redux";
import { Form, ScrollView } from 'devextreme-react';
import * as apis from '../../utils/apis';



class EditCostingPopup extends Component {

   

    render() {

        let formConfig = {
            items : [
                {
                    itemType: "group",
                    colCount: 5,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: 'requestIdentifier',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "Request #"},
                            
                        },
                        {
                            dataField: 'partNumber',
                            colSpan: 1,                    
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Part Number"},
                        },
                        {
                            dataField: 'description',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Description"},
                                
                        },
                        {
                            dataField: 'figure',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Figure"
                            },
                                
                        },
                        {
                            dataField: 'iplNo',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "IPL No"},
                            
                        },
                       
                        
                    ]
                },
                {
                    itemType: "group",
                    colCount: 6,
                    colSpan: 3,
                    items: [      
                        {
                            dataField: 'grn',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "GRN"},
                           
                        },
                        {
                            dataField: 'poRef',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "P/O Ref"
                            },
                           
                               
                        },
                        {
                            dataField: 'accountsRef',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: false
                            },
                            label : { 
                                text: "Sage Ref"
                            },
                            validationRules: [
                                {
                                    type: "required",
                                    message: "Sage reference is required."
                                },
                                {
                                    type: "stringLength",
                                    max: 100,
                                    message: "The sage reference cannot be longer than 100 characters."
                                }
                            ]
                               
                        
                               
                        },
                        {
                            dataField: 'dateRequested',
                            colSpan: 1,                    
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly: true
                            },
                            label : { 
                                text: "Requested"},
                        },
                        {
                            dataField: 'dueDate',
                            colSpan: 1,                    
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly: true
                         
                            },
                            label : { 
                                text: "Due Date"},
                           
                        },
                       
                        {
                            dataField: 'dateClosed',
                            colSpan: 1,                    
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly: true
                            },
                            label : { 
                                text: "Closed"},
                        },
                        
                    ]
                },
                {
                    itemType: "group",
                    colCount: 5,
                    colSpan: 3,
                    items: [      
                        {
                            dataField: 'qty',
                            colSpan: 2,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Quantity"},
                           
                        },
                        {
                            dataField: 'requestedBy',
                            colSpan: 1,
                            editorOptions: {
                            
                                readOnly: true
                            },
                            label : { text: "Requested By"},
                           
                        },
                        {
                            dataField: 'partRequestPriorityId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'priority',
                                valueExpr: 'id',
                                dataSource: apis.PRPriorityDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Priority"
                            },
                               
                        },
                        {
                            dataField: 'partRequestStatusId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'text',
                                valueExpr: 'id',
                                dataSource: apis.PartRequestStatusesDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Status"
                            },
                               
                        },
                        
                    ]
                },
                {
                    itemType: "group",
                    colcount: 6,
                    colSpan: 3,
                    items: [
                        {
                            dataField: 'notes',
                            colSpan: 3,
                            editorType: "dxTextArea",
                            editorOptions: {
                                height: "150px",
                                readOnly: true
                            },
                            label: {
                                text: "Existing Notes"
                            }
        
                        },
                        
                        {
                            dataField: 'additionalNotes',
                            colSpan: 3,
                            editorType: "dxTextArea",
                            editorOptions: {
                                height: "150px",
                                readOnly: false
                            },
                            label: {
                                text: "Costing Notes"
                            }
        
                        },
                    ]
                },
               
                {
                    itemType: "group",
                    colCount: 6,
                    colSpan: 3,
                    items:[
                        {
                            itemType: "empty",
                            colSpan: 5
                        },

                        
                        {
                            itemType: "button",
                            visible: true,
                            horizontalAlignment: "right",
                            colSpan: 1,
                            buttonOptions: {
                                text: "Update Costing",
                                type: "default",
                                useSubmitBehavior: false,
                                onClick: (params) => this.props.editCosting(params,this.props.selectedPR.id,this.props.selectedPR.additionalNotes,this.props.selectedPR.accountsRef) 
                            }
                        
                       
                        },
                    ]
                },



              
               
                
               
            ]


    }

        let output = null;

       

        if(this.props.selectedPR) {
            output = 
            <ScrollView>
                <Form formData ={this.props.selectedPR} items={formConfig.items}>

                </Form>
            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        selectedPR : state.prDetail.rowData
    };
  }

export default connect(mapStateToProps,null)(EditCostingPopup)