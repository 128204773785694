import React, {Component} from 'react';
import * as apis from '../../../utils/apis';
import * as utils from '../common';
import { connect } from "react-redux";
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    Column,
    Editing,
     Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    Lookup,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Form
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';
import {Popup} from 'devextreme-react';
import QueryDetailsPopup from '../../QueryDetailsPopup/QueryDetailsPopup';
import CancelQueryPopup from '../../CancelQueryPopup/CancelQueryPopup';
import * as actions from '../../../store/actions/actions';
import { notificationTiming } from '../../../utils/appConfig';


const getHeight = () => {
    return (window.innerHeight - 98)
}

class QueriesTab extends Component {
    state = {
            formvisibility :  {
            description: false
        } 
    };

    cancelQuery = (params,id,reason) => {
      var result = params.validationGroup.validate();
      if(result.isValid) {
        let data = {
          id: id,
          response: reason
        }
  
        axios.post('api/workorderqueries/cancelquery/' + this.props.selectedWo.id , data)
        .then(response => {
           
            notify("Query cancelled successfully.","success", notificationTiming);
            this.gridview.instance.refresh();
            this.cancelpopup.instance.hide();
  
        })
        .catch(error => {
            notify("Unable to cancel query.","error", notificationTiming); 
            console.log(error);
      
        });
      }

     
    }

    resize = () => {
      this.gridview.instance.option('height',getHeight());
    }
    
      componentDidMount() {
       


        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

     onEditorPreparing = (e) =>  {

        if (e.parentType === 'dataRow') {
            
            if (e.dataField === "question")
            e.editorName = "dxTextArea";
        
           
        }        
    }

    setDefaultNewValues = (e) => {
        e.data.queryStatusId = 1;
        
    }

    prClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingQuery(e.row.data);
        this.popup.instance.show();
    }

    cancelClickedHandler = (e) => {
      e.event.preventDefault();
      this.props.onSelectingQuery(e.row.data);
      this.cancelpopup.instance.show();
    }

    isCancelIconVisible = (e) => {    
      return !e.row.isEditing && (e.row.data.userRaisedById === this.props.user.profile.sub) && (e.row.data.queryStatusId === 1) && (this.props.grants.rights.cancelOwnQuery === true);
  }


    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.WorkorderQueriesDataSource(this.props.selectedWo.id,this.props.user.access_token)}  
            noDataText="There are no queries for this W/O"
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'woqueries' }}
            height={this.state.gridHeight > 0 ? this.state.gridHeight : getHeight }
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onEditorPreparing={this.onEditorPreparing} onInitNewRow={this.setDefaultNewValues} onToolbarPreparing={this.onToolbarPreparing}
         >
         <Editing
            mode={'popup'}
            allowAdding={this.props.grants.rights.addQuery && !(this.props.selectedWo.locked === true || this.props.selectedWo.complete === true)} >
           
            <Popup title={'Query'} showTitle={true} width={utils.popupWidth()} height={utils.popupHeight()}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
             <Form colCount="1" ref={(form) => {this.gridform = form}}>  

             </Form> 
           
        </Editing>
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'queryIdentifier'} caption="Identifier">
        <FormItem visible={false} />
      
   
        </Column>
        <Column dataField={'raisedBy'} caption="Opened by">
        <FormItem visible={false} />
      
   
        </Column>
        <Column dataField={'dateOpened'} dataType="date" format="dd/MM/y">
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'closedBy'} caption="Closed by">
        <FormItem visible={false} />
        </Column>
        <Column dataField={'dateResolved'} dataType="date" format="dd/MM/y">
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'queryStatusId'} caption='Status'>
            <FormItem visible={false} />
            <RequiredRule />
            <Lookup dataSource={apis.QueryStatusDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'}  />
        </Column>
        <Column dataField={'userGroupId'} caption='Responsible Group' >
            <FormItem visible={true} />
            <RequiredRule />
            <Lookup dataSource={apis.UserGroupWithAccessDataSource(this.props.user.access_token)} displayExpr={'name'} valueExpr={'id'}  />
        </Column>
        <Column dataField={'claimedBy'} >
        <FormItem visible={false} />
        </Column>

      
       

      

       
        <Column dataField={'question'} caption='Question' visible={false} showInColumnChooser={false}>
            <FormItem editorOptions={{height: "150px"}} visible="true"></FormItem>
            <RequiredRule />
            <StringLengthRule max="2048" message="A question cannot exceed 2048 characters."/>
        </Column>
        
       
        <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
          buttons={[ {
            hint: 'View',
            icon: 'info',
            onClick: this.prClickedHandler
          },
          {
            hint: 'Cancel',
            icon: 'clear',
            onClick: this.cancelClickedHandler,
            visible: this.isCancelIconVisible,
          }
          ]} />
        </DataGrid>
         <Popup title="Query details" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} >
            <QueryDetailsPopup></QueryDetailsPopup>
         </Popup>
         <Popup title="Cancel Query" ref={(popup) => {this.cancelpopup = popup}} height={this.popupHeight} width={this.popupWidth}  >
            <CancelQueryPopup cancelQuery={(params,id,reason) => this.cancelQuery(params,id,reason)}></CancelQueryPopup>
         </Popup>
       </div>
        );
        
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      selectedWo: state.woDetail.rowData,
      grants: state.rights
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
         onSelectingQuery: (rowData) => dispatch(actions.setQueryDetail(rowData)),
       
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(QueriesTab)