import {Component} from "react";
import userManager from '../../utils/userManager';

class LoginPage extends Component {
    render() {
        userManager.signinRedirect();
        return null;
    }
}

export default LoginPage;