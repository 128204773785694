import React, {Component} from 'react';
import { ScrollView } from 'devextreme-react';
import DetailsForm from './DetailsForm/DetailsForm';

import * as utils from '../common';

class DetailsTab extends Component {
    render() {
        return (
            <ScrollView height={utils.getHeight()}>
               <DetailsForm admin={this.props.admin}></DetailsForm>
            </ScrollView>
        ) 
        
    }
}

export default DetailsTab