import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Format,
    Export
} from 'devextreme-react/ui/data-grid';



const getHeight = () => {
    return (window.innerHeight - 48)
}

class AllocationGrid extends Component {
    state = {
    };

    resize = () => {
       this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

     

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    onCellPrepared = (e) => {
        if(e.rowType === "data" && e.column.dataField === "woNumber") {
            if(e.data.hoursRequired === 0)
            {
                return;
            }

            if(e.data.actual === 0) {
                e.cellElement.style.color = "darkorange";
                return;
            }
            else {
                e.cellElement.style.color = e.data.diff > 0 ? "mediumseagreen" : "red";
            }

        }
    }

    render() {
        return  (
            <DataGrid 
            dataSource={apis.AllocationDataSource(this.props.user.access_token)}  
            noDataText="There are no matching allocation in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'woAllocation' }}
            height={ getHeight }
            width="100%" ref={(gv) => {this.gridview = gv}}
            onCellPrepared={this.onCellPrepared} 
            onToolbarPreparing={this.onToolbarPreparing} 
         >
         <Export enabled={this.props.grants.rights.exportData} fileName="woallocation" />
         <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.allocateWO}
            // allowDeleting={true}            
   >
             <Popup title={'Workorder Allocation'} showTitle={true} width={700} height={345}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
   
        </Editing>
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
       

        
        <Column dataField={'id'} visible={false} showInColumnChooser={false}>
            <FormItem visible={false} disabled={true} />
        </Column>
        <Column dataField={'woNumber'} caption="W/O#">
            <FormItem visible={true} disabled={true} />
        </Column>
        <Column dataField={'hoursRequired'} caption="Hours Reqd" dataType="number" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
         </Column>
         <Column dataField={'acceptedPrice'} dataType="number" caption="Accepted Price" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
            <Format
              type="fixedPoint"
              precision={2}
          />
         </Column>
         <Column dataField={'currency'} caption="Currency" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
         </Column>
        <Column dataField={'entityId'} caption="Entity" visible={false} showInColumnChooser={true}>
         
            <Lookup dataSource={apis.EntityDataSource(this.props.user.access_token)} displayExpr={'shortDescription'} valueExpr={'id'} />
            <FormItem visible={false}></FormItem>
        </Column>

        
        <Column dataField={'workOrderPriorityId'} caption="Priority" visible={false} showInColumnChooser={true}>
            <Lookup dataSource={apis.WOPriorityDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} />
            <RequiredRule />
        </Column>
        <Column dataField={'userId'} caption="Allocated To">
             <Lookup dataSource={apis.AllocUsersDataSource(this.props.user.access_token)} displayExpr={'userName'} valueExpr={'id'} /> 
             <RequiredRule />
        </Column>
        <Column dataField={'workOrderStatusId'} caption="Status">
            <FormItem visible={false} />
            <Lookup dataSource={apis.WorkorderStatusDataSource(this.props.user.access_token)} displayExpr={'status'} valueExpr={'id'} />
        </Column>
        <Column dataField={'dueDate'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
            <FormItem visible={false} />
        </Column>
     
        <Column dataField={'customerId'} caption="Customer Name">
            <FormItem visible={false} />
            <Lookup dataSource={apis.CustomersDataSource(this.props.user.access_token)} displayExpr={'customerName'} valueExpr={'id'} />
        </Column>
        <Column dataField={'customerReference'} visible={false} showInColumnChooser={true}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'allocatedBy'} visible={false} showInColumnChooser={true}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'partNumber'}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'partDescription'}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'serialNo'} visible={false} showInColumnChooser={true}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'quantity'} visible={false} showInColumnChooser={true}>
            <FormItem visible={false} />
        </Column>
       <Column dataField={'actual'} dataType="number" caption="Elapsed" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'diff'} dataType="number" caption="Diff" visible={false} showInColumnChooser={true} >
            <FormItem visible={false}></FormItem>
            <Format
              type="fixedPoint"
              precision={2}
          />
        </Column>
        </DataGrid>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        grants: state.rights
    };
  }


export default connect(mapStateToProps, null)(AllocationGrid)