import React, {Component} from 'react';
import { ScrollView } from 'devextreme-react';
import CustomerForm from './CustomerForm/CustomerForm';

import * as utils from '../common';

class CustomerTab extends Component {
    render() {
        return (
            <ScrollView height={utils.getHeight()}>
               <CustomerForm admin={this.props.admin}></CustomerForm>
            </ScrollView>
        ) 
        
    }
}

export default CustomerTab