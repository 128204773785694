import React, {Component} from 'react';
import { ScrollView } from 'devextreme-react';
import TechDataForm from './TechDataForm/TechDataForm';

import * as utils from '../common';

class TechDataTab extends Component {
    render() {
        return (
            <ScrollView height={utils.getHeight()}>
               <TechDataForm admin={this.props.admin}></TechDataForm>
            </ScrollView>
        ) 
        
    }
}

export default TechDataTab