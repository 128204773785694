import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions'
import PartRequestDetailsPopup from './PartRequestDetailPopup/PartRequestDetailPopup';
import {Popup} from 'devextreme-react';

import DataGrid, {
    Column,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Export

} from 'devextreme-react/ui/data-grid';

const getHeight = () => {
    return (window.innerHeight - 48)
}

class PartRequestEnquiry extends Component {
    state = {
    };

    

    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

     
      infoClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.popup.instance.show();
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }
    
    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.PartRequestDataSource(this.props.user.access_token)}  
            noDataText="There are no matching part requests in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'prenquiry' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} onToolbarPreparing={this.onToolbarPreparing}
         >
        <Export enabled={this.props.grants.rights.exportData} fileName="partrequestenquiry" />
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        <Column dataField={'requestIdentifier'} dataType="string" >
            
        </Column>
        <Column dataField={'id'} visible={false} dataType="number">
            
        </Column>
     
       
        <Column dataField={'partNumber'} dataType="string">
      
       
        </Column>
        <Column dataField={'description'} dataType="string" >
         
      
        </Column>
        <Column dataField={'figure'} dataType="string" visible={false} showInColumnChooser={true}>
   
        </Column>
        <Column dataField={'iplNo'} dataType="string" visible={false} showInColumnChooser={true}>

        </Column>
    
        <Column dataField={'partRequestPriorityId'} caption="Priority" dataType="number">
            <Lookup dataSource={apis.PRPriorityDataSource(this.props.user.access_token)} displayExpr={'priority'} valueExpr={'id'} />
        </Column>

        

        <Column dataField={'grn'} caption ="GRN"dataType="string" visible={false} showInColumnChooser={true}>
        </Column>

        <Column dataField={'poRef'} dataType="string" visible={false} showInColumnChooser={true} >

        </Column>

        <Column dataField={'accountsRef'} caption="Sage Ref" dataType="string" visible={false} showInColumnChooser={true} >

        </Column>
            
        <Column dataField={'qty'} dataType="number" visible={false} showInColumnChooser={true} >

        </Column>
        
        <Column dataField={'partRequestStatusId'} caption="Status" dataType="number" >
            <Lookup dataSource={apis.PartRequestStatusesDataSource(this.props.user.access_token)} displayExpr={'text'} valueExpr={'id'} />
        </Column>
        <Column dataField={'notes'} caption='Note' visible={false} showInColumnChooser={false} dataType="string">
           
        </Column>
        <Column dataField={'requestedBy'} dataType="string" visible={false} showInColumnChooser={true}>

        </Column>  
        <Column dataField={'dueDate'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
        </Column>  
        <Column dataField={'dateRequested'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
        </Column>
        <Column dataField={'dateClosed'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true} >

        </Column> 
        <Column dataField={'costingComplete'} caption="Costed" dataType="boolean" >
        
        </Column>
        <Column dataField={'costing'} caption="Costing" dataType="boolean" visible={false}>
        
        </Column>
        <Column dataField={'quoted'} caption="Quoted" dataType="boolean" visible={false}>
            
    </Column>
        <Column dataField={'ordered'} caption="Ordered" dataType="boolean" >
            
            </Column>
    
        <Column dataField={'allocated'} caption="Allocated" dataType="boolean" >
            
        </Column>
        <Column dataField={'used'} caption="Used" dataType="boolean" >
            
            </Column>
        <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
          buttons={[ {
            hint: 'Details',
            icon: 'info',
            onClick: this.infoClickedHandler
          }]} />
   
        </DataGrid>
            <Popup title="Part request details" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <PartRequestDetailsPopup></PartRequestDetailsPopup>
            </Popup>
      

        </div>
        );
    }


}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      grants: state.rights
  };
}

const mapDispatchToProps = dispatch => {
  return {
       onSelectingPartRequest: (rowData) => dispatch(actions.setPRDetail(rowData)),
     
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(PartRequestEnquiry)