import React, {Fragment} from 'react';
import './LinkCell.css';
import axios from 'axios';

const linkcell = (cellData) => {
    return (
        <Fragment>
            <a className='msl-doclink' onClick={(e) => getDocLink(cellData.data.id,e)}>{cellData.value}</a> 
        </Fragment>
    );

}

const getDocLink = (docId,e) => {
 e.preventDefault();

 // code here to fetch link and redirect to it.
//  let bodyFormData = new FormData();
//  bodyFormData.set('id',docId);

 let dlRequest = {
     id: docId
 }

 axios.post('api/downloadlink/getdownloadlink',dlRequest)
 .then(response => {
     //window.location.href = (response.data);
     window.open(response.data, '_blank');
 })
 .catch(error => {
     console.log(error)
 });
}

export default linkcell;