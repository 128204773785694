import React, {Component} from 'react';
import { connect } from "react-redux";
import Tabs from 'devextreme-react/tabs';
import DetailsTab from '../../containers/WorkorderTabs/DetailsTab/DetailsTab';
import CustomerTab from '../../containers/WorkorderTabs/CustomerTab/CustomerTab';
import TechDataTab from '../../containers/WorkorderTabs/TechDataTab/TechDataTab';
import LabourTab from '../../containers/WorkorderTabs/LabourTab/LabourTab';
import FilesTab from '../../containers/WorkorderTabs/FilesTab/FilesTab';
import PartsTab from '../../containers/WorkorderTabs/PartsTab/PartsTab';
import QueriesTab from '../../containers/WorkorderTabs/QueriesTab/QueriesTab';
import NotesTab from '../../containers/WorkorderTabs/NotesTab/NotesTab';
import QuotesTab from '../../containers/WorkorderTabs/QuotesTab/QuotesTab';
import * as actions from '../../store/actions/actions';

import './WorkorderTabs.css';

class WorkorderTabs extends Component {
    
    state =  {
            selectedIndex: null
    }

    onTabsSelectionChanged = (args) =>  {
        if(args.name === 'selectedIndex') {
          this.setState({
            selectedIndex: args.value
          });
        }
      }
   
    componentDidMount() {
      if(this.props.tab !== null) {
        this.setState({selectedIndex : this.props.tab});
      }
    }

    componentWillUnmount() {
      this.props.onSettingWorkorder(null);
    }
    
// example code for Sarah, using a test right.

 woTabs = [
    { text: 'Details' },
    { text: 'Customer' },
    { text: 'Quotes'},
    { text: 'Tech Data' },
    { text: 'Labour Log' },
    { text: 'Files' },
    { text: 'Parts' },
    { text: 'Queries' },
    { text: 'Notes' },
];

    render() {
        

        let tab = null;
        
        switch(this.state.selectedIndex) {
            case 0:
                tab = <DetailsTab admin={this.props.admin}></DetailsTab>;
                break;
            case 1:
                tab = <CustomerTab admin={this.props.admin}></CustomerTab>;
                break;
            case 2:
                tab = <QuotesTab admin={this.props.admin}></QuotesTab>;
                break;
            case 3:
                tab = <TechDataTab admin={this.props.admin} ></TechDataTab>;
            break;
            case 4:
                tab = <LabourTab admin={this.props.admin}></LabourTab>;
                break;
            case 5:
                tab = <FilesTab admin={this.props.admin}></FilesTab>;
                break;
            case 6:
                tab = <PartsTab admin={this.props.admin}></PartsTab>;
                break;
            case 7:
                tab = <QueriesTab admin={this.props.admin}></QueriesTab>;
                break;
            case 8:
                tab = <NotesTab admin={this.props.admin} ></NotesTab>;
                break;
            default:
                tab = null;
        }


        return (
      <div id={'woTabs'}>
        <Tabs dataSource={this.woTabs} selectedIndex={this.state.selectedIndex} onOptionChanged={this.onTabsSelectionChanged} height='50px'/> 
        {tab}
      </div>
        );    
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      selectedWo: state.woDetail.rowData,
      grants: state.rights
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
        onSettingWorkorder: (rowData) => dispatch(actions.setWoDetail(rowData)),
    };
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(WorkorderTabs)