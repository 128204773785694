import React, {Component} from 'react';
import {Popup} from 'devextreme-react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import {animOff} from '../../utils/appConfig';

import DataGrid, {
    Column,
    Editing,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Popup as GridPopup,
    Lookup
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';

class AddressPopup extends Component {
    setDefaultNewValues = (e) => {
        e.data.active = true;
        e.data.default = false;
      }
    
    render() {

        return (
            <Popup visible={this.props.popupVisible}
             title={this.props.rowData.customerName + " addresses"}
             closeOnOutsideClick={true}
             onHidden={this.props.hidingPopupHandler}
             animation={animOff}
             >
            <DataGrid 
        dataSource={apis.AddressDataSource(this.props.rowData.id, this.props.user.access_token)}  
        noDataText="There are no addresses defined for this customer."
        rowAlternationEnabled={true}  
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}  
        allowColumnReordering={true}
        allowColumnResizing={true}
        stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'custaddr' }}
        height="100%"
        width="100%"
        onInitNewRow={this.setDefaultNewValues} 
        >
         <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.adminCustomerAddresses}
            allowDeleting={this.props.grants.rights.adminCustomerAddresses}
            allowAdding={this.props.grants.rights.adminCustomerAddresses} >
            <GridPopup title="Address Details" showTitle={true} />
         </Editing>
        <Scrolling mode="virtual" />
        <Column dataField={'id'} visible={false} >
            <FormItem visible={false} />
        </Column>
        <Column dataField={'addressLine1'} caption="Address L1">
        <StringLengthRule max="255" message="Address line 1 cannot exceed 255 characters."/>
            <RequiredRule />
        </Column>
        <Column dataField={'addressLine2'} caption="Address L2">
            <StringLengthRule max="255" message="Address line 2 cannot exceed 255 characters."/>
        </Column>
        <Column dataField={'addressLine3'} caption="Address L3">
            <StringLengthRule max="255" message="Address line 3 cannot exceed 255 characters."/>
        </Column>
        <Column dataField={'addressLine4'} caption="Address L4">
            <StringLengthRule max="255" message="Address line 4 cannot exceed 255 characters."/>
        </Column>

        <Column dataField={'postalZipCode'} caption="Post Code">
            <RequiredRule />
            <StringLengthRule max="20" message="Postal / Zip code cannot exceed 20 characters."/>
        </Column>
        <Column dataField={'addressTypeId'} caption="Address Type">
            <Lookup dataSource={apis.AddressTypeDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} />
            <RequiredRule />
        </Column>
        <Column dataField={'comment'} caption="Comment" />
        <Column dataField={'countryId'} caption="Country">
            <Lookup dataSource={apis.CountriesDataSource(this.props.user.access_token)} displayExpr={'text'} valueExpr={'id'} />
            <RequiredRule />
        </Column>
       
        <Column dataField={'default'} dataType={'boolean'} />
        <Column dataField={'active'} dataType={'boolean'} />
       
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        </DataGrid>
           
            </Popup>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        grants: state.rights,
        rowData: state.customer.rowData
    };
}

export default connect(mapStateToProps)(AddressPopup);