import React, {Component} from 'react';
import { connect } from "react-redux";
import { BarGauge, Label, Export, Title, Font, Legend } from 'devextreme-react/bar-gauge';
import { Form, LoadIndicator  } from 'devextreme-react';
import './prodlabourform.css';
import axios from 'axios';

//const values = [180, 225 ];

const format = {
    type: 'fixedPoint',
    precision: 2
  };

  const getHeight = () => {
    return (window.innerHeight - 178);
}

const getWidth = () => {
  return (window.innerWidth - 150) / 2;
}


class ProdLabourForm extends Component {

  state =  {
    loading: false,
    values: []
}


  detailsFormData = {
    woNumber: 25,
    woStatus: null,
    timeUsed: null,
    targetTime: null,
    allocatedStaffMember: null,
   
}



    customizeText = (e) => {
       
        return `${e.valueText} Hours`;
      }

      legendText = (e) => {
       
        if(e.item.index === 1) {
            return "Target"
        }
        else
        {
            return "Actual"
        }
        
      }

      getMax = () => {
      //  console.log(Math.max(...this.detailsFormData.values));
          var max = Math.max(...this.state.values);
          if(max <= 0) {
            return 1;
          }
          else
          {
            return max;
          }
      }
      resize = () => {
      this.gauge.instance.option('size',{height: getHeight(), width: getWidth()});
     
      
       }

       componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      componentDidMount() {
        window.addEventListener('resize', this.resize);
        axios.get('api/labour/' + this.props.selectedWo.id)
        .then(response => {
            this.detailsFormData = response.data;
            
            this.setState({ loading: false, values: response.data.values });
            console.log(this.detailsFormData);
            
        });
        if(this.state.loading === false) {
         this.gauge.instance.option('size',{height: getHeight()});
        }
       }

       getPallete = () => {
           if(this.state.values[0] > this.state.values[1]) {
               return ['#9c27b0', '#cc0000']
           }
           else {
               return ['#9c27b0', '#00cc00']
           }
       }

    render() {
      let detailsFormConfig = {
        items: [
          {
            dataField: 'woNumber',
            colSpan: 1,
            editorType: "dxNumberBox",
            editorOptions: {
                showSpinButtons: true,
                showClearButton: true,
                readOnly: true
            },
            label : { text: "W/O #"},
          },
          {
            dataField: 'woStatus',
            colSpan: 1,
            editorType: "dxTextBox",
            editorOptions: {
                readOnly: true
            },
            label : { text: "W/O Status"},
          },
          {
            dataField: 'timeUsed',
            colSpan: 1,
            editorType: "dxTextBox",
            editorOptions: {
                readOnly: true
            },
            label : { text: "Time Used"},
          },
          {
            dataField: 'targetTime',
            colSpan: 1,
            editorType: "dxTextBox",
            editorOptions: {
                readOnly: true
            },
            label : { text: "Target Time"},
          },
          {
            dataField: 'allocatedStaffMember',
            colSpan: 1,
            editorType: "dxTextBox",
            editorOptions: {
                readOnly: true
            },
            label : { text: "Allocated"},
          },


      ]
      }

        return <div id="wrapper">
          <div id="left">
          <LoadIndicator visible={this.state.loading} />
          {this.state.loading ? null :
          <BarGauge 
        id="gauge"
        startValue={0}
        endValue={this.getMax()}
        palette={this.getPallete()}
        values={this.state.values}
         ref={(gv) => {this.gauge = gv}} 
       
      >
        <Label indent={30} format={format} customizeText={this.customizeText} />
        <Export enabled={false} />
        <Title text={"Man Hours - Expected vs Actual"}>
          <Font size={28} />
        </Title>
        <Legend visible={true} customizeText={this.legendText} verticalAlignment="bottom" horizontalAlignment="center" />
      </BarGauge>}
      </div>
      <div id="right">
        <Form visible={!this.state.loading} height="100%" 
        colCount={1}
        formData={this.detailsFormData} 
        items={detailsFormConfig.items} 
        ref={(form) => {this.detailsForm = form}}>
          
       </Form> 
       </div>
      
       </div>
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      selectedWo: state.booking.woToInteract,
      grants: state.rights
    };
  }
  export default connect(mapStateToProps)(ProdLabourForm)