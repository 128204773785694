import React, {Component} from 'react';
import { ScrollView } from 'devextreme-react';

import ProductionWODetails from '../ProductionWODetails/ProductionWODetails';

export const getHeight = () => {
    return (window.innerHeight - 185)
  }

class ProductionDetailsTabHolder extends Component {
    render() {
        return (
            <ScrollView height={getHeight()}>
               <ProductionWODetails></ProductionWODetails>
            </ScrollView>
        ) 
        
    }
}

export default ProductionDetailsTabHolder