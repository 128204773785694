import React, {Component} from 'react'
import { connect } from "react-redux";
import { Form, ScrollView } from 'devextreme-react';
import * as apis from '../../../../utils/apis'

class BookToWorkorderPopup extends Component {
      
    render() {
        let formConfig = {
            items: [
                {
                    itemType: "group",
                    colCount: 3,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: 'woNumber',
                            colSpan: 1,
                        
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "W/O #"},
                           
                            
                        },                        
                        {
                            dataField: 'workOrderStatusId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'status',
                                valueExpr: 'id',
                                dataSource: apis.WorkorderStatusDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Status"
                            },
                        },
                       
                       
                        {
                            dataField: 'entityId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'shortDescription',
                                valueExpr: 'id',
                                dataSource: apis.EntityDataSource(this.props.user.access_token),
                                readOnly: true,
                            },
                            label : { 
                                text: "Entity"},
                                validationRules: [{
                                type: "required",
                                message: "Entity is Required."
                            },
                            ]
                        },
                        
                    ]
                },
                {
                    itemType: "group",
                    colCount: 4,
                    colSpan: 3,
                    items: [
                        {
                            dataField: 'partNumber',
                            colSpan: 1,
                            editorOptions: {
                               
                                readOnly: true
                            },
                            label : { 
                            text: "Part No"},
                           
                            
                        },
                        {
                            dataField: 'partDescription',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Part Description"},
                                validationRules: [
                                    {
                                        type: "required",
                                        message: "Part Description is required."
                                    },
                                    {
                                        type: "stringLength",
                                        max: 100,
                                        message: "Part description cannot be longer than 100 characters."
                                    }
                            ]
                        },
                       
                        {
                            dataField: 'serialNo',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "Serial No"},
                            validationRules: [
                                {
                                    type: "stringLength",
                                    max: 50,
                                    message: "Serial number cannot be longer than 50 characters."
                                },
                            
                            ]
                        },
                        {
                            dataField: 'quantity',
                            colSpan: 1,
                            editorType: "dxNumberBox",
                            editorOptions: {
                                showSpinButtons: true,
                                showClearButton: true,
                                readOnly: true
                            },
                            label : { 
                                text: "Qty"
                            },
                           
                        },
                    ]
                },
               {
                itemType: "group",
                colCount: 4,
                colSpan: 3,
                items : [
                    {
                        dataField: 'customerId',
                        colSpan: 1,
                        editorType: "dxSelectBox",
                        editorOptions: {
                            displayExpr: 'customerName',
                            valueExpr: 'id',
                            searchExpr: "customerName",
                            searchEnabled: true,
                            dataSource: apis.CustomersDataSource(this.props.user.access_token),
                            readOnly: true
                        },
                        label : { 
                            text: "Customer"},
                            validationRules: [{
                            type: "required",
                            message: "Customer is Required."
                        },
                        ]
                    },
                    {
                        dataField: 'customerReference',
                        colSpan: 1,
                        editorType: "dxTextBox",
                        editorOptions: {
                            readOnly: true
                        },
                        label : {
                        text: "Customer Ref"},
                        validationRules: [{
                            type: "required",
                            message: "Customer Reference is required."
                        },
                        {
                            type: "stringLength",
                            max: 100,
                            message: "Customer reference cannot be longer than 100 characters."
                        }
                        ]
                    },
                    {
                        dataField: 'dateReceived',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            readOnly: true
                        },
                        label : { 
                            text: "Date Received"
                        },
                        validationRules: [{
                            type: "required",
                            message: "Date Received is Required."
                        },
                        ]
                           
                    },
                    {
                        dataField: 'dueDate',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            readOnly: true
                        },
                        label : { 
                            text: "Due Date"
                        },
                           
                    },
                   
                ]
               },
               {
                    itemType: "group",
                    colCount: 2,
                    colSpan: 3,
                    items: [
                        {
                            dataField: 'warranty',
                            colSpan: 1,
                            editorType: "dxCheckBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : {
                            text: "Warranty"
                            },
                        },
                        {
                            dataField: 'aog',
                            colSpan: 1,
                            editorType: "dxCheckBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : {
                            text: "AOG"},
                           
                        },
                      
                       
                    ]
               },
              
               {
                itemType: "button",
                visible: true,
                horizontalAlignment: "right",
                colSpan: 1,
                buttonOptions: {
                    text: "Book on to W/O",
                    type: "default",
                    useSubmitBehavior: false,
                    onClick: () => this.props.submitBookOnRequest(this.props.woToBookTo)
                }
            },
                
               
               
                
              
            ]
        }


        let output = null;

        if(this.props.woToBookTo) {
         //   if(!this.props.woBookedOnTo)
            output = 
            <ScrollView>
                <Form formData ={this.props.woToBookTo} items={formConfig.items}>
                    
                </Form>
            </ScrollView>

            // if(this.props.woBookedOnTo)
            // output = <div>
            //     <p>You are currently booked on to workorder {this.props.woBookedOnTo.woNumber} please book off that first.</p>
            // </div>
        }


        return  (<div style={{height: '100%'}}>
                { output }
                </div>)
        }
}


const mapStateToProps = (state) => {
    return {
      woToBookTo : state.booking.rowData,
      woBookedOnTo: state.booking.bookedOnToWo,
      user: state.oidc.user,
    };
  }

export default connect(mapStateToProps, null)(BookToWorkorderPopup)