import React, {Component} from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import PieChart, {
    Series,
    Label,
    Connector,
    Size,
    Legend
  } from 'devextreme-react/pie-chart';


  const getHeight = () => {
    return (window.innerHeight - 48)
}


class WODash extends Component {
    

    state = {
        wostatus : []
    };
   
    resize = () => {
        this.chart.instance.option('size',{height: getHeight()});
       }


      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

       componentDidMount() {
        window.addEventListener('resize', this.resize);
             axios.get('api/charts/GetOpenWorkorderStats')
         .then(response => {
             this.setState({wostatus: response.data});
         });
         this.chart.instance.option('size',{height: getHeight()});
       }
       
      
    
    render() {
        return  (
            <div className="chartContainer">
             <PieChart
        id={'pie'}
        dataSource={this.state.wostatus}
        palette={'Material'}
        title={'Open Workorders'}
        onPointClick={this.pointClickHandler}
        onLegendClick={this.legendClickHandler} ref={(gv) => {this.chart = gv}} 
      >
        <Series
          argumentField={'status'}
          valueField={'count'}
        >
       
          <Label visible={true}>
            <Connector visible={true} width={1} />
          </Label>
        </Series>

        <Size width={'100%'} />
        {/* <Export enabled={true} /> */}
        <Legend
          verticalAlignment={'bottom'}
          horizontalAlignment={'center'}
          itemTextPosition={'right'}
        />
      </PieChart>

        </div>
        );
    }


}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
  };
}

const mapDispatchToProps = dispatch => {
  return {
      
     
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(WODash)