import { combineReducers } from 'redux';
import { reducer as oidcReducer } from 'redux-oidc';
import  customerReducer  from '../reducers/customerReducer';
import workorderDetailReducer from './workorderDetailReducer';
import partRequestReducer from './partRequestReducer';
import noteReducer from './noteReducer';
import bookingReducer from './bookingReducer';
import queryReducer from './queryReducer';
import rightsReducer from './rightsReducer';
import ruleReducer from './ruleReducer';
import actionReducer from './actionReducer';

const reducer = combineReducers(
    {
        oidc: oidcReducer,
        customer: customerReducer,
        woDetail: workorderDetailReducer,
        prDetail: partRequestReducer,
        notes: noteReducer,
        booking: bookingReducer,
        queryDetail: queryReducer,
        rights: rightsReducer,
        rules: ruleReducer,
        action: actionReducer
    }
);

export default reducer;