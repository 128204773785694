import React, {Component, Fragment} from 'react';
import { Form, LoadIndicator  } from 'devextreme-react';
import axios from 'axios';

import { connect } from "react-redux";

class ProductionCustomer extends Component {

    state =  {
        loading: true,
        error: false, 
        selectedWo: null
      
    }

    componentDidMount() {

        if(this.props.woBookedOnTo) {
            if(this.state.selectedWo !== this.props.woBookedOnTo.id) {
                axios.get('api/customerheader/' + this.props.woBookedOnTo.id)
                .then(response => {
                this.customerFormData = response.data;
                    this.setState({ loading: false, selectedWo: this.props.woBookedOnTo.id });
                });
            }
           
        }
       
    } 

    componentDidUpdate() {
        if(this.props.woBookedOnTo) {
            if(this.state.selectedWo !== this.props.woBookedOnTo.id) {
                axios.get('api/customerheader/' + this.props.woBookedOnTo.id)
                .then(response => {
                this.customerFormData = response.data;
                    this.setState({ loading: false, selectedWo: this.props.woBookedOnTo.id });
                });
            
            }
           
        }
    }
    

   

    customerFormData = {
        customerId: null,
        customerRequests: null,
        deliveryAddressId: null,
        shippingMethod: null,
        shippingReference: null,
        customerContactId: null,
        customerEmail: null,
        customerPhone: null,
    }

    render() {

        let customerFormConfig = {
            validationGroup: 'cust',
            items: [
                {
                    itemType: "group",
                    colCount: 2,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: "customerRequests",
                            colSpan: 2,
                            editorType: "dxTextArea",
                           
                            editorOptions: {
                                height: "200px",
                                readOnly: true
                        
                            },
                            label : { text: "Customer Requests"},
                            
                            
                        },
                       
                        
                     
                    
                        {
                            dataField: 'shippingMethod',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: 
                            {
                                readOnly: !this.props.admin,
                            },
                            label : { 
                                text: "Shipping Method"
                            },
                                
                        },
                      
                        {
                           
                            dataField: 'shippingReference',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: 
                            {
                                readOnly: !this.props.admin,
                            },
                            
                            label : { 
                                text: "Shipping Reference"
                            },
                                
                        },
                       
                        
                        
                    ]
                },

               
            ]
        }


        return (
            <Fragment>
             <Form visible={!this.state.loading} height="100%"
             colCount={3}
             formData={this.customerFormData}  
             items={customerFormConfig.items}
             ref={(form) => {this.customerForm = form}}>
            </Form> 
             <LoadIndicator visible={this.state.loading} />
           </Fragment>
             
         );
    }
}

const mapStateToProps = (state) => {
    return {
      //  user: state.oidc.user,
      woBookedOnTo: state.booking.woToInteract,
    };
  }


export default connect(mapStateToProps)(ProductionCustomer)