import React, {Component} from 'react';
import { connect } from "react-redux";
import Tabs from 'devextreme-react/tabs';
import ProductionQuotes from '../ProductionQuotes/ProductionQuotes';
import ProductionFiles from '../ProductionFiles/ProductionFiles';
import PartRequests from '../PartRequests/PartRequests';
import ProductionQueries from '../ProductionQueries/ProductionQueries';
import ProductionNotes from '../ProductionNotes/ProductionNotes';
import ProductionDetailsTabHolder from '../ProductionDetailsTabHolder/ProductionDetailsTabHolder';
import ProductionCustomerHolder from '../ProductionCustomerHolder/ProductionCustomerHolder';
import ProductionTechDataHolder from '../ProductionTechDataHolder/ProductionTechDataHolder';
import ProductionLabour from '../ProductionLabour/ProductionLabour';


//import './WorkorderTabs.css';

const woTabs = [
    { text: 'Details' },
    { text: 'Customer' },
    { text: 'Quotes'},
    { text: 'Tech Data' },
    { text: 'Labour Log' },
    { text: 'Files' },
    { text: 'Parts' },
    { text: 'Queries' },
    { text: 'Notes' },
];


class ProductionDetailsTab extends Component {
    
    state =  {
            selectedIndex: null
    }

    onTabsSelectionChanged = (args) =>  {
        if(args.name === 'selectedIndex') {
          this.setState({
            selectedIndex: args.value
          });
        }
      }
   
    componentDidMount() {
      if(this.props.tab !== null) {
        this.setState({selectedIndex : this.props.tab});
      }
    }
 
    render() {
        

        let tab = null;
        
        switch(this.state.selectedIndex) {
            case 0:
                tab =   <ProductionDetailsTabHolder></ProductionDetailsTabHolder>
                break;
            case 1:
                tab =   <ProductionCustomerHolder></ProductionCustomerHolder>
                break;
            case 2:
                tab = <ProductionQuotes></ProductionQuotes>;
                break;
            case 3:
                tab = <ProductionTechDataHolder></ProductionTechDataHolder>
                break;
            case 4:
                tab = <ProductionLabour></ProductionLabour>;
                break; 
            case 5:
                tab = <ProductionFiles></ProductionFiles>;
                break;
            case 6:
                tab = <PartRequests></PartRequests>;
                break;
            case 7:
                tab = <ProductionQueries></ProductionQueries>
                break;
            case 8:
                tab = <ProductionNotes></ProductionNotes>;
                break; 
            default:
                tab = null;
        }


        return (
      <div id={'woTabs'}>
        <Tabs dataSource={woTabs} selectedIndex={this.state.selectedIndex} onOptionChanged={this.onTabsSelectionChanged} height='50px'/> 
        {tab}
      </div>
        );    
    }
}

const mapStateToProps = (state) => {
    return {
      //  user: state.oidc.user,
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
    };
  }
 
export default connect(mapStateToProps, mapDispatchToProps)(ProductionDetailsTab)