import * as actionTypes from './actionTypes';

export const showAddressPopup = (e) => {
    console.log(e.itemData.rowData);
    return {
        type: actionTypes.SHOW_ADDRESS_POPUP,
        rowData: e.itemData.rowData
    } 
}

export const hideAddressPopup = () => {
    return {
        type: actionTypes.HIDE_ADDRESS_POPUP
    }
}

export const showContactsPopup = (e) => {
    console.log(e.itemData.rowData);
    return {
        type: actionTypes.SHOW_CONTACTS_POPUP,
        rowData: e.itemData.rowData
    } 
}

export const hideContactsPopup = () => {
    return {
        type: actionTypes.HIDE_CONTACTS_POPUP
    }
}

export const setWoDetail = (rowData) => {
    return {
        type: actionTypes.SET_WO_DETAIL,
        rowData: rowData
    } 
}

export const setRuleDetail = (rowData) => {
    return {
        type: actionTypes.SET_RULE_DETAIL,
        rowData: rowData
    }
}

export const setActionDetail = (rowData) => {
    return {
        type: actionTypes.SET_ACTION_DETAIL,
        rowData: rowData
    }
}

export const setWoCustomer = (customerId) => {
    return {
        type: actionTypes.SET_WO_CUSTOMER,
        customerId: customerId
    }
}

export const setPRDetail = (rowData) => {
    return {
        type: actionTypes.SET_PR_DETAIL,
        rowData: rowData
    }
}

export const setQueryDetail = (rowData) => {
    return {
        type: actionTypes.SET_QUERY_DETAIL,
        rowData: rowData
    }
}

export const setNoteDetail = (rowData) => {
    return {
        type: actionTypes.SET_NOTE_DETAIL,
        rowData: rowData
    }
}

export const setFullFlag = (rowData) => {
    return {
        type: actionTypes.SET_FULL_FLAG,
        rowData: rowData
    }
}

export const setUserRights = (rights) => {
    return {
        type: actionTypes.SET_USER_RIGHTS,
        rights: rights
    }
}

export const setBooking = (rowData)=> {
    return {
        type: actionTypes.SET_BOOKING,
        rowData: rowData
    }
}

export const setInteract = (rowData)=> {
    return {
        type: actionTypes.SET_INTERACT,
        rowData: rowData
    }
}

export const bookOnToWo = (rowData) => {
    return {
        type: actionTypes.BOOKON_TO_WO,
        rowData: rowData
    }
}

export const bookToOther = () => {
    return {
        type: actionTypes.BOOK_TO_OTHER
    }
}

export const bookOffOther = () => {
    return {
        type: actionTypes.BOOK_OFF_OTHER
    }
}

export const bookOffAll = () => {
    return {
        type: actionTypes.BOOK_OFF_ALL
    }
}

export const setBookings = (bookings) => {
    return {
        type: actionTypes.SET_BOOKINGS,
        bookings: bookings
    }
}


export const bookOffOrder = (woId) => {
    return {
        type: actionTypes.BOOK_OFF_ORDER,
        woId: woId
    }
}

export const bookToMulti = (orders) => {
    return {
        type: actionTypes.BOOK_TO_MULTI,
        orders: orders
    }
}
