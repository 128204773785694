import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions'
import PartRequestDetailsPopup from '../PartRequestEnquiry/PartRequestDetailPopup/PartRequestDetailPopup';
import PartRequestClaimPopup from '../PartRequestClaimPopup/PartRequestClaimPopup';
import ReleasePartRequestClaimPopup from '../ReleasePartRequestClaimPopup/ReleasePartRequestClaimPopup';
import OrderPartPopup from '../OrderPartPopup/OrderPartPopup';
import {Popup} from 'devextreme-react';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import DataGrid, {
    Column,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    Export

} from 'devextreme-react/ui/data-grid';
import PartAllocationPopup from '../PartAllocationPopup/PartAllocationPopup';
import PartCostingPopup from '../PartCostingPopup/PartCostingPopup';
import EditCostingPopup from '../EditCostingPopup/EditCostingPopup';
import { confirm} from 'devextreme/ui/dialog';
import {notificationTiming} from '../../utils/appConfig';
const getHeight = () => {
    return (window.innerHeight - 48)
}

class PartWorkflow extends Component {
    state = {
    };

    

    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

     
      infoClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.popup.instance.show();
      }

      costingClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.costingpopup.instance.show();
      }

      editCostingClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.editcostingpopup.instance.show();
      }

      validToClaim = (e) => {
        return !e.row.isEditing && (e.row.data.claimedBy === null) 
      }

      validToRelease = (e) => {
        
        return !e.row.isEditing && (e.row.data.claimedById === this.props.user.profile.sub) 
      }

      editCostingPossible = (e) => {
        return !e.row.isEditing && (e.row.data.costingComplete === true && this.props.grants.rights.editCostingNotes === true) 
      }

      validToAllocate = (e) => {
        return !e.row.isEditing && (e.row.data.allocated !== true && this.props.grants.rights.allocateParts === true);
      }

      validToOrder = (e) => {
        return !e.row.isEditing && (e.row.data.allocated !== true && e.row.data.ordered !== true && this.props.grants.rights.orderParts === true);
      }

      costingPossible = (e) => {
        
        return !e.row.isEditing && (e.row.data.costingComplete !== true && this.props.grants.rights.costParts === true )
      }

      claimClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.partClaimPopup.instance.show();
      }

      orderClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.orderPopup.instance.show();
      }

      releaseClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.releaseClaimPopup.instance.show();
      }


      submitAllocation = (params,id,grn,qtyAllocated,accountsRef) => {
        var result = params.validationGroup.validate();
        if(result.isValid)
        {
            let data = {
                id: id,
                grn: grn,
                qtyAllocated: qtyAllocated,
                accountsRef: accountsRef
            }
 
            axios.post('api/partworkflow/allocate', data)
            .then(response => {
               
                notify("Allocated Parts successfully","success", notificationTiming);
                this.gridview.instance.refresh();
                this.allocationpopup.instance.hide();
    
            })
            .catch(error => {
              notify(error.response.data,"error", notificationTiming);
    
          
            });
        }
    }

    submitOrder = (params,id,poRef,dueDate) => {
      var result = params.validationGroup.validate();
      if(result.isValid)
      {
          let data = {
              id: id,
              poRef: poRef,
              dueDate: dueDate
          }

          axios.post('api/partworkflow/order', data)
          .then(response => {
             
              notify("Part Order Recorded Successfully","success", notificationTiming);
               this.gridview.instance.refresh();
//               this.props.onBookedOn(null);
              this.orderPopup.instance.hide();
  
          })
          .catch(error => {
            notify(error.response.data,"error", notificationTiming);
            
        
          });
      }
  }

  editCosting = (params,id,notes,accountsRef) => {
    var result = params.validationGroup.validate();
    if(result.isValid)
    {
        let data = {
            id: id,
            additionalNotes: notes,
            accountsRef: accountsRef
        }

        axios.post('api/partworkflow/editcost', data)
        .then(response => {
           
            notify("Part Costed Successfully","success", notificationTiming);
            this.gridview.instance.refresh();
            this.editcostingpopup.instance.hide();

        })
        .catch(error => {
          notify(error.response.data,"error", notificationTiming);
          
      
        });
    }
}

  submitCosting = (params,id,notes,accountsRef) => {
    var result = params.validationGroup.validate();
    if(result.isValid)
    {
        let data = {
            id: id,
            additionalNotes: notes,
            accountsRef: accountsRef
        }

        axios.post('api/partworkflow/cost', data)
        .then(response => {
           
            notify("Part Costed Successfully","success", notificationTiming);
            this.gridview.instance.refresh();
            this.costingpopup.instance.hide();

        })
        .catch(error => {
          notify(error.response.data,"error", notificationTiming);
          
      
        });
    }
}

submitCostingInProgress = (params,id,notes,accountsRef) => {
  var result = params.validationGroup.validate();
  if(result.isValid)
  {
      let data = {
          id: id,
          additionalNotes: notes,
          accountsRef: accountsRef
      }

      axios.post('api/partworkflow/partialcost', data)
      .then(response => {
         
          notify("Updated Part Costing Successfully","success", notificationTiming);
          this.gridview.instance.refresh();
          this.costingpopup.instance.hide();

      })
      .catch(error => {
        notify(error.response.data,"error", notificationTiming);
        
    
      });
  }
}


      allocateClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.allocationpopup.instance.show();
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    quotedClickedHandler = (e) => {
      e.event.preventDefault();

      let result = confirm("<i>" + e.row.data.requestIdentifier + "</i>", "Quoted?");
      result.then((dialogResult) => {
          if(dialogResult === true) {

              let data = {
                  id: e.row.data.id,
                
              }
              axios.post('api/partworkflow/quoteparts', data)
              .then(response => {
                 
                  notify("Part request quoted successfully.","success", notificationTiming);
                  this.gridview.instance.refresh();
 
      
              })
              .catch(error => {
                notify(error.response.data,"error", notificationTiming);
      
            
              });
          }
          
      });

    }


    deAllocateClickedHandler = (e) => {
      e.event.preventDefault();
  //    this.props.onSelectingPartRequest(e.row.data);
      let result = confirm("<i>" + e.row.data.requestIdentifier + "</i>", "Deallocate");
      result.then((dialogResult) => {
          if(dialogResult === true) {

              let data = {
                  id: e.row.data.id,
                
              }
              axios.post('api/partworkflow/deallocate', data)
              .then(response => {
                 
                  notify("Part request successfully deallocated","success", notificationTiming);
                  this.gridview.instance.refresh();
 
      
              })
              .catch(error => {
                notify(error.response.data,"error", notificationTiming);
      
            
              });
          }
          
      });
    }


    validToDeAllocate = (e) => {
      return !e.row.isEditing && (e.row.data.allocated === true && this.props.grants.rights.deallocateParts === true && e.row.data.dateClosed === null);
    }

    validToQuote = (e) => {
      return !e.row.isEditing && (e.row.data.quoted === false && this.props.grants.rights.quoteParts === true);
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }
    
    partRequestClaimed = () => {
      this.partClaimPopup.instance.hide();
      this.gridview.instance.refresh();
    }

    claimReleased = () => {
      this.releaseClaimPopup.instance.hide();
      this.gridview.instance.refresh();
    }

    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.FilteredPartWorkflowDataSource(this.props.user.access_token)}  
            noDataText="There are no matching part requests in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'prworkflow' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} onToolbarPreparing={this.onToolbarPreparing}
         >
        <Export enabled={this.props.grants.rights.exportData} fileName="partworkflow" />
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        <Column dataField={'requestIdentifier'} dataType="string" >
            
        </Column>
        <Column dataField={'id'} visible={false} dataType="number">
            
        </Column>
     
       
        <Column dataField={'partNumber'} dataType="string">
      
       
        </Column>
        <Column dataField={'description'} dataType="string" >
         
      
        </Column>
        <Column dataField={'figure'} dataType="string" visible={false} showInColumnChooser={true}>
   
        </Column>
        <Column dataField={'iplNo'} dataType="string" visible={false} showInColumnChooser={true}>

        </Column>
    
        <Column dataField={'partRequestPriorityId'} caption="Priority" dataType="number">
            <Lookup dataSource={apis.PRPriorityDataSource(this.props.user.access_token)} displayExpr={'priority'} valueExpr={'id'} />
        </Column>

        

        <Column dataField={'grn'} caption ="GRN"dataType="string" visible={false} showInColumnChooser={true}>
        </Column>

        <Column dataField={'poRef'} dataType="string" visible={false} showInColumnChooser={true}>

        </Column>

        <Column dataField={'accountsRef'} caption="Sage Ref" dataType="string" visible={false} showInColumnChooser={true}>

        </Column>


        <Column dataField={'qty'} dataType="number" visible={false} showInColumnChooser={true}>

        </Column>
        
        <Column dataField={'partRequestStatusId'} caption="Status" dataType="number" >
            <Lookup dataSource={apis.PartRequestStatusesDataSource(this.props.user.access_token)} displayExpr={'text'} valueExpr={'id'} />
        </Column>
        <Column dataField={'notes'} caption='Note' visible={false} showInColumnChooser={false} dataType="string">
           
        </Column>
        <Column dataField={'requestedBy'} dataType="string" visible={false} showInColumnChooser={true}>

        </Column>  
        <Column dataField={'dueDate'}  dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
        </Column>
        <Column dataField={'dateRequested'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
        </Column>
        <Column dataField={'dateClosed'}  dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>

        </Column> 
        <Column dataField={'claimedBy'} dataType="string" visible={false} showInColumnChooser={true}>
         
      
         </Column>
         <Column dataField={'costing'} caption="Costing" dataType="boolean" visible={false}>
         
        </Column>
         <Column dataField={'costingComplete'} caption="Costed" dataType="boolean" >
         </Column>
         <Column dataField={'quoted'} caption="Quoted" dataType="boolean" visible={false} >
           
        
        </Column>
        <Column dataField={'ordered'} caption="Ordered" dataType="boolean" >
      
      </Column>
      <Column dataField={'allocated'} caption="Allocated" dataType="boolean" >
          
          </Column>
        <Column dataField={'used'} caption="Used" dataType="boolean" >
        
        </Column>
      
     
        <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
          buttons={[ {
            hint: 'Details',
            icon: 'info',
            onClick: this.infoClickedHandler
          },
          {
            hint: 'Costing',
            icon: 'money',
            onClick: this.costingClickedHandler,
            visible: this.costingPossible
          },
          {
            hint: 'Costing Notes',
            icon: 'edit',
            onClick: this.editCostingClickedHandler,
            visible: this.editCostingPossible
          },
          {
            hint: 'Allocate',
            icon: 'toolbox',
            onClick: this.allocateClickedHandler,
            visible: this.validToAllocate
          },
          {
            hint: 'De-allocate',
            icon: 'undo',
            onClick: this.deAllocateClickedHandler,
            visible: this.validToDeAllocate
          },
          {
            hint: 'Order',
            icon: 'cart',
            onClick: this.orderClickedHandler,
            visible: this.validToOrder
          },
          {
            hint: 'Quoted',
            icon: 'exportselected',
            onClick: this.quotedClickedHandler,
            visible: this.validToQuote
          },
          {
            hint: 'Claim',
            icon: 'todo',
            onClick: this.claimClickedHandler,
             visible: this.validToClaim
          },
          {
            hint: 'Release Claim',
            icon: 'clearsquare',
            onClick: this.releaseClickedHandler,
             visible: this.validToRelease
          }]} />
   
        </DataGrid>
            <Popup title="Part request details" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <PartRequestDetailsPopup></PartRequestDetailsPopup>
            </Popup>
            <Popup title="Part costing" ref={(popup) => {this.costingpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <PartCostingPopup submitCosting={(params,id,notes,accountsRef) => this.submitCosting(params,id, notes, accountsRef)} submitCostingInProgress={(params,id,notes,accountsRef) => this.submitCostingInProgress(params,id, notes,accountsRef)}></PartCostingPopup>
            </Popup>
            <Popup title="Edit costing" ref={(popup) => {this.editcostingpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <EditCostingPopup editCosting={(params,id,notes,accountsRef) => this.editCosting(params,id, notes, accountsRef)} ></EditCostingPopup>
            </Popup>
            <Popup title="Part allocation details" ref={(popup) => {this.allocationpopup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <PartAllocationPopup submitAllocation={(params,id,grn,qtyAllocated,accountsRef) => this.submitAllocation(params,id,grn,qtyAllocated,accountsRef)}></PartAllocationPopup>
            </Popup>
            <Popup title="Claim Part Request" ref={(popup) => {this.partClaimPopup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <PartRequestClaimPopup partRequestClaimedHandler={this.partRequestClaimed}></PartRequestClaimPopup>
            </Popup>
            <Popup title="Release Claim" ref={(popup) => {this.releaseClaimPopup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <ReleasePartRequestClaimPopup claimReleasedHandler={this.claimReleased} adminMode={false}></ReleasePartRequestClaimPopup> 
            </Popup>
            <Popup title="Part Order Details" ref={(popup) => {this.orderPopup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <OrderPartPopup submitOrder={(params,id,poRef, dueDate) => this.submitOrder(params,id, poRef, dueDate)}></OrderPartPopup> 
            </Popup>
        </div>
        );
    }


}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      grants: state.rights
  };
}

const mapDispatchToProps = dispatch => {
  return {
       onSelectingPartRequest: (rowData) => dispatch(actions.setPRDetail(rowData)),
     
  };
}


export default connect(mapStateToProps,mapDispatchToProps)(PartWorkflow)