import * as actionTypes from '../actions/actionTypes';

const initialState = {
    viewingAddress: false,
    viewingContacts: false,
    rowData: null
}

const customerReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SHOW_ADDRESS_POPUP:
         
            return {
                ...state,
                viewingAddress: true,
                rowData: action.rowData

            }
        case actionTypes.HIDE_ADDRESS_POPUP:
            return {
                ...state,
                viewingAddress: false,
                rowData: null
            }
        case actionTypes.SHOW_CONTACTS_POPUP:
            return {
                ...state,
                viewingContacts: true,
                rowData: action.rowData
            }
        case actionTypes.HIDE_CONTACTS_POPUP:
            return {
                ...state,
                viewingContacts: false,
                rowData: null
            }
        default:
            return state;

    }
}

export default customerReducer;
