import React, {Component} from 'react';
import {Popup} from 'devextreme-react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import {animOff} from '../../utils/appConfig';

import DataGrid, {
    Column,
    Editing,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Popup as GridPopup,
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule, EmailRule } from 'devextreme-react/validator';

class ContactPopup extends Component {
    setDefaultNewValues = (e) => {
        e.data.active = true;
        e.data.default = false;
      }
    
    render() {

        return (
            <Popup visible={this.props.popupVisible}
             title={this.props.rowData.customerName + " contacts"}
             closeOnOutsideClick={true}
             onHidden={this.props.hidingPopupHandler}
             animation={animOff}
             >
            <DataGrid 
        dataSource={apis.ContactDataSource(this.props.rowData.id, this.props.user.access_token)}  
        noDataText="There are no contacts defined for this customer."
        rowAlternationEnabled={true}  
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}  
        allowColumnReordering={true}
        allowColumnResizing={true}
        stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'custcont' }}
        height="100%"
        width="100%"
        onInitNewRow={this.setDefaultNewValues} 
        >
         <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.adminCustomerContacts}
            allowDeleting={this.props.grants.rights.adminCustomerContacts}
            allowAdding={this.props.grants.rights.adminCustomerContacts} >
            <GridPopup title="Contact Details" showTitle={true} />
         </Editing>
        <Scrolling mode="virtual" />
        <Column dataField={'id'} visible={false} >
            <FormItem visible={false} />
        </Column>
        <Column dataField={'name'}>
            <RequiredRule />
            <StringLengthRule max="255" message="The name cannot exceed 255 characters."/>
        </Column>
        <Column dataField={'email'}>
            <StringLengthRule max="255" message="Contact email cannot exceed 255 characters."/>
            <EmailRule message="Please enter a valid email address."></EmailRule>
        </Column>
        <Column dataField={'phone'}>
            <StringLengthRule max="255" message="Contact Phone cannot exceed 255 characters."/>
        </Column>
        <Column dataField={'comment'} caption="Comment">
            <StringLengthRule max="1024" message="Comments cannot exceed 1024 characters."/>
        </Column>
        
        <Column dataField={'default'} dataType={'boolean'} />
        <Column dataField={'active'} dataType={'boolean'} />
       
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        </DataGrid>
           
            </Popup>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        grants: state.rights,
        rowData: state.customer.rowData
    };
}

export default connect(mapStateToProps)(ContactPopup);