import * as actionTypes from '../actions/actionTypes';

const initialState = {
    rights: {
        viewAdminMenu: false,
        addWorkorder: false,
        editWorkorderHeader: false,
        addQuote: false,
        editQuote: false,
        addFile: false,
        deleteFile: false,
        createPartRequest: false,
        cancelPartRequest: false,
        addNote: false,
        updateNote: false,
        deleteNote: false,
        adminWOStatus: false,
        adminWOTypes: false,
        adminWOPriorities: false,
        adminReleaseTypes: false,
        adminPRStatus: false,
        adminWOStatusMap: false,
        adminPRStatusMap: false,
        adminPRPriorities: false,
        adminFileTypes: false,
        adminCountries: false,
        adminAddressTypes: false,
        adminBookingTypes: false,
        adminBookOffReasons: false,
        adminBookingStatusMap: false,
        adminCurrencies: false,
        adminGrantedRights: false,
        adminCustomer: false,
        adminCustomerContacts: false,
        adminCustomerAddresses: false,
        productionUser: false,
        manageBookings: false,
        costParts: false,
        allocateParts: false,
        orderParts: false,
        deallocateParts: false,
        allocateWO: false,
        techDataCheck: false,
        addQuery: false,
        cancelOwnQuery: false,
        quarantine: false,
        dispatchWO: false,
        closeWO: false,
        editCustomerTab: false,
        reOpenWorkorder: false,
        quoteParts: false,
        manageRules: false,
        editCostingNotes: false,
        exportData: false,
        cloneWorkorder: false,
        restrictedOrderData: false
    }
}

const rightsReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_USER_RIGHTS:
         
            return {
                ...state,
                rights: action.rights

            }
        default:
            return state;

    }
}

export default rightsReducer;