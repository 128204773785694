import React, {PureComponent, Fragment} from 'react';
import { Form, LoadIndicator  } from 'devextreme-react';
import axios from 'axios';
import * as apis from '../../../utils/apis';
import { connect } from "react-redux";


class ProductionWODetails extends PureComponent {

    state =  {
        loading: true,
        error: false,
        customersData: null,
        workorderTypes: null,
        entities: null,
        releaseTypes: null,
        workorderStatuses: null,
        workTypes: null,
        selectedWo: null 
    }

    componentDidMount() {

        if(this.props.woBookedOnTo) {
            if(this.state.selectedWo !== this.props.woBookedOnTo.id) {
                axios.get('api/workorderheader/' + this.props.woBookedOnTo.id)
                .then(response => {
                    this.detailsFormData = response.data;
                    this.setState({ loading: false, selectedWo: this.props.woBookedOnTo.id });
            
                });
            }
       
        }

               
        
    } 

    componentDidUpdate() {

        if(this.props.woBookedOnTo) {
            if(this.state.selectedWo !== this.props.woBookedOnTo.id) {
                axios.get('api/workorderheader/' + this.props.woBookedOnTo.id)
                .then(response => {
                    this.detailsFormData = response.data;
                    this.setState({ loading: false, selectedWo: this.props.woBookedOnTo.id });
            
                });
            }
       
        }
    }


   
    pressureVesselToggle = (e) => {
        if(this.detailsForm !== null) {
            if(e.value === true) {
                this.detailsForm.instance.itemOption('pressureData','visible',true);
            }
            else { 
                this.detailsForm.instance.itemOption('pressureData','visible',false);
                this.detailsFormData.cylinderSerialNo =  null;
                this.detailsFormData.regulatorSerialNo = null;
                
                this.detailsFormData.hstDue = null;
                this.detailsFormData.overhaulDue = null;
            }
        }
        
    }

    detailsFormData = {
        woNumber: null,
        dateReceived: null,
        entityId: null,
        workOrderTypeId: null,
        partNumber: null,
        serialNo: null,
        quantity: null,
        partDescription: null,
        releaseTypeId: null,
        releaseReference: null,
        customerId: null,
        customerReference: null,
        warranty: false,
        aog: false,
        targetDate: null,
        workTypeId: null,
        boxId: null,
        isPressureVessel: false,
        cylinderSerialNo: null,
        regulatorSerialNo: null,
        workOrderStatusId: null,
        hstDue: null,
        overhaulDue: null,
        dueDate: null 
    }

    render() {
        let detailsFormConfig = {
            items: [
                {
                    itemType: "group",
                    colCount: 5,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: 'woNumber',
                            colSpan: 1,
                            editorType: "dxNumberBox",
                            editorOptions: {
                                showSpinButtons: true,
                                showClearButton: true,
                                readOnly: true
                            },
                            label : { text: "W/O #"},
                        },
                        {
                            dataField: 'workOrderTypeId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'code',
                                valueExpr: 'id',
                                dataSource: apis.WorkorderTypeDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Type"
                            },
                               
                        },
                        {
                            dataField: 'workOrderStatusId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'status',
                                valueExpr: 'id',
                                dataSource: apis.WorkorderStatusDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Status"
                            },
                            
                        },
                        {
                            dataField: 'workTypeId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'description',
                                valueExpr: 'id',
                                dataSource: apis.WorkTypeDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Work Type"
                            },
                                
                        },
                        {
                            dataField: 'entityId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'shortDescription',
                                valueExpr: 'id',
                                dataSource: apis.EntityDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Entity"},
                        },
                        
                    ]
                },
                {
                    itemType: "group",
                    colCount: 4,
                    colSpan: 3,
                    items: [
                        {
                            dataField: 'partNumber',
                            colSpan: 1,
                            editorType: "dxAutocomplete",
                            editorOptions: {
                                dataSource : apis.PartLookupDataSource(this.props.user.access_token),
                                placeholder: 'Start typing',
                                searchMode: 'startswith',
                                displayExpr :'partNumber',
                                valueExpr: 'partNumber',
                                searchExpr: 'partNumber',
                                readOnly: true
                            },
                            label : { 
                            text: "Part No"
                            },
                        },
                        {
                            dataField: 'partDescription',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Part Description"
                            },
                
                        },
                       
                        {
                            dataField: 'serialNo',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "Serial No"},
                            
                        },
                        {
                            dataField: 'quantity',
                            colSpan: 1,
                            editorType: "dxNumberBox",
                            editorOptions: {
                                showSpinButtons: true,
                                showClearButton: true,
                                readOnly: true
                            },
                            label : { 
                                text: "Qty"
                            },
                           
                        },
                    ]
                },
               {
                itemType: "group",
                colCount: 5,
                colSpan: 3,
                items : [
                    {
                        dataField: 'customerId',
                        colSpan: 1,
                        editorType: "dxSelectBox",
                        editorOptions: {
                            displayExpr: 'customerName',
                            valueExpr: 'id',
                            searchExpr: "customerName",
                            searchEnabled: true,
                            dataSource: apis.CustomersDataSource(this.props.user.access_token),
                            readOnly: true
                        },
                        label : { 
                            text: "Customer"},
                           
                    },
                    {
                        dataField: 'customerReference',
                        colSpan: 1,
                        editorType: "dxTextBox",
                        editorOptions: {
                            readOnly: true
                        },
                        label : {
                        text: "Customer Ref"},
                       
                    },
                    {
                        dataField: 'dateReceived',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            readOnly: true
                        },
                        label : { 
                            text: "Date Received"
                        },
                       
                           
                    },
                    {
                        dataField: 'dueDate',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            readOnly: true
                        },
                        label : { 
                            text: "Due Date"
                        },
                           
                    },
                    {
                        dataField: 'dispatchDate',
                        colSpan: 1,
                        editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            readOnly: true
                        },
                        label : { 
                            text: "Close Date"
                        },
                           
                    },
                ]
               },
                
               
                {
                    dataField: 'releaseTypeId',
                    colSpan: 1,
                    editorType: "dxSelectBox",
                    editorOptions: {
                        displayExpr: 'description',
                        valueExpr: 'id',
                        searchExpr: "description",
                        searchEnabled: true,
                        dataSource: apis.ReleaseTypeDataSource(this.props.user.access_token),
                        readOnly: true
                    },
                    label : { 
                        text: "Release"
                    },
                       
                },
                {
                    dataField: 'releaseReference',
                    colSpan: 1,
                    editorType: "dxNumberBox",
                    editorOptions: {
                        readOnly: true
                    },
                    label : {
                    text: "Release Ref"
                    },
                   
                },
                {
                    dataField: 'boxId',
                    colSpan: 1,
                    editorType: "dxTextBox",
                    editorOptions: {
                        readOnly: true
                    },
                    label : {
                         text: "Box ID"
                    },
                    
                },
                
                {
                    dataField: 'warranty',
                    colSpan: 1,
                    editorType: "dxCheckBox",
                    editorOptions: {
                        readOnly: true
                    },
                    label : {
                    text: "Warranty"
                    },
                },
                {
                    dataField: 'aog',
                    colSpan: 1,
                    editorType: "dxCheckBox",
                    editorOptions: {
                        readOnly: true
                    },
                    label : {
                    text: "AOG"},
                   
                },
                {
                    dataField: 'isPressureVessel',
                    colSpan: 1,
                    editorType: "dxCheckBox",
                    editorOptions: {
                        readOnly: true,
                        onValueChanged: this.pressureVesselToggle
                    },
                    label : { 
                        text: "Pressure Vessel"
                    },
                   
                },
               
               
                {
                    itemType: "group",
                    name: "pressureData",
                    caption: "Pressure Vessel Data",
                    colCount: 4,
                    colSpan: 3,
                    visible: this.detailsFormData.isPressureVessel,
                    items: [
                       
                        {
                            dataField: 'cylinderSerialNo',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Cylinder S/N"
                            },
                           
                               
                        },
                        {
                            dataField: 'regulatorSerialNo',
                            colSpan: 1,
                            editorType: "dxTextBox",
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Regulator S/N"
                            },
                            
                               
                        },
                        {
                            dataField: 'hstDue',
                            colSpan: 1,
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly: true
                            },
                            label : { 
                                text: "HST Due"
                            },
                               
                        },
                        {
                            dataField: 'overhaulDue',
                            colSpan: 1,
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly:  true
                            },
                            label : { 
                                text: "Overhaul Due"
                            },
                               
                        },
                    ]
                },
               
            ]
        }

        let output = <p>Book on to wo</p>;

        if(this.props.woBookedOnTo) {
            output = <Fragment>
            <Form visible={!this.state.loading} height="100%"
            colCount={3}
            formData={this.detailsFormData} 
            items={detailsFormConfig.items}
            ref={(form) => {this.detailsForm = form}}>
           </Form> 
            <LoadIndicator visible={this.state.loading} />
          </Fragment>
        }

        return (
           <div>
            {output}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      woBookedOnTo: state.booking.woToInteract,
    };
  }



export default connect(mapStateToProps,null)(ProductionWODetails);

