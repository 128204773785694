import React, {Component} from 'react';
import * as apis from '../../../utils/apis';
import * as utils from '../common';
import { connect } from "react-redux";
import DataGrid, {
    Column,
    Editing,
    Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Form
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';
import * as actions from '../../../store/actions/actions';
import NoteChronologyView from './NoteChronologyView/NoteChronologyView';
import {Popup} from 'devextreme-react';


const getHeight = () => {
    return (window.innerHeight - 98)
}

class NotesTab extends Component {
    state = {
    };

    popupWidth = () => {
        return window.innerWidth / 1.1;
    }
    
    popupHeight = () => {
        return window.innerHeight / 1.1;
    }

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      setDefaultNewValues = (e) => {
        e.data.internal = true;
    }

   onEditorPreparing = (e) =>  {
        if (e.dataField === "text")
            e.editorName = "dxTextArea";
        }

    infoClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingNote(e.row.data);
        this.popup.instance.show();
    }

    viewFullNotes = (e) => {
      var dataKey = this.gridview.instance.getVisibleRows();

      if(dataKey.length !== 0) {
        this.props.onSelectingFullNote(dataKey[0].data);
        this.popup.instance.show();
      }
       // this.props.onSelectingFullNote();
     
    }
    
    onToolbarPreparing = (e) => {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'doc',
          onClick: this.viewFullNotes 
        }
      },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            onClick: this.refreshGrid 
          }
        });
    }
  
    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    render() {
        return  (
            <div>
            <DataGrid 
            dataSource={apis.WorkorderNoteDataSource(this.props.selectedWo.id,this.props.user.access_token)}  
            noDataText="There are no notes for this W/O"
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'wonotes' + this.props.admin }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onInitNewRow={this.setDefaultNewValues} onEditorPreparing={this.onEditorPreparing} onToolbarPreparing={this.onToolbarPreparing}>
         >
         <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.updateNote && !(this.props.selectedWo.locked === true || this.props.selectedWo.complete === true)}
            allowDeleting={this.props.grants.rights.deleteNote && !(this.props.selectedWo.locked === true || this.props.selectedWo.complete === true)}
            allowAdding={this.props.grants.rights.addNote && !(this.props.selectedWo.locked === true || this.props.selectedWo.complete === true)} >
             <Form colCount="1">         
            </Form>
             <Popup title={'Workorder Note'} showTitle={true} width={utils.popupWidth()} height={utils.popupHeight()}>
              <Position my={'center'} at={'center'} of={window} />
 
            </Popup>
           
        </Editing>
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'addedBy'}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'dateAdded'} dataType="date" format="dd/MM/y"  >
            <FormItem visible={false} />
        </Column>
        <Column dataField={'internal'} dataType="boolean">
            <FormItem visibleIndex="1"></FormItem>
        </Column>

        <Column dataField={'text'} caption='Note' visible={false}>
            <FormItem visibleIndex="0" editorOptions={{height: "200px"}}></FormItem>
            <RequiredRule />
            <StringLengthRule max="2048" message="A Note cannot exceed 512 characters."/>
        </Column>
        <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
          buttons={[ 'edit','delete', {
            hint: 'Details',
            icon: 'info',
            onClick: this.infoClickedHandler
          }]} />
       
 
        </DataGrid>
        <Popup title="Notes upto selected note" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} >
              <NoteChronologyView id={this.testId}></NoteChronologyView>
            </Popup>
        </div>
        );
        
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      selectedWo: state.woDetail.rowData,
      grants: state.rights
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
         onSelectingNote: (rowData) => dispatch(actions.setNoteDetail(rowData)),
         onSelectingFullNote: (rowData) => dispatch(actions.setFullFlag(rowData))
       
    };
  }

export default connect(mapStateToProps,mapDispatchToProps)(NotesTab)