import * as actionTypes from '../actions/actionTypes';

const initialState = {
    rowData: null,
    fullNotes: false
}

const noteReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_NOTE_DETAIL:        
            return {
                ...state,
                rowData: action.rowData,
                fullNotes: false
            }
        case actionTypes.SET_FULL_FLAG:
            return {
                ...state,
                rowData: action.rowData,
                fullNotes: true
            }
        default:
            return state;

    }
}

export default noteReducer;