import React, {Component, Fragment} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import * as actions from '../../store/actions/actions';
import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Lookup
} from 'devextreme-react/ui/data-grid';
import {Popup as VPopup} from 'devextreme-react';
import RejectionForm from './RejectionForm/RejectionForm';
import {notificationTiming} from '../../utils/appConfig';
export const REJECT = 1;

const getHeight = () => {
    return (window.innerHeight - 48)
}



class RuleActionsGrid extends Component {
    state = {
        gridHeight: 0,
    };

    resize = () => {
       this.gridview.instance.option('height',getHeight());
      }

      popupWidth = () => {
        return window.innerWidth / 1.1;
      }
      
      popupHeight = () => {
        return window.innerHeight / 1.1;
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      setDefaultNewValues = (e) => {
        e.data.active = true; 
      }

      onEditorPreparing = (e) =>  {
      
     if(e.dataField === "sequence") {
         e.editorName = "dxNumberBox";
     }
   }

   onToolbarPreparing = (e) => {
    e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: this.refreshGrid 
        }
      });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    setDefaultNewValues = (e) => {
        e.data.active = true;
        e.data.sequence = 1;
    }

    configureClickedHandler =(e) => {
        e.event.preventDefault();
        this.props.onSelectingAction(e.row.data);

        switch(e.row.data.actionTypeId) {
          case REJECT: 
            this.rejectPopup.instance.show();
          break;
          default:
        }
       
    }

    submitRejection = (params,actionId,formData) => {
   
      var result = params.validationGroup.validate();
      if(result.isValid)
      {
          let data = {
              actionId: actionId,
              rejectionMessage: formData.rejectionMessage
          }
  
          axios.post('api/actionconfig/rejection', data)
          .then(response => {
             
              notify("Updated Configuration Successfully","success", notificationTiming);
             this.gridview.instance.refresh();
             this.rejectPopup.instance.hide();
  
          })
          .catch(error => {
            notify(error.response.data,"error", notificationTiming);
          });
      }
    }

    render() {

        let output=<p>Select rule to view Actions</p>;

        if(this.props.rules.rowData !== null) {
          output =   <Fragment><DataGrid 
          dataSource={apis.RuleActionsDataSource(this.props.rules.rowData.id,this.props.user.access_token)}  
          noDataText="There are no actions defined for this rule."
          rowAlternationEnabled={true}  
          showBorders={true}
          showRowLines={true}
          showColumnLines={true}  
          columnHidingEnabled={false}
          allowColumnReordering={true}
          allowColumnResizing={true}
          stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'ruleActions' }}
          height={getHeight}
          width="100%" ref={(gv) => {this.gridview = gv}}
          onInitNewRow={this.setDefaultNewValues} onEditorPreparing={this.onEditorPreparing} onToolbarPreparing={this.onToolbarPreparing}
       >
         <Editing
          mode={'popup'}
          allowUpdating={this.props.grants.rights.manageRules}
          allowDeleting={this.props.grants.rights.manageRules}
          allowAdding={this.props.grants.rights.manageRules} >
           <Popup title={'Define Rule Action'} showTitle={true} width={700} height={345}>
            <Position my={'center'} at={'center'} of={window} />
          </Popup>
      </Editing>
      <Scrolling mode="virtual" />
      <GroupPanel visible={true} />
      <FilterRow visible={true} />
      <FilterPanel visible ={true} />

      <ColumnChooser enabled={true} />
      {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}

      
      <Column dataField={'id'} visible={false}>
          <FormItem visible={false} />
      </Column>
      <Column dataField={'actionTypeId'} visible={true} caption="Action Type">
      <RequiredRule message="You must specify the type of action." />
          <Lookup dataSource={apis.ActionsDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} allowClearing={true} /> 
      </Column>

      <Column dataField={'sequence'}  dataType="number">
          <RequiredRule message="Numeric value is required." />
          <FormItem editorOptions={{ showSpinButtons: true, min: 1 }} />
      </Column>



    
      <Column dataField={'active'} dataType="boolean" />
      <Column type="buttons" width={110}
        buttons={['edit', 'delete',
         {
          hint: 'Configure',
          icon: 'preferences',
          onClick: this.configureClickedHandler,
          }]} />


      </DataGrid>
        <VPopup ref={(popup) => {this.rejectPopup = popup}} height={this.popupHeight} width={this.popupWidth} onHiding={this.hidePopup} title="Configure Rejection Action" >
          <RejectionForm submitRejection={(params,actionId,formData) => this.submitRejection(params,actionId,formData)}></RejectionForm>
        </VPopup>
        </Fragment>
        }
        return  (
          <div>
          {output}
          </div>
        );
    }


}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      grants: state.rights,
      rules: state.rules,
      action: state.action
  };
}

const mapDispatchToProps = dispatch => {
  return {
       onSelectingAction: (rowData) => dispatch(actions.setActionDetail(rowData)),
     
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(RuleActionsGrid)