import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';


const getHeight = () => {
    return (window.innerHeight - 48)
}

class QueryStatusGrid extends Component {
    state = {
    };

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      setDefaultNewValues = (e) => {
          
          e.data.open = false;
      }

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    render() {
        return  (
            <DataGrid
            dataSource={apis.QueryStatusDataSource(this.props.user.access_token)}  
            noDataText="There are no query statuses defined."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'queryStatus' }}
            height={getHeight }
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onInitNewRow={this.setDefaultNewValues} onToolbarPreparing={this.onToolbarPreparing}
            >
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
        <Editing
            mode={'popup'}
            allowUpdating={false}
            allowDeleting={false}
            allowAdding={false} >
             <Popup title={'Query Status'} showTitle={true} width={700} height={345}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
        </Editing>
  
        <ColumnChooser enabled={true} />

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'description'} >
            <RequiredRule />
            <StringLengthRule max="100" message="The query status description cannot exceed 100 characters."/>
        </Column>
        <Column dataField={'open'} dataType={'boolean'}>
           
        </Column>
       
 
        </DataGrid>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
    };
  }

export default connect(mapStateToProps, null)(QueryStatusGrid)