import React,{Fragment, Component} from 'react';
import {Drawer, Toolbar, LoadIndicator} from 'devextreme-react';
import MainMenu from '../../components/MainMenu/MainMenu';
import { Route, Switch } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import AddressTypesGrid from '../AddressTypesGrid/AddressTypesGrid';
import CountriesGrid from '../CountriesGrid/CountriesGrid';
import WorkorderStatusGrid from '../WorkorderStatusGrid/WorkorderStatusGrid';
import './Layout.css';
import BookingTypesGrid from '../BookingTypeGrid/BookingTypeGrid';
import CurrencyGrid from '../CurrencyGrid/CurrencyGrid';
import WorkorderTypeGrid from '../WorkorderTypeGrid/WorkorderTypeGrid';
import ReleaseTypeGrid from '../ReleaseTypeGrid/ReleaseTypeGrid';
import PartRequestStatusesGrid from '../PartRequestStatusesGrid/PartRequestStatusesGrid';
import CustomersGrid from '../CustomersGrid/CustomersGrid';
import WOPriorityGrid from '../WOPriorityGrid/WOPriorityGrid';
import WorkorderGrid from '../WorkorderGrid/WorkorderGrid';
import AllocationGrid from '../AllocationGrid/AllocationGrid';
import WOStatusMapGrid from '../WOStatusMapGrid/WOStatusMapGrid';
import PRStatusMapGrid from '../PRStatusMapGrid/PRStatusMapGrid';
import TimeBookingGrid from '../TimeBookingGrid/TimeBookingGrid';
import WorkorderTabs from '../WorkorderTabs/WorkorderTabs';
import { connect } from "react-redux";
import PRPriorityGrid from '../PRPriorityGrid/PRPriorityGrid';
import FileTypesGrid from '../FileTypesGrid/FileTypesGrid';
import PartRequestGrid from '../PartRequestGrid/PartRequestGrid';
import PartRequestEnquiry from '../PartRequestEnquiry/PartRequestEnquiry';
import ProductionTabs from '../ProductionTabs/ProductionTabs';
import BookingReasonStatusGrid from '../BookingReasonStatusGrid/BookingReasonStatusGrid';
import BookOffReasonGrid from '../BookOffReasonGrid/BookOffReasonGrid';
import QueryStatusGrid from '../QueryStatusGrid/QueryStatusGrid';
import QueryEnquiry from '../QueryEnquiry/QueryEnquiry';
import QueryAdmin from '../QueryAdmin/QueryAdmin';
import PartWorkflow from '../PartWorkflow/PartWorkflow';
import QueryWorkflow from '../QueryWorkflow/QueryWorkflow';
import SecurityRights from '../SecurityRights/SecurityRights';
import UserSecurityRights from '../UserSecurityRights/UserSecurityRights';
import AvailableRights from '../AvailableRights/AvailableRights';
import RulesGrid from '../RulesGrid/RulesGrid';
import WODash from '../WODash/WODash';
import * as apis from '../../utils/apis';
import WorkorderWorkflow from '../WorkorderWorkflow/WorkorderWorkflow';
import axios from 'axios';
import * as actions from '../../store/actions/actions';

class Layout extends Component {

    state = {
        opened: true,
        access: false,
        loadingAccess: true,
        loadingRights: true,
        loadingOrders: true,
        loadingActivity: true,
      };

    componentDidMount() {
      axios.get('api/rights/getuserrights')
        .then(response => {

            this.props.onSettingRights(response.data);
            this.setState({loadingRights: false});
        });
      
        axios.get('api/rights/getaccess')
        .then(response => {
            this.setState({access: response.data, loadingAccess: false});
    
        });

        axios.get('api/production/getbookedworkorders')
        .then(response => {
            this.props.onSettingBookings(response.data);
            this.setState({loadingOrders: false});
        });

        axios.get('api/production/getbookedactivity')
        .then(response => {
          if(response.data.activityId === true) {
            this.props.onBookedToOther();
           
          }
          this.setState({loadingActivity: false});
        }

        )
    }

    render() {
      let  toolbarItems = [
     
        {
          widget: 'dxButton',
          location: 'before',
          options: {
            icon: 'menu',
            onClick: () => this.setState({ opened: !this.state.opened })
          },
          
      },
      {
        location: 'before',
        widget: 'dxButton',
        role: 'hub',
        options: {
            icon: 'home',
            text: '',
            toolTip: "HUB",
            onClick: () => window.location.assign(apis.HUBHOME)
           
        }
    }, 
      {
        location: 'center',
        widget: 'dxTextBox',
        options: {
            placeholder: "NO BOOKING",
            width: '200px',
            value: this.props.woBookedOnTo.length !== 0 ? 'BOOKED TO ' +  this.props.woBookedOnTo.length + ' ORDERS' : (this.props.bookedToOther !== false ? 'BOOKED TO OTHER' : null),// + this.props.woBookedOnTo.woNumber : (this.props.bookedToOther !== false ? 'BOOKED TO OTHER' : null),
            readOnly: true,
            visible: this.props.woBookedOnTo.length !== 0 ? true : (this.props.bookedToOther !== false ? true : false)
  
        }
      },
      {
        location: 'after',
        widget: 'dxTextBox',
        options: {
            placeholder: "W/O Number",
            width: '200px',
            value: this.props.selectedWo !== null ? 'VIEWING WO#' + this.props.selectedWo.woNumber : null,
            readOnly: true,
            visible: this.props.selectedWo !== null
  
        },
        
       
    },
    {
      location: 'after',
      widget: 'dxTextBox',
      options: {
          placeholder: "UNALLOCATED",
          width: '200px',
          value: this.props.selectedWo !== null ? this.props.selectedWo.allocatedTo : null,
          readOnly: true,
          visible: this.props.selectedWo !== null

      },
      
     
  },
  
  ];
  


      let authUI =  <Fragment>
      <div className="topbar">   
      <Toolbar items={toolbarItems } />
      </div>
      <Drawer
        opened={this.state.opened}
        openedStateMode={'shrink'}
        position={'left'}
        revealMode={'slide'}
        component={MainMenu}
        closeOnOutsideClick={true}
        height="100%">
        <div id={'content'} className={'dx-theme-background-color'}>
        <Switch>
        <Route path='/' exact component={WODash} />
        <Route path='/production/live' exact render={() => <ProductionTabs tab={1} /> } />
        <Route path='/admin/workorderdetail' exact render={() => <WorkorderTabs tab={0} admin={true} />} />
        <Route path='/enquiry/workorderdetail' exact render={() => <WorkorderTabs tab={0} admin={false} />} />
        <Route path='/admin/workorderdetail/customer' exact render={() => <WorkorderTabs tab={1} admin={true} />} />
        <Route path='/enquiry/workorderdetail/customer' exact render={() => <WorkorderTabs tab={1} admin={false} />} />
        <Route path='/admin/workorderdetail/quotes' exact render={() => <WorkorderTabs tab={2} admin={true} />} />
        <Route path='/enquiry/workorderdetail/quotes' exact render={() => <WorkorderTabs tab={2} admin={false} />} />
        <Route path='/admin/workorderdetail/techdata' exact render={() => <WorkorderTabs tab={3} admin={true} />} />
        <Route path='/enquiry/workorderdetail/techdata' exact render={() => <WorkorderTabs tab={3} admin={false} />} />
        <Route path='/admin/workorderdetail/labour' exact render={() => <WorkorderTabs tab={4} admin={true} />} />
        <Route path='/admin/workorderdetail/files' exact render={() => <WorkorderTabs tab={5} admin={true} />} />
        <Route path='/enquiry/workorderdetail/files' exact render={() => <WorkorderTabs tab={5} admin={false} />} />
        <Route path='/admin/workorderdetail/parts' exact render={() => <WorkorderTabs tab={6} admin={true} />} />
        <Route path='/enquiry/workorderdetail/parts' exact render={() => <WorkorderTabs tab={6} admin={false} />} />
        <Route path='/admin/workorderdetail/queries' exact render={() => <WorkorderTabs tab={7} admin={true}/>} />
        <Route path='/enquiry/workorderdetail/queries' exact render={() => <WorkorderTabs tab={7} admin={false}/>} />
        <Route path='/admin/workorderdetail/notes' exact render={() => <WorkorderTabs tab={8} admin={true} />} />
        <Route path='/enquiry/workorderdetail/notes' exact render={() => <WorkorderTabs tab={8} admin={false} />} />
        <Route path='/admin/partrequests' component={PartRequestGrid} />
        <Route path='/admin/queries' component={QueryAdmin} />
        <Route path='/admin/querystatus' component={QueryStatusGrid} />
        <Route path='/admin/prpriorities' exact component={PRPriorityGrid} />
        <Route path='/admin/filetypes' exact component={FileTypesGrid} />
        <Route path='/admin/currencies' exact component={CurrencyGrid} />
        <Route path='/admin/bookings' exact component={TimeBookingGrid} />
        <Route path='/admin/bookingreasons' exact component={BookOffReasonGrid} />
        <Route path='/admin/bookingstatusmap' exact component={BookingReasonStatusGrid} />
        <Route path='/admin/prstatusmap' exact component={PRStatusMapGrid} />
        <Route path='/admin/wostatusmap' exact component={WOStatusMapGrid} />
        <Route path='/workflow/allocation' exact component={AllocationGrid} />
        <Route path='/workflow/parts' exact component={PartWorkflow} />
        <Route path='/workflow/queries' exact component={QueryWorkflow} />
        <Route path='/workflow/workorders' exact component={WorkorderWorkflow} />
        <Route path='/enquiry/partrequests' exact component={PartRequestEnquiry} />
        <Route path='/enquiry/queries' exact component={QueryEnquiry} />
        <Route path='/enquiry/workorders' exact render={() => <WorkorderGrid admin={false} />} />
        <Route path='/admin/workorders' exact render={() => <WorkorderGrid admin={true} /> } />
        <Route path='/admin/workorderpriorities' exact component={WOPriorityGrid} />
        <Route path='/admin/customers' exact component={CustomersGrid} />
        <Route path='/admin/countries' exact component={CountriesGrid} />
        <Route path='/admin/rules' exact component={RulesGrid} />
        <Route path='/admin/workordertypes' exact component={WorkorderTypeGrid} />
        <Route path='/admin/releasetypes' exact component={ReleaseTypeGrid} /> 
        <Route path='/admin/partrequeststatuses' exact component={PartRequestStatusesGrid} />
        <Route path='/admin/bookingtypes' exact component={BookingTypesGrid} />
        <Route path='/admin/workorderstatuses' exact component={WorkorderStatusGrid} />
        <Route path='/admin/addresstypes' exact component={AddressTypesGrid} />
        <Route path='/admin/countries' exact component={CountriesGrid} />
        <Route path='/admin/security' exact component={SecurityRights} />
        <Route path='/admin/usersecurity' exact component={UserSecurityRights} />
        <Route path='/admin/availablerights' exact component={AvailableRights} />
        </Switch>
        </div>
      </Drawer>
 
      </Fragment>;

      let unauthUI = <div><h1>Access Denied</h1>
      <a href={apis.HUBHOME}>Back to the Hub</a>
      </div>;

     let loadingUI = <div><h1>Please Wait</h1>  <LoadIndicator visible={this.state.loadingAccess || this.state.loadingRights || this.state.loadingOrders} /></div>

   
     let UI = loadingUI;


  if(this.state.access && !(this.state.loadingAccess || this.state.loadingRights || this.state.loadingOrders))
  {
    UI = authUI;
  }
  else if(!this.state.access && !(this.state.loadingAccess))
  {
    UI = unauthUI;
  }
 

        return (
        // potentially here we can conditionally control display

          

            <Fragment>
             
              {UI}
       
            </Fragment>
            );
        
    }
}

const mapStateToProps = (state) => {
  return {
    user: state.oidc.user,
    selectedWo: state.woDetail.rowData,
    woBookedOnTo: state.booking.bookedOnToWo,
    bookedToOther: state.booking.bookedToOther,
  };
}

const mapDispatchToProps = dispatch => {
  return {
       onSettingRights: (rights) => dispatch(actions.setUserRights(rights)),
       onSettingBookings: (bookings) => dispatch(actions.setBookings(bookings)),
       onBookedToOther: () => dispatch(actions.bookToOther()),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Layout))


