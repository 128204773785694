import React, {Component} from 'react';
import * as apis from '../../../utils/apis';
import { connect } from "react-redux";
import LinkCell from '../../../components/LinkCell/LinkCell';
import { Template } from 'devextreme-react/core/template';

import DataGrid, {
    Column,
    Editing,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Form
} from 'devextreme-react/ui/data-grid';
import  WOFileUploader from '../../WorkorderTabs/FilesTab/WOFileUploader';
import { StringLengthRule } from 'devextreme-react/validator';

const getHeight = () => {
    return (window.innerHeight - 178)
}

class ProductionFiles extends Component {
    state = {
    };

    filenames = [];

    resize = () => {
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }


   onEditorPreparing = (e) =>  {
        // if (e.dataField === "text")
        //     e.editorName = "dxTextArea";
        }

    rowInserting = (e) => {     
        e.data.filenames = this.filenames;  
    }

    rowInserted = (e) => {
        this.filenames = [];
    }

    rowUpdating = (e) => {
        e.newData.filename = this.filename;
    }

    rowUpdated = (e) => {
        this.filenames = [];
    }

    uploadHandler = (e) => {

     this.filenames.push(e.file.name)
     if(this.filenames.length > 0) {
     this.gridview.instance.option('isValid,true');
     }
    }

    checkFileUploaded = (e) => {
        return this.filenames.length > 0;
    }

    setDefaultNewValues =(e) => {
        this.filenames = [];
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }
    
    render() {
        let output = <p>Book on to wo</p>;

        if(this.props.woBookedOnTo){
            output =  <DataGrid 
            dataSource={apis.WorkorderFilePDataSource(this.props.woBookedOnTo.id,this.props.user.access_token)}  
            noDataText="There are no files for this W/O"
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'wofilesp' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onInitNewRow={this.setDefaultNewValues} onEditorPreparing={this.onEditorPreparing}
            onRowInserting={this.rowInserting} onRowUpdating={this.rowUpdating}
            onRowUpdated={this.rowUpdated} onRowInserted={this.rowInserted} onToolbarPreparing={this.onToolbarPreparing}>
         >
         <Editing
            mode={'popup'}
             allowDeleting={this.props.woBookedOnTo.allocated === this.props.user.profile.sub}
              allowAdding={this.props.woBookedOnTo.allocated === this.props.user.profile.sub} >   
            <Form colCountByScreen={{ lg:1, md:1,sm:1, xs:1}}>         
            </Form>       
        </Editing>
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>
        <Column dataField={'fileTypeId'} caption="File Type" >
        <RequiredRule message="Please select file type" />
            <Lookup dataSource={apis.FileTypesDataSource(this.props.user.access_token)} displayExpr={'description'} valueExpr={'id'} />
        </Column>
        <Column dataField={'filename'} visible={true} cellTemplate={'linkCellTemplate'} >
            <FormItem visible={false} />
        </Column>
        <Column dataField={'description'} >
            <RequiredRule message="Please enter a file description." />
            <StringLengthRule max="512" message="File description cannot exceed 512 characters."/>
            <FormItem visible={true} />
        </Column>
        <Column dataField={'file'} visible={false} editCellRender={() =>  <WOFileUploader uploadedHandler={this.uploadHandler} token={this.props.user.access_token} />} >
              <FormItem visible={true} />
              
        </Column>
        <Column dataField={'dateAdded'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
        <FormItem visible={false} />
        </Column>
        <Column dataField={'addedBy'} dataType="string" visible={false} showInColumnChooser={true}>
        <FormItem visible={false} />
        </Column>
        <Template name={'linkCellTemplate'} render={LinkCell} />
        </DataGrid>
        }
        return  (
           <div>
               {output}
           </div>
        );
        
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      woBookedOnTo: state.booking.woToInteract,
    };
  }

export default connect(mapStateToProps)(ProductionFiles)