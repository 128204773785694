import React, {Component} from 'react';
import { connect } from "react-redux";
import { Form, ScrollView } from 'devextreme-react';
import * as apis from '../../utils/apis';



class QueryDetailsPopup extends Component {

   

    render() {

        let formConfig = {
            items : [
                {
                    itemType: "group",
                    colCount: 6,
                    colSpan: 6,
                    items: [
                        
                        {
                            dataField: 'queryIdentifier',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "Query #"},
                            
                        },
                        {
                            dataField: 'claimedBy',
                            colSpan: 1,                    
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Claimed by"},
                        },
                        {
                            dataField: 'queryStatusId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'description',
                                valueExpr: 'id',
                                dataSource: apis.QueryStatusDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Status"
                            },
                               
                        },
                        {
                            dataField: 'userGroupId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'name',
                                valueExpr: 'id',
                                dataSource: apis.UserGroupDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Responsible"
                            },
                               
                        },
                        {
                            dataField: 'raisedBy',
                            colSpan: 1,                    
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Raised by"},
                        },
                        {
                            dataField: 'dateOpened',
                            colSpan: 1,                    
                            editorType: "dxDateBox",
                            editorOptions: {
                                displayFormat: "dd/MM/y",
                                readOnly: true
                            },
                            label : { 
                                text: "Date Opened"},
                        },
                        
                        
                    ]
                },
                {
                    dataField: 'closedBy',
                    colSpan: 3,
                    editorOptions: {
                        readOnly: true
                    },
                    label : { text: "Resolved By"},

                },
                {
                    dataField: 'dateResolved',
                    colSpan: 3,
                    editorType: "dxDateBox",
                        editorOptions: {
                            displayFormat: "dd/MM/y",
                            readOnly: true
                        },
                    label : { text: "Date Resolved"},

                },
                {
                    dataField: 'question',
                    colSpan: 6,
                    editorType: "dxTextArea",
                    editorOptions: {
                        height: "150px",
                        readOnly: true
                    },
                    label: {
                        text: "Question"
                    }

                },
                {
                    dataField: 'response',
                    colSpan: 6,
                    editorType: "dxTextArea",
                    editorOptions: {
                        height: "150px",
                        readOnly: true
                    },
                    label: {
                        text: "Response"
                    }

                },
               
                
               
            ]


    }

        let output = null;

       

        if(this.props.query) {
            output = 
            <ScrollView>
                <Form formData ={this.props.query} items={formConfig.items} colCount={6}>

                </Form>
            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        query : state.queryDetail.rowData
    };
  }

export default connect(mapStateToProps,null)(QueryDetailsPopup)