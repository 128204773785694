import React, {Component} from 'react';
import { connect } from "react-redux";
import { Form, ScrollView } from 'devextreme-react';
import * as apis from '../../utils/apis';
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import { notificationTiming } from '../../utils/appConfig';



class QuarantineWorkorderPopup extends Component {
    
    quarantineWO = (e) => {

        var result = e.validationGroup.validate();
        if(result.isValid)
        {
        let data = {
            id: this.props.selectedWo.id,
            reason: this.props.selectedWo.reason
        }
    
       
        axios.post('api/workorders/quarantine', data)
        .then(response => {  
            notify("Workorder quarantined successfully.","success", notificationTiming);
            this.props.quarantinedHandler();
        })
        .catch(error => {
            console.log(error);
    
            notify(error.response.data,"error", notificationTiming);
        });
        
       }
    }

    render() {

        let formConfig = {
            items : [
                {
                    itemType: "group",
                    colCount: 5,
                    colSpan: 3,
                    items: [
                        
                        {
                            dataField: 'woNumber',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { text: "WO #"},
                            
                        },
                        {
                            dataField: 'partNumber',
                            colSpan: 1,                    
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Part Number"},
                        },
                        {
                            dataField: 'partDescription',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Description"},
                                
                        },
                        {
                            dataField: 'serialNo',
                            colSpan: 1,
                            editorOptions: {
                                readOnly: true
                            },
                            label : { 
                                text: "Serial No"
                            },
                                
                        },
                        {
                            dataField: 'customerId',
                            colSpan: 1,
                            editorType: "dxSelectBox",
                            editorOptions: {
                                displayExpr: 'customerName',
                                valueExpr: 'id',
                                searchExpr: "customerName",
                                searchEnabled: true,
                                dataSource: apis.CustomersDataSource(this.props.user.access_token),
                                readOnly: true
                            },
                            label : { 
                                text: "Customer"},
                                validationRules: [{
                                type: "required",
                                message: "Customer is Required."
                            },
                            ]
                        },
                       
                        
                    ]
                },
                {
                    dataField: 'reason',
                    colSpan: 5,
                    editorType: "dxTextArea",
                    editorOptions: {
                        height: "150px",
                        readOnly: false
                    },
                    label: {
                        text: "Reason"
                    },
                    validationRules: [{
                        type: "required",
                        message: "Reason is Required."
                    },
                    ]

                },
                {
                    itemType: "button",
                    horizontalAlignment: "right",
                    colSpan: 5,
                    buttonOptions: {
                        text: "Quarantine",
                        type: "danger",
                        useSubmitBehavior: false,
                        onClick: e => this.quarantineWO(e)
                    }
                },
            ]
            }


        let output = null;
       

        if(this.props.selectedWo) {
            output = 
            <ScrollView>
                <Form formData ={this.props.selectedWo} items={formConfig.items}>

                </Form>

            </ScrollView>
   
        }
        return (
            <div style={{height: '100%'}}>
            { output }
            </div>
          
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        selectedWo: state.woDetail.rowData
    };
  }

export default connect(mapStateToProps,null)(QuarantineWorkorderPopup)