import React, { Component } from 'react';
import { connect } from "react-redux";
import axios from 'axios';

import Layout from './containers/Layout/Layout';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import CallbackPage from './containers/CallbackPage/CallbackPage';
import MainPage from './components/MainPage/mainPage';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.purple.light.css';
import './App.css';

class App extends Component {
  render() {

    axios.defaults.baseURL = 'https://woapi.bosa.uat99.com/';

    const {user} = this.props;
    if(user) {
      axios.defaults.headers.common = {'Authorization': "bearer " + user.access_token}
    }

    let view =  <BrowserRouter>
    <Layout />
    </BrowserRouter>;

if(!user || user.expired) {
  view = <BrowserRouter>
   <Switch>
    <Route path='/callback' exact component={CallbackPage} />
    <Route path='/' exact component={MainPage} />
    <Redirect to='/' />
   </Switch>
  </BrowserRouter>
} 

    return (
      <div className="App">
      {view}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user
  };
}

const mapDispatchToProps = dispatch => {
  return {

  };
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
