import React, {Component} from 'react'
import { Form, ScrollView } from 'devextreme-react';
import * as apis from '../../../utils/apis'
import { connect } from "react-redux";
class BookToGeneralPopup extends Component {
    bookingForm = {
        activityId : null
    }  

    render() {
         let formConfig = {
             validationGroup : "bookon",
            items: [
                {
                    dataField: 'activityId',
                    colSpan: 1,
                    editorType: "dxSelectBox",
                    editorOptions: {
                        displayExpr: 'description',
                        valueExpr: 'id',
                        dataSource: apis.BookingTypeDataSource(this.props.user.access_token)
                    },
                    label : { 
                        text: "Activity"},
                        validationRules: [
                    {
                        validationGroup: "bookon",
                        type: "required",
                        message: "Activity is Required.",        
                    },
                    ]
                },
               
              
               {
                itemType: "button",
                visible: true,
                horizontalAlignment: "right",
                colSpan: 1,
                buttonOptions: {
                    text: "Book on to Activity",
                    type: "default",
                    useSubmitBehavior: false,
                    onClick: (params) => this.props.submitBookOnRequest(params,this.bookingForm.activityId)
                        
                    
                },
                
            },
            {
                itemType: "group",               
                colCount: 1,
                items: [{
                    itemType: "simple",
                    template: "<div><p class='dx-theme-material-typography'>Booking to a non workorder task will book you off all workorders.</p><p class='dx-theme-material-typography'>Please note booking off all work orders will not change their status.</p></div>"
                },]
                
            }
            
            ]
        }
 
        let offformConfig = {
            validationGroup : "bookon",
           items: [
              
          
             
              {
               itemType: "button",
               visible: true,
               horizontalAlignment: "right",
               colSpan: 1,
               buttonOptions: {
                   text: "Book Off Activity",
                   type: "default",
                   useSubmitBehavior: false,
                   onClick: () => this.props.submitBookOffRequest()
                       
                   
               }
           }
           
           ]
       }
       

       let output = <ScrollView>
                
                <Form formData ={this.bookingForm} items={formConfig.items} >
                    
                </Form> 
        
            </ScrollView>
        

        if(this.props.bookedToOther) {
            output = <ScrollView>
            <Form items={offformConfig.items} >
                
            </Form> 
        </ScrollView>
        }

        return  (<div style={{height: '100%'}}>
                { output }
                </div>)
        }
}


const mapStateToProps = (state) => {
    return {
      woToBookTo : state.booking.rowData,
      bookedToOther: state.booking.bookedToOther,
      user: state.oidc.user,
    };
  }

export default connect(mapStateToProps, null)(BookToGeneralPopup)

