import React, {Component} from 'react';
import { connect } from "react-redux";
import * as actions from '../../store/actions/actions';
import Tabs from 'devextreme-react/tabs';
import ProductionQueueTab from './ProductionQueueTab/ProductionQueueTab';
import AllocatedTab from './AllocatedTab/AllocatedTab';
import './ProductionTabs.css';

const prTabs = [
    { text: 'Production Queue' },
    { text: 'Allocated To Me' },
  
];

class ProductionTabs extends Component {


    state =  {
        selectedIndex: null
    }

    onTabsSelectionChanged = (args) =>  {
        if(args.name === 'selectedIndex') {
        this.setState({
            selectedIndex: args.value
        });
        }
    }

    componentDidMount() {

        


        if(this.props.tab !== null) {
          this.setState({selectedIndex : this.props.tab});
        }
      }

    render() {
        let tab = null;
        
        switch(this.state.selectedIndex) {
            case 0:
                tab = <ProductionQueueTab></ProductionQueueTab>;
                break;
            case 1:
                tab = <AllocatedTab></AllocatedTab>;
                break;
            default:
                tab = null;
        }

        return (
        <div id={'prTabs'}>
        <Tabs dataSource={prTabs} height='50px' selectedIndex={this.state.selectedIndex} onOptionChanged={this.onTabsSelectionChanged} /> 
        {tab}
      </div>)
    }
}

const mapStateToProps = (state) => {
    return {
      //  user: state.oidc.user,
    };
  }

const mapDispatchToProps = dispatch => {
    return {
         onBookedOn: (rowData) => dispatch(actions.bookOnToWo(rowData)),
         onBookedToOther: () => dispatch(actions.bookToOther())
    };
}


export default connect(mapStateToProps,mapDispatchToProps)(ProductionTabs)