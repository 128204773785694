import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    Lookup,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,

} from 'devextreme-react/ui/data-grid';



const getHeight = () => {
    return (window.innerHeight - 48)
}

class UserSecurityRights extends Component {
    state = {
    };

    resize = () => {
       this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

     /*  cellPrepared = (e) => {
        if(e.rowType === "data" && e.column.command === "edit") {
            if(e.row.data.userId === null) {
               var x = e.cellElement.querySelector(".dx-link-delete");
               x.parentNode.removeChild(x);
            }
        }
      } */

      onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }


    render() {
        return  (
            <DataGrid 
            dataSource={apis.UserRightsDataSource(this.props.user.access_token)}  
            noDataText="There are no matching rights in the system."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'usersecrights' }}
            height={ getHeight }
            width="100%" ref={(gv) => {this.gridview = gv}}
            // onCellPrepared={this.cellPrepared} 
            onToolbarPreparing={this.onToolbarPreparing} 
         >
         
         <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.adminGrantedRights}
            allowAdding={this.props.grants.rights.adminGrantedRights}
            allowDeleting={this.props.grants.rights.adminGrantedRights}           
   >
             <Popup title={'User Security Rights'} showTitle={true} width={700} height={345}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
  
        </Editing>
        <Scrolling mode="infinite" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
       

        
        <Column dataField={'id'} visible={true}>
            <FormItem visible={false} disabled={true} />
        </Column>

        <Column dataField={'userId'} caption="User">
            <Lookup dataSource={apis.AllocUsersDataSource(this.props.user.access_token)} displayExpr={'userName'} valueExpr={'id'} />
            <RequiredRule />
        </Column>
        <Column dataField={'securityRightId'} caption="Right">
             <Lookup dataSource={apis.SecurityRightDataSource(this.props.user.access_token)} displayExpr={'name'} valueExpr={'id'} /> 
             <RequiredRule />
        </Column>
        <Column dataField={'granted'} caption="Grant/Revoke" dataType="boolean">
     
        </Column>
        </DataGrid>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        user: state.oidc.user,
        grants: state.rights
    };
  }


export default connect(mapStateToProps, null)(UserSecurityRights)