export const getHeight = () => {
    return (window.innerHeight - 105)
}

export const popupWidth = () => {
    return window.innerWidth / 1.5;
}

export const popupHeight = () => {
    return window.innerHeight / 1.5;
}