import React, {Component} from 'react';
import * as apis from '../../../utils/apis';
import * as utils from '../../WorkorderTabs/common';
import { connect } from "react-redux";
import axios from 'axios';
import notify from 'devextreme/ui/notify';
import CancelPartRequestPopup from '../../CancelPartRequestPopup/CancelPartRequestPopup';
import PartUsedPopup from '../../PartUsedPopup/PartUsedPopup';

import DataGrid, {
    Column,
    Editing,
     Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    Lookup,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem,
    Form
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';
import {Popup} from 'devextreme-react';
import PartRequestDetailsPopup from '../../PartRequestEnquiry/PartRequestDetailPopup/PartRequestDetailPopup';
import * as actions from '../../../store/actions/actions';
import { notificationTiming } from '../../../utils/appConfig';

const getHeight = () => {
    return (window.innerHeight - 178)
}

class PartRequests extends Component {
    state = {
        prPriorityId : null,
        formvisibility :  {
            description: false
        } 
    };

    resize = () => {
      this.gridview.instance.option('height',getHeight());
    }
    
      componentDidMount() {
        axios.get('api/prprioritydefault/')
        .then(response => {
            this.setState({prPriorityId: response.data.prPriorityId});
        });


        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

     onEditorPreparing = (e) =>  {

        if (e.parentType === 'dataRow') {
            
            if (e.dataField === "notes")
            e.editorName = "dxTextArea";
        
            if(e.dataField === 'qty') {
                e.editorName = "dxNumberBox";
               
            }
        }        
    }

    cancelClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.cancelpopup.instance.show();
      }

    setDefaultNewValues = (e) => {
        e.data.partRequestPriorityId = this.state.prPriorityId;
    }

    prClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.popup.instance.show();
    }

    partUsedClickedHandler = (e) => {
        e.event.preventDefault();
        this.props.onSelectingPartRequest(e.row.data);
        this.usedpopup.instance.show();
    }

    cancelPartRequest = (params,id,reason) => {
        var result = params.validationGroup.validate();
        if(result.isValid) {
          let data = {
            id: id,
            reason: reason
          }
    
          axios.post('api/workorderparts/cancelpartrequestp/' + this.props.woBookedOnTo.id , data)
          .then(response => {
             
              notify("Part request cancelled successfully.","success", notificationTiming);
              this.gridview.instance.refresh();
              this.cancelpopup.instance.hide();
    
          })
          .catch(error => {
            notify(error.response.data,"error", notificationTiming);
           
        
          });
        }
  
       
      }

      usePartRequest = (params,id) => {
 
          let data = {
            id: id,
          }
    
          axios.post('api/workorderparts/usepartrequest/' + this.props.woBookedOnTo.id , data)
          .then(response => {
             
              notify("Part marked used successfully.","success", notificationTiming);
              this.gridview.instance.refresh();
              this.usedpopup.instance.hide();
    
          })
          .catch(error => {
             notify(error.response.data,"error", notificationTiming);
         
        
          });
        
      }



    isCancelIconVisible = (e) => {
        return !e.row.isEditing && (!e.row.data.allocated) && (!e.row.data.dateClosed)  
    }

    isUsedIconVisible = (e) => {
        return !e.row.isEditing && (!e.row.data.used) && (e.row.data.allocated)  
    }

    onToolbarPreparing = (e) => {
        e.toolbarOptions.items.unshift({
            location: 'after',
            widget: 'dxButton',
            options: {
              icon: 'refresh',
              onClick: this.refreshGrid 
            }
          });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }

    render() {
        let output = <p>Book on to wo</p>;
         
         if(this.props.woBookedOnTo){
         output = <div>
        <DataGrid 
        dataSource={apis.WorkorderPartsPDataSource(this.props.woBookedOnTo.id,this.props.user.access_token)}  
        noDataText="There are no parts requests for this W/O"
        rowAlternationEnabled={true}  
        showBorders={true}
        showRowLines={true}
        showColumnLines={true}  
        columnHidingEnabled={false}
        allowColumnReordering={true}
        allowColumnResizing={true}
        stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'partRequests' }}
        height={this.state.gridHeight > 0 ? this.state.gridHeight : getHeight }
        width="100%" ref={(gv) => {this.gridview = gv}} 
        onEditorPreparing={this.onEditorPreparing} onInitNewRow={this.setDefaultNewValues} onToolbarPreparing={this.onToolbarPreparing}
     >
     <Editing
        mode={'popup'}
        allowAdding={this.props.woBookedOnTo.allocated === this.props.user.profile.sub} >
       
        <Popup title={'Part Request'} showTitle={true} width={utils.popupWidth()} height={utils.popupHeight()}>
          <Position my={'center'} at={'center'} of={window} />
        </Popup>
         <Form colCount={3} ref={(form) => {this.gridform = form}}>  

         </Form> 
       
    </Editing>
    <Scrolling mode="virtual" />
    <GroupPanel visible={true} />
    <FilterRow visible={true} />
    <FilterPanel visible ={true} />

    <ColumnChooser enabled={true} />

    <Column dataField={'id'} visible={false}>
        <FormItem visible={false} />
    </Column>
    <Column dataField={'requestIdentifier'} >
        <FormItem visible={false} />
    </Column>
    <Column dataField={'partNumber'}>
        <FormItem visible={true} />
        <RequiredRule />
        <StringLengthRule max='32' message='The part number cannot exceed 32 characters.' />
    </Column>
    <Column dataField={'description'}  >
        <FormItem visible={true} />
        <RequiredRule />
        <StringLengthRule max ='100' message='The part description cannot exceed 100 characters.' />
    </Column>
    <Column dataField={'dueDate'} dataType="date" format="dd/MM/y" visible={false} showInColumnChooser={true}>
            <FormItem visible={false} />
    </Column>
    <Column dataField={'figure'} visible={false} showInColumnChooser={true}>
        <StringLengthRule max='100' message='The figure text cannot exceed 100 characters.' />
        <FormItem visible={true} />
    </Column>

    <Column dataField={'iplNo'} visible={false} showInColumnChooser={true}>
        <StringLengthRule max='100' message='The figure text cannot exceed 100 characters.' />
        <FormItem visible={true} />
    </Column>
    <Column dataField={'qty'} visible={false} showInColumnChooser={true}>
        <RequiredRule></RequiredRule>
        <FormItem visible={true} editorOptions={{ showSpinButtons: true, showClearButton: true, min: 1 }} />
    </Column>
    <Column dataField={'partRequestPriorityId'} caption="Priority" >
    <RequiredRule message="Please select priority" />
        <Lookup dataSource={apis.PRPriorityDataSource(this.props.user.access_token)} displayExpr={'priority'} valueExpr={'id'} />
        <FormItem colSpan="3" />
    </Column>

    <Column dataField={'PORef'} visible={false} showInColumnChooser={true}>
        <StringLengthRule max='100' message='The PO Ref text cannot exceed 100 characters.' />
        <FormItem visible={false} />
    </Column>

    <Column dataField={'accountsRef'} caption="Sage Ref" visible={false} showInColumnChooser={true}>
        <FormItem visible={false} />
    </Column>

    <Column dataField={'grn'} caption ="GRN" visible={false} showInColumnChooser={true} >
        <FormItem visible={false} />
    </Column>

  

   
    <Column dataField={'notes'} caption='Note' visible={false}>
        <FormItem editorOptions={{height: "100px"}} colSpan="3" visible="true"></FormItem>
        <StringLengthRule max="2048" message="A Note cannot exceed 2048 characters."/>
    </Column>
    <Column dataField={'requestedBy'} visible={false} showInColumnChooser={true}>
        <FormItem visible={false} />
    </Column>
    <Column dataField={'dateRequested'} dataType="date" format="dd/MM/y" caption="Requested" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>
        <Column dataField={'dateClosed'} dataType="date" format="dd/MM/y" caption="Closed" visible={false} showInColumnChooser={true}>
            <FormItem visible={false}></FormItem>
        </Column>

  

    <Column dataField={'partRequestStatusId'} caption="Status" >
        <FormItem visible={false} />
        <Lookup dataSource={apis.PartRequestStatusesDataSource(this.props.user.access_token)} displayExpr={'text'} valueExpr={'id'} />
    </Column>
    <Column dataField={'costingComplete'} caption="Costed" dataType="boolean" >
         <FormItem visible={false} />
    </Column>
    <Column dataField={'costing'} caption="Costing" dataType="boolean" visible={false}>
      <FormItem visible={false} />
    </Column>
    <Column dataField={'quoted'} caption="Quoted" dataType="boolean" visible={false}>
            <FormItem visible={false} />
    </Column>
    <Column dataField={'ordered'} caption="Ordered" dataType="boolean" >
         <FormItem visible={false} />
    </Column>
    <Column dataField={'allocated'} caption="Allocated" dataType="boolean" >
         <FormItem visible={false} />
    </Column>
    <Column dataField={'used'} caption="Used" dataType="boolean" >
         <FormItem visible={false} />
    </Column>
  
 
    <Column type={'buttons'} width={110} allowReordering={false} showInColumnChooser={false}
      buttons={['edit', 'delete', {
        hint: 'View',
        icon: 'info',
        onClick: this.prClickedHandler
      },
      {
        hint: 'Parts used',
        icon: 'toolbox',
        onClick: this.partUsedClickedHandler,
        visible: this.isUsedIconVisible,
      },
      {
        hint: 'Cancel',
        icon: 'clear',
        onClick: this.cancelClickedHandler,
        visible: this.isCancelIconVisible,
      }]} />
    </DataGrid>
    <Popup title="Part request details" ref={(popup) => {this.popup = popup}} height={this.popupHeight} width={this.popupWidth} >
        <PartRequestDetailsPopup></PartRequestDetailsPopup>
   </Popup>
   <Popup title="Cancel Part Request" ref={(popup) => {this.cancelpopup = popup}} height={this.popupHeight} width={this.popupWidth}  >
            <CancelPartRequestPopup cancelPartRequest={(params,id,reason) => this.cancelPartRequest(params,id,reason)}></CancelPartRequestPopup>
    </Popup>
    <Popup title="Parts Used" ref={(popup) => {this.usedpopup = popup}} height={this.popupHeight} width={this.popupWidth}  >
            <PartUsedPopup usePartRequest={(params,id) => this.usePartRequest(params,id)}></PartUsedPopup>
    </Popup>
   </div>
         }
   
      
   
        return  (
          <div>
           {output}
           </div>
        );
        
    }
}

const mapStateToProps = (state) => {
    return {
      user: state.oidc.user,
      woBookedOnTo: state.booking.woToInteract,
    };
  }

  const mapDispatchToProps = dispatch => {
    return {
         onSelectingPartRequest: (rowData) => dispatch(actions.setPRDetail(rowData)),
       
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(PartRequests)