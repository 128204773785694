import React, {Component} from 'react';
import * as apis from '../../utils/apis';
import { connect } from "react-redux";
import DataGrid, {
    Column,
    Editing,
    Popup,
    Position,
    GroupPanel,
    FilterPanel,
    FilterRow,
    ColumnChooser,
    Scrolling,
    RequiredRule,
    FormItem
} from 'devextreme-react/ui/data-grid';
import { StringLengthRule } from 'devextreme-react/validator';

const getHeight = () => {
    return (window.innerHeight - 48)
}

class WorkorderStatusGrid extends Component {
    state = {
        
    };

    resize = () => {
 
        this.gridview.instance.option('height',getHeight());
      }
    
      componentDidMount() {
        window.addEventListener('resize', this.resize)
      }
      
      componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
      }

      setDefaultNewValues = (e) => {
        e.data.active = true; 
        e.data.isHold = false;
        e.data.isFullyComplete = false;
        e.data.isForProduction = false;
        e.data.defaultNew = false;
        e.data.bookedOn = false;
        e.data.locked = false;
      }

      popupWidth = () => {
        return window.innerWidth / 1.5;
      }
    
    popupHeight = () => {
        return window.innerHeight / 1.5;
    }

    onToolbarPreparing = (e) => {
      e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'refresh',
            onClick: this.refreshGrid 
          }
        });
    }

    refreshGrid = () => {
        this.gridview.instance.refresh(true);
    }
      
    render() {
        return  (
            <DataGrid 
            dataSource={apis.WorkorderStatusDataSource(this.props.user.access_token)}  
            noDataText="There are no workorder statuses defined."
            rowAlternationEnabled={true}  
            showBorders={true}
            showRowLines={true}
            showColumnLines={true}  
            columnHidingEnabled={false}
            allowColumnReordering={true}
            allowColumnResizing={true}
            stateStoring={{ enabled: 'true',type: 'localStorage', storageKey: 'wostatus' }}
            height={getHeight}
            width="100%" ref={(gv) => {this.gridview = gv}} 
            onInitNewRow={this.setDefaultNewValues} onToolbarPreparing={this.onToolbarPreparing}
         >
          <Editing
            mode={'popup'}
            allowUpdating={this.props.grants.rights.adminWOStatus}
            allowDeleting={this.props.grants.rights.adminWOStatus}
            allowAdding={this.props.grants.rights.adminWOStatus} >
             <Popup title={'Workorder Status'} showTitle={true} width={this.popupWidth} height={this.popupHeight}>
              <Position my={'center'} at={'center'} of={window} />
            </Popup>
        </Editing>
        <Scrolling mode="virtual" />
        <GroupPanel visible={true} />
        <FilterRow visible={true} />
        <FilterPanel visible ={true} />
  
        <ColumnChooser enabled={true} />
        {/* <StateStoring enabled={true} storageKey="this.props.sectionId" /> */}

        <Column dataField={'id'} visible={false}>
            <FormItem visible={false} />
        </Column>

        <Column dataField={'status'} >
            <RequiredRule message="Status is required." />
            <StringLengthRule max="255" message="A workorder status cannot exceed 256 characters."/>
        </Column>

        <Column dataField={'active'} />
        <Column dataField={'isForProduction'} caption='Production' />
        <Column dataField={'isFullyComplete'} caption='Complete' />
        <Column dataField={'isHold'} caption='On Hold' />
        <Column dataField={'defaultNew'} caption='Default new' />
        <Column dataField={'bookedOn'} caption='Booked On' />
        <Column dataField={'locked'} caption='Locked' />
       
        </DataGrid>
        );
    }


}

const mapStateToProps = (state) => {
  return {
      user: state.oidc.user,
      grants: state.rights
  };
}

export default connect(mapStateToProps,null)(WorkorderStatusGrid)