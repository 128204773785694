import React, {Component, Fragment} from 'react';
import { Form, LoadIndicator  } from 'devextreme-react';
import axios from 'axios';
import * as apis from '../../../../utils/apis';
import { connect } from "react-redux";
import { confirm} from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { notificationTiming } from '../../../../utils/appConfig';

class TechDataForm extends Component {

    state =  {
        loading: true,
        error: false, 
    }

    componentDidMount() {
        axios.get('api/workordertechdata/' + this.props.selectedWo.id)
        .then(response => {
           this.techDataFormData = response.data;
            this.setState({ loading: false });
        });
    } 

    getWorksheet = ()  => {

      
        let dlRequest = {
            id: this.techDataFormData.worksheetId
        }

        if(this.techDataFormData.worksheetId !== null) {
            axios.post(apis.MANUALAPI + 'api/downloadlink/getdownloadlink',dlRequest)
            .then(response => {
            window.open(response.data, '_blank');
        })
        .catch(error => {
            console.log(error)
        });
        }
        
    }

    checkData = () => {
   

        let result = confirm("<i>Tech data is current</i>", "Current?");
        result.then((dialogResult) => {
            if(dialogResult === true) {
  
                let data = {
                    id: this.props.selectedWo.id,
                  
                }
                axios.post('api/workordertechdata/' + data.id, data)
                .then(response => {
                   
                    notify("Techdata marked check successfuly","success", notificationTiming);
                    this.techDataForm.techDataChecked = true;
                    let button = this.techDataForm.instance.getButton('check');
                    button.option("text","Data Check Complete");
                    button.option("onClick",null);
                    button.option("type","success");
   
        
                })
                .catch(error => {
                  notify(error.response.data,"error", notificationTiming);

                });
            }
            
        });


    }

    techDataFormData = {
        techLibraryLocation: null,
        worksheetTitle: null,
        worksheetIdentifier: null,
        worksheetId: null,
        cmmReference: null,
        techDataNotes: null,
        techDataChecked: false
    }

    render() {

        let techDataFormConfig = {
            validationGroup: 'cust',
            items: [
                {
                    
                    itemType: "group",
                    name: "Check",
                    caption: "Check",
                    colSpan: 3,
                    colCount: 3,
                    items: [
                        {
                            itemType: "button",
                            horizontalAlignment: "left",
                            colSpan: 1,
                            name: 'check',
                            buttonOptions: {
                                text: this.techDataFormData.techDataChecked === false ? 'Tech Data Not Checked' : 'Data Check Complete',
                                type: this.techDataFormData.techDataChecked === false ? "danger" : "success",
                                width: "100%",
                                useSubmitBehavior: false,
                                onClick: this.techDataFormData.techDataChecked === true || !this.props.grants.rights.techDataCheck || this.props.selectedWo.locked === true || this.props.selectedWo.complete === true ? null : this.checkData,
                          //      disabled: this.techDataFormData.techDataChecked === true || !this.props.grants.rights.techDataCheck || this.props.selectedWo.locked === true || this.props.selectedWo.complete === true,
                            }
                        },
                        {
                            itemType: "empty",
                            colSpan: 1
                        },
                        {
                            itemType: "empty",
                            colSpan: 1
                        },
                      

                    ]

                },
                {
                    
                        itemType: "group",
                        name: "links",
                        caption: "Links",
                        colSpan: 3,
                        colCount: 3,
                        items: [
                            {
                                itemType: "button",
                                horizontalAlignment: "left",
                                colSpan: 1,
                                buttonOptions: {
                                    text: this.techDataFormData.worksheetId !== null ? "Worksheet - " + this.techDataFormData.worksheetIdentifier : 'Worksheet not set.',
                                    type: "default",
                                    width: "100%",
                                    useSubmitBehavior: false,
                                    onClick: this.getWorksheet,
                                    disabled: this.techDataFormData.worksheetId === null
                                }
                            },
                            {
                                itemType: "empty",
                                colSpan: 1
                            },
                            {
                                itemType: "empty",
                                colSpan: 1
                            },
                            {
                                itemType: "button",
                                horizontalAlignment: "left",
                                colSpan: 1,
                                validationGroup: 'cust',
                                buttonOptions: {
                                    text: this.techDataFormData.techLibraryLocation !== '' ? "Tech Library - " + this.techDataFormData.techLibraryLocation : 'Library location not set.',
                                    type: "default",
                                    width: "100%",
                                    useSubmitBehavior: false,
                                    onClick: this.openFileBrowser,  
                                    disabled: this.techDataFormData.techLibraryLocation === ''                              
                                }
                            },
                            {
                                itemType: "empty",
                                colSpan: 1
                            },
                            {
                                itemType: "empty",
                                colSpan: 1
                            },

                        ]
    
                    },
                    {
                    
                        itemType: "group",
                        name: "Information",
                        caption: "Data",
                        colSpan: 3,
                        colCount: 1,
                        items: [
                            {
                                dataField: 'cmmReference',
                                colSpan: 1,
                                editorType: "dxTextBox",
                                editorOptions: {
                                    width: "50%",
                                    readOnly: true
                                },
                                label : { 
                                text: "CMM Reference"},
                                validationRules: [
                                {
                                    type: "stringLength",
                                    max: 512,
                                    message: "CMM Reference cannot be longer than 512 characters."
                                }
                                ]
                            },
                            {
                                dataField: "techDataNotes",
                                colSpan: 1,
                                editorType: "dxTextArea",
                               
                                editorOptions: {
                                    height: "100px",
                                    readOnly: true
                            
                                },
                                label : {
                                     text: "Notes"
                                },
                                validationRules: [{
                                    type: "stringLength",
                                    max: 1024,
                                    message: "Notes cannot be longer than 1024 characters."
                                }]
                                
                            },

                        ]
    
                    },
       
            ]
        }


        return (
            <Fragment>
             <Form visible={!this.state.loading} height="100%"
             colCount={3}
             formData={this.techDataFormData}  
             items={techDataFormConfig.items}
             ref={(form) => {this.techDataForm = form}}>
            </Form> 
             <LoadIndicator visible={this.state.loading} />
           </Fragment>
             
         );
    }
}

const mapStateToProps = (state) => {
    return {
      //  user: state.oidc.user,
      grants: state.rights,
      selectedWo: state.woDetail.rowData
    };
  }

export default connect(mapStateToProps)(TechDataForm)